import { Node, Edge } from "reactflow";
import { Canvas, ZSLFromTemplate } from "../types";
import { extractAssetId } from "./extractAssetId";

export function assignAssetsToCanvas(
  dehydratedCanvas: Canvas,
  zslJson: ZSLFromTemplate,
) {
  const zslData = zslJson.data;
  if (!zslData) {
    console.error("No ZSL data found - zslJson:", zslJson);
    return dehydratedCanvas;
  }

  try {
    // Process nodes using reduce to avoid mutation
    const newNodes = dehydratedCanvas.nodes.reduce(
      (acc: Node[], node: Node) => {
        // Exclude zap steps since they're ephemeral and are create on demand whenever we have a zap node with zap id
        if (node.type === "zapStep") return acc;

        // Create a deep copy of the node and its data
        const newNode = { ...node, data: { ...node.data } };

        // Replace real asset IDs in node.data with templated strings from resourceMap
        if (node.data.tableId) {
          const tableId = extractAssetId(node.data.tableId);

          newNode.data.tableId = zslData.resourceMap[tableId];
        } else if (newNode.data.zapId) {
          const zapId = extractAssetId(node.data.zapId);

          newNode.data.zapId = zslData.resourceMap[zapId];
        } else if (node.data.pageId && node.data.projectId) {
          const projectId = extractAssetId(node.data.projectId);

          newNode.data.projectId = zslData.resourceMap[projectId];

          const pageId = extractAssetId(node.data.pageId);

          newNode.data.pageId = zslData.resourceMap[`${projectId}.${pageId}`];
        }

        acc.push(newNode);
        return acc;
      },
      [],
    );

    // Filter out zapStep edges since they're ephemeral and are create on demand whenever we have a zap node with zap id
    const newEdges = dehydratedCanvas.edges.filter(
      (edge: Edge) => edge.type !== "zapStepEdge",
    );

    return {
      ...dehydratedCanvas,
      nodes: newNodes,
      edges: newEdges,
    };
  } catch (e) {
    console.error("Error assigning assets to canvas", e);
    return dehydratedCanvas;
  }
}
