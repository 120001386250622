import { atom } from "jotai";

import { BuiltInApp, Table } from "../types";
import { atomWithRefresh } from "../utils";

export const tablesAppAtom = atom<BuiltInApp | null>(null);
tablesAppAtom.debugLabel = "tablesAppAtom";

export const tablesAsyncAtom = atomWithRefresh<Promise<Table[]>>(async () => {
  try {
    const res = await fetch(`/api/canvas/v1/tables/`, {
      headers: {
        "trailing-slash-hack": "true",
      },
    });

    if (!res.ok) {
      const data = await res.json();
      throw new Error(data.errors);
    }

    return await res.json();
  } catch (e) {
    return [];
  }
});

tablesAsyncAtom.debugLabel = "tablesAsyncAtom";
