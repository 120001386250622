import { atom } from "jotai";
import { Node } from "reactflow";
import {
  closeContextMenusAtom,
  cursorAtom,
  floatingToolbarActiveButtonAtom,
  selectedNodeTypeAtom,
} from "../diagramming";
import { edgesAtom, editingEdgeIdAtom } from "../edges";
import { nodesAtom } from "./nodesAtom";
import {
  getNodePositionInsideParent,
  getEventRawText,
  isTempEdge,
  snapToAdjacentNode,
} from "../utils";
import { DRAG_AND_DROP, TEMP } from "@zapier/canvas-constants";
import { addAndSavePathNodeAtom } from "../paths";
import { addNodeAtom } from "./addNodeAtom";
import { selectedAssetIdAtom } from "../project";
import { GetNodeDataByTypeFnParams, NodesAndEdges } from "../types";

type Params = {
  event: React.MouseEvent;
  /** parentId is when you are trying to drop a node on a group node
   */
  parentId?: string;
  ref: React.MutableRefObject<HTMLDivElement | null>;
  saveNodesAndEdges: ({ nodes, edges }: NodesAndEdges) => void;
  onSaveNodes: (nodes: Node[]) => void;
  emitEventOnAddStep: (interactionType: string, rawText: string) => void;
  emitEventOnAddPath: (interactionType: string, rawText: string) => void;
  screenToFlowPosition: (point: { x: number; y: number }) => {
    x: number;
    y: number;
  };
  getIntersectingNodes: (rect: {
    x: number;
    y: number;
    width: number;
    height: number;
  }) => Node[];
  getNodeDataByType: (params: GetNodeDataByTypeFnParams) => Node | null;
};

export const addNodeOnClickAtom = atom(
  null,
  (
    get,
    set,
    {
      event,
      parentId,
      ref,
      saveNodesAndEdges,
      onSaveNodes,
      emitEventOnAddStep,
      emitEventOnAddPath,
      screenToFlowPosition,
      getIntersectingNodes,
      getNodeDataByType,
    }: Params,
  ) => {
    // Close the context menus if they're open whenever the window is clicked.
    set(closeContextMenusAtom);
    set(editingEdgeIdAtom, null);
    const nodes = get(nodesAtom);
    const edges = get(edgesAtom);
    const selectedNodeType = get(selectedNodeTypeAtom);

    if (selectedNodeType === "group") {
      // You can only create a group node by clicking and dragging. If you
      // choose the group node option and click, we don't want to create a
      // 1x1 group node because it will be very hard to see and users might
      // not understand what just happened
      return;
    }

    const reactFlowBounds = ref?.current?.getBoundingClientRect();

    if (reactFlowBounds) {
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      if (selectedNodeType !== null) {
        const intersections = getIntersectingNodes({
          x: position.x,
          y: position.y,
          width: 40,
          height: 40,
        }).filter((n) => n.type === "group");

        const groupNode = intersections[0];
        let newNode = getNodeDataByType({
          type: selectedNodeType,
          position,
        }) as Node;

        if (parentId) {
          newNode.parentId = parentId;
        }

        if (groupNode) {
          // if we drop a node on a group node, we want to position the node inside the group
          newNode.position = getNodePositionInsideParent(
            {
              position,
              width: 40,
              height: 40,
            },
            groupNode,
          ) ?? { x: 0, y: 0 };
          newNode.parentId = groupNode?.id;
          newNode.expandParent = true;
          newNode.extent = "parent";
        }

        if (newNode === null) {
          set(selectedNodeTypeAtom, null);
          return;
        }

        const rawText = getEventRawText(selectedNodeType);

        const isPathNode = selectedNodeType === "path";
        const isZapNode = selectedNodeType === "zap";
        // newNode will have updated position after running snapToAdjacentNode
        newNode = snapToAdjacentNode({ newNode, nodes, edges, isPathNode });

        const tempEdge = edges.find((e) => isTempEdge(e));

        let newEdges;

        /** Swap temp edge for real one */
        if (tempEdge) {
          const { className, ...rest } = tempEdge;

          const realEdge = {
            ...rest,
            id: rest.id.replace(TEMP, newNode.id),
            source: rest.source === TEMP ? newNode.id : rest.source,
            target: rest.target === TEMP ? newNode.id : rest.target,
          };

          set(edgesAtom, (edges) => {
            const updatedEdges = edges.map((edge) => {
              if (isTempEdge(edge)) {
                return realEdge;
              }
              return edge;
            });

            newEdges = updatedEdges;
            return updatedEdges;
          });
        }

        if (isPathNode) {
          const sourceNodeId = tempEdge?.id?.split("=>")[0] || null;
          set(addAndSavePathNodeAtom, {
            sourceNodeId,
            nodePartial: newNode,
            numOfPaths: 2,
            saveNodesAndEdges,
            shouldAddIncomerToPathNodeEdge: false, // since we already add this above when swapping temp edge for real one
          });

          emitEventOnAddPath(DRAG_AND_DROP, rawText);
        } else {
          // This is so the FloatingZapTypeahead shows up automatically when using the diagramming toolbar but not when you add a Zap from Project sidebar
          if (isZapNode && !newNode.data.zapId) {
            setTimeout(() => set(floatingToolbarActiveButtonAtom, "zap"), 0);
          }

          // we need to make sure that the parents are sorted before the children
          // to make sure that the children are rendered on top of the parents
          const updatedNodes = set(addNodeAtom, newNode);

          if (newEdges) {
            void saveNodesAndEdges({
              nodes: updatedNodes,
              edges: newEdges,
            });
          } else {
            void onSaveNodes(updatedNodes);
          }

          emitEventOnAddStep(DRAG_AND_DROP, rawText);
        }

        set(selectedNodeTypeAtom, null);
        set(selectedAssetIdAtom, null);
      }

      const cursorMode = get(cursorAtom);
      const isCommentMode = cursorMode === "comment";
      if (isCommentMode) {
        const commentNode = getNodeDataByType({
          type: "comment",
          position,
        }) as Node;

        const rawText = getEventRawText("comment");

        const updatedNodes = set(addNodeAtom, commentNode);
        void onSaveNodes(updatedNodes);
        emitEventOnAddStep(DRAG_AND_DROP, rawText);

        set(cursorAtom, "select");
      }
    }
  },
);

addNodeOnClickAtom.debugLabel = "addNodeOnClickAtom";
