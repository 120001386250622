import { Node } from "reactflow";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { nodesAtom } from "../nodes/nodesAtom";
import { edgesAtom } from "../edges/edgesAtom";

export const lastBranchingPathPositionAtomFamily = atomFamily(
  (pathNodeId: string) => {
    const lastBranchingPathPositionAtom = atom((get) => {
      const nodes = get(nodesAtom);
      const edges = get(edgesAtom);
      const childNodeIds = edges
        .filter((edge) => edge.source === pathNodeId)
        .map((edge) => edge.target);

      const childNodes = childNodeIds.reduce<Node[]>((acc, childNodeId) => {
        const foundNode = nodes.find((node) => node.id === childNodeId);
        if (foundNode) acc.push(foundNode); // Filter out undefined nodes
        return acc;
      }, []);

      if (childNodes.length === 0) {
        return { x: 0, y: 0 };
      }

      // Find the node with the highest Y position (lowest on Canvas)
      const lowestBranchingPathNode = childNodes.reduce((highest, node) => {
        return node.position.y > highest.position.y ? node : highest;
      }, childNodes[0]);

      return lowestBranchingPathNode.position;
    });

    lastBranchingPathPositionAtom.debugLabel = `lastBranchingPathPositionAtomFamily(${pathNodeId})`;
    return lastBranchingPathPositionAtom;
  },
);
