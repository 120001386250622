import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";

export interface Toggles {
  useLiveblocks: boolean;
  useLiveblocksThreads: boolean;
  dataPoc: boolean;
  manageProjectsMvp: boolean;
  aiPrompt: string;
  showModifyPrompt: boolean;
  aiSeparateLlms: boolean;
}

/* Only to be used by the CanvasHydrator, not in the UI */
export const togglesAtom = atom<Toggles>({
  useLiveblocks: false,
  useLiveblocksThreads: false,
  dataPoc: false,
  manageProjectsMvp: false,
  aiPrompt: "default",
  showModifyPrompt: false,
  aiSeparateLlms: false,
});

togglesAtom.debugLabel = "togglesAtom";

/* Use this atom in the UI since we need to be able to reset it to the state set by CanvasHydrator in DevTools */
export const togglesAtomResettable = atomWithDefault((get) => get(togglesAtom));

togglesAtomResettable.debugLabel = "togglesAtomResettable";
