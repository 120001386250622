import { atom } from "jotai";

import { atomFamily } from "jotai/utils";
import { permissionsAtom } from "./fetchPermissionsAtomFamily";
import {
  Tuple,
  Member,
  MemberWithPermission,
  getUniqueSelectedMemberIds,
  getSelectedMembersOrTeamsWithPermission,
} from "@zapier/permissions";

type Params = {
  currentAccountId: number | undefined;
  allowTeams: boolean;
};

export const selectedMembersAtom = atom<MemberWithPermission[]>([]);

export const isFetchingSelectedMembersAtom = atom(false);

export const fetchSelectedMembersAtomFamily = atomFamily(
  ({ currentAccountId, allowTeams }: Params) => {
    const fetchAtom = atom(null, async (get, set) => {
      if (!allowTeams) {
        return;
      }

      set(isFetchingSelectedMembersAtom, true);

      const permissions = get(permissionsAtom) as Tuple[];

      const uniqueMemberIds = getUniqueSelectedMemberIds(permissions, "canvas");

      if (uniqueMemberIds.length === 0 || !currentAccountId) {
        set(selectedMembersAtom, []);
        set(isFetchingSelectedMembersAtom, false);
        return;
      }

      const membersRes = await fetch(
        `/api/v4/accounts/${currentAccountId}/members?ids=${uniqueMemberIds.join(
          ",",
        )}&limit=9000`,
      );

      if (!membersRes.ok) {
        set(isFetchingSelectedMembersAtom, false);
        throw new Error(
          `Error fetching selected members: ${membersRes.status}`,
        );
      }

      const json = await membersRes.json();
      const selectedMembersNoPermission: Member[] = json.results;

      const selectedMembers = getSelectedMembersOrTeamsWithPermission(
        selectedMembersNoPermission,
        permissions,
        "canvas",
        "individual",
      ) as MemberWithPermission[];

      set(selectedMembersAtom, selectedMembers);
      set(isFetchingSelectedMembersAtom, false);
    });

    fetchAtom.debugLabel = `fetchSelectedMembersAtomFamily(allowTeams: ${allowTeams})`;
    return fetchAtom;
  },
  isEqual,
);

function isEqual(a: Params, b: Params) {
  return (
    a.allowTeams === b.allowTeams && a.currentAccountId === b.currentAccountId
  );
}
