import { atom } from "jotai";
import { nodesAtom } from "../nodes";
import { edgesAtom } from "../edges";
import { Edge, Node } from "reactflow";
import { zapStepsByIdAsyncAtomFamily } from "./zapStepsByIdAsyncAtomFamily";
import { atomFamily } from "jotai/utils";
import {
  dropInvalidEdges,
  createCollapsedZapStepAndEdges,
  layoutElementsVertically,
} from "../utils";
import { allZapStepEdgesAtomFamily } from "./allZapStepEdgesAtomFamily";

export const zapStepNodesAtomFamily = atomFamily((zapNodeId: string) => {
  const zapStepNodesAtom = atom(
    (get) =>
      get(nodesAtom).filter(
        (node) => node.type === "zapStep" && node.parentId === zapNodeId,
      ),
    async (get, set) => {
      const nodes = get(nodesAtom);
      const edges = get(edgesAtom);

      const zapNode = nodes.find((node) => node.id === zapNodeId)!;
      const isExpanded = zapNode?.data.isExpanded;

      const newZapStepNodes: Node[] = [];
      const newZapStepEdges: Edge[] = [];

      const { nodes: zapStepNodes, edges: newEdges } = await get(
        zapStepsByIdAsyncAtomFamily(zapNodeId),
      );

      // Filter out nodes that already exist
      const uniqueNewZapStepNodes = zapStepNodes.filter((newNode) => {
        return !nodes.some((node) => node.id === newNode.id);
      });

      // Filter out edges that already exist
      const uniqueNewZapStepEdges = newEdges.filter((newEdge) => {
        return !edges.some((edge) => edge.id === newEdge.id);
      });

      if (
        uniqueNewZapStepNodes.length > 0 &&
        uniqueNewZapStepEdges.length > 0
      ) {
        // Create a new Dagre graph for the Zap steps & edges for just this Zap node
        const { layoutedZapSteps, layoutedZapStepEdges } =
          layoutElementsVertically({
            zapNode,
            zapSteps: uniqueNewZapStepNodes,
            zapStepEdges: uniqueNewZapStepEdges,
          });

        const allZapStepEdges = allZapStepEdgesAtomFamily(zapNodeId);
        set(allZapStepEdges, layoutedZapStepEdges);

        newZapStepNodes.push(...layoutedZapSteps);
        newZapStepEdges.push(...layoutedZapStepEdges);
      }

      /** This is to ensure that Zap step nodes & edges only get added to the RF state once */
      if (newZapStepNodes.length > 0 && newZapStepEdges.length > 0) {
        let newNodes = [
          ...nodes,
          ...newZapStepNodes.map((node) => ({ ...node, hidden: !isExpanded })),
        ];
        const validEdges = dropInvalidEdges(zapNodeId, newZapStepNodes, edges);

        let newEdges = [
          ...validEdges,
          ...newZapStepEdges.map((edge) => ({ ...edge, hidden: !isExpanded })),
        ];

        const { zapStepsWithConnections, zapStepEdges } =
          createCollapsedZapStepAndEdges({
            zapNodeId,
            zapSteps: newZapStepNodes,
            nodes,
            edges,
          });

        /** If there are Zap steps with connections, render them on page load if user collapsed the Zap node previously */
        if (!isExpanded && zapStepsWithConnections.length > 0) {
          const {
            layoutedZapSteps: layoutedCollapsedZapSteps,
            layoutedZapStepEdges: layoutedCollapsedZapStepEdges,
          } = layoutElementsVertically({
            zapNode,
            zapSteps: zapStepsWithConnections,
            zapStepEdges: zapStepEdges,
          });

          const layoutedZapStepsWithConnectionsMap = new Map(
            layoutedCollapsedZapSteps.map((node) => [node.id, node]),
          );

          newNodes = newNodes.map((node) => {
            const layoutedNodeWithConnections =
              layoutedZapStepsWithConnectionsMap.get(node.id);

            if (layoutedNodeWithConnections) {
              // Zap step with connections will be visible
              return layoutedNodeWithConnections;
            } else {
              // Zap step without connections will be hidden
              return node;
            }
          });

          const layoutedZapStepEdgesWithConnectionsMap = new Map(
            layoutedCollapsedZapStepEdges.map((edge) => [edge.id, edge]),
          );

          const combinedEdges = [...newEdges, ...layoutedCollapsedZapStepEdges];

          // Create a Set of unique edge IDs
          const uniqueEdgeIds = new Set(combinedEdges.map((edge) => edge.id));
          const uniqueEdgeIdsArray = [...uniqueEdgeIds];

          newEdges = uniqueEdgeIdsArray.map((edgeId) => {
            const layoutedEdgeWithConnections =
              layoutedZapStepEdgesWithConnectionsMap.get(edgeId);

            if (layoutedEdgeWithConnections) {
              // Zap step with connections will be visible
              return layoutedEdgeWithConnections;
            } else {
              // Zap step without connections will be hidden
              return newEdges.find((edge) => edge.id === edgeId)!;
            }
          });
        }

        set(nodesAtom, newNodes);
        set(edgesAtom, newEdges);
      }
    },
  );

  zapStepNodesAtom.debugLabel = `zapStepNodesAtomFamily(${zapNodeId})`;
  return zapStepNodesAtom;
});
