import { atom } from "jotai";
import { nodesAtom } from "./nodesAtom";

const isInterfacesNode = (appId: string = "") =>
  appId.includes("InterfacesCLIAPI@");

export const numberOfInterfaceNodesAtom = atom((get) => {
  const nodes = get(nodesAtom);

  const interfaceNodes = nodes.filter(
    (node) =>
      isInterfacesNode(node?.data?.services?.[0]?.currentImplementation.id) &&
      node.data.projectId,
  );

  return interfaceNodes.length;
});

numberOfInterfaceNodesAtom.debugLabel = "numberOfInterfaceNodesAtom";
