import { atom } from "jotai";
import { nodesAtom } from "./nodesAtom";

export const saveNodeOnResizeEndAtom = atom(
  null,
  (get, _set, { id, width, height, x, y, onSaveNodes }) => {
    const nodes = get(nodesAtom);

    const updatedNodes = nodes.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          width,
          height,
          position: { x, y },
        };
      }

      return node;
    });

    /** Don't need to update RF state for height & width since that's already happening */
    void onSaveNodes(updatedNodes);
  },
);

saveNodeOnResizeEndAtom.debugLabel = "saveNodeOnResizeEndAtom";
