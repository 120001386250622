import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { templateAtom } from "../templates";
import { Interface, ZSLInterface } from "../types";
import { extractAssetId, isTemplateInterfaceProjectId } from "../utils";

export const interfaceProjectAsyncAtomFamily = atomFamily(
  (interfaceProjectId: string | undefined) => {
    const interfaceAtom = atom(async (get) => {
      if (!interfaceProjectId) {
        return null;
      }

      const isTemplateInterface =
        isTemplateInterfaceProjectId(interfaceProjectId);
      const zslTemplate = get(templateAtom);

      if (zslTemplate && isTemplateInterface) {
        const coreInterfaceProjectId = extractAssetId(interfaceProjectId);
        const templateInterface = zslTemplate.interfaces?.find(
          (project) => coreInterfaceProjectId === project.id,
        );

        if (templateInterface) {
          return templateInterface as ZSLInterface;
        }
      }

      // Because we don't want to fetch with template asset id and get a 404 Not Found error
      if (isTemplateInterface) {
        return null;
      }

      try {
        const res = await fetch(
          `/api/canvas/v1/projects/${interfaceProjectId}/`,
        );

        if (res.ok) {
          const json = await res.json();
          return json as Interface;
        } else {
          console.error("Failed to fetch interface:", res.statusText);
          return null;
        }
      } catch (error) {
        console.error("Error fetching interface:", error);
        return null;
      }
    });

    interfaceAtom.debugLabel = `interfaceProjectAsyncAtomFamily(${interfaceProjectId})`;
    return interfaceAtom;
  },
);
