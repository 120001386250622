export const CHILD_NODE_HEIGHT = 151;
export const GAP = 88;

export function calculatePathChildrenYPositions(
  sourceNodeY = 0,
  numPaths: number,
) {
  // Determine the total height occupied by the child nodes and the gap
  const totalHeight = numPaths * CHILD_NODE_HEIGHT + (numPaths - 1) * GAP;

  // Determine the Y position of the first child node
  const firstChildY = sourceNodeY - (totalHeight - CHILD_NODE_HEIGHT) / 2;

  const childPositions = [];

  for (let i = 0; i < numPaths; i++) {
    // Y position of each child node
    const childY = firstChildY + i * (CHILD_NODE_HEIGHT + GAP);
    childPositions.push(childY);
  }

  return childPositions;
}
