import { atomFamily } from "jotai/utils";
import { atom } from "jotai";

import { edgesAtom } from "./edgesAtom";

export const childrenEdgesAtomFamily = atomFamily((nodeId: string) => {
  const childrenEdgesAtom = atom((get) => {
    const edges = get(edgesAtom);
    return edges.filter((edge) => edge.source === nodeId);
  });

  childrenEdgesAtom.debugLabel = `childrenEdgesAtomFamily(${nodeId})`;
  return childrenEdgesAtom;
});
