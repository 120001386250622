import { atom } from "jotai";
import { Node, Edge } from "reactflow";
import * as Sentry from "@sentry/nextjs";

import { saveCanvasAsyncSetterAtom } from "../persistence";
import { canvasAtom, canvasIdAtom } from "../canvas";
import { nodesAtom } from "../nodes";
import { edgesAtom } from "../edges";
import {
  assignAssetsToCanvas,
  convertAssetIdsToPlaceholderIds,
  removeTemplateFromString,
} from "../utils";
import { ZSLFromTemplate } from "../types";
import { templateAtom } from "./templateAtom";
import { showCanvasTemplateWidgetAtom } from "./showCanvasTemplateWidgetAtom";
import { isExecutingTemplateAtom } from "./isExecutingTemplateAtom";
import { handleZslExecutionErrorCommand } from "./handleZslExecutionErrorCommand";

type CanvasToSave = {
  nodes: Node[];
  edges: Edge[];
  name?: string;
  description?: string;
};

type Params = { prompt: string };

function trimString(inputString: string) {
  const maxLength = 99; // Canvas description must NOT have more than 100 characters
  const ellipsis = "...";

  if (inputString.length > maxLength) {
    return inputString.substring(0, maxLength - ellipsis.length) + ellipsis;
  }

  return inputString;
}

/* Converts template to real canvas nodes and edges
 * Used by CreateCanvasFromTemplateToolbar, AiCopilotBanner, template page, and AI page
 */
export const executeTemplateAndSaveAsyncAtom = atom(
  null,
  async (get, set, params?: Params) => {
    const { prompt = "" } = params || {};

    const zslTemplate = get(templateAtom);

    if (zslTemplate === undefined) {
      return;
    }

    const canvasId = get(canvasIdAtom);
    set(isExecutingTemplateAtom, true);

    // For template or AI pages with no real canvas
    if (!canvasId || !isValidUUID(canvasId)) {
      // For AI page, there will be no canvases
      if (zslTemplate && !zslTemplate.canvases) {
        const nodes = get(nodesAtom);
        const edges = get(edgesAtom);

        zslTemplate.canvases = [
          {
            id: "",
            name: zslTemplate.name,
            description: trimString(zslTemplate.description as string),
            ...convertAssetIdsToPlaceholderIds({ nodes, edges }),
          },
        ];
      }

      try {
        const zslRes = await fetch("/api/canvasui/zsl", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            template: {
              meta: {},
              ...zslTemplate,
            },
          }),
        });

        if (!zslRes.ok) {
          const error = await zslRes.json();
          const statusCode = zslRes.status;

          set(handleZslExecutionErrorCommand, { error, statusCode, prompt });

          Sentry.captureException(
            new Error("ZSL execution error - API response failure (no Canvas)"),
            {
              extra: { error, zsl: zslTemplate, statusCode },
            },
          );

          return;
        }

        const zsl = await zslRes.json();

        const canvasIsNotEmpty = Object.keys(zsl.data.canvases).length > 0;

        if (canvasIsNotEmpty) {
          window.location.href = `/app/canvas/${Object.keys(zsl.data.canvases)[0]}`;
        }
      } catch (error) {
        const statusCode = error instanceof Response ? error.status : 500;
        set(handleZslExecutionErrorCommand, { error, statusCode, prompt });

        Sentry.captureException(
          error instanceof Error ? error : new Error(JSON.stringify(error)),
          {
            contexts: {
              canvas: {
                message:
                  "ZSL execution error - network or fetch failure (no Canvas)",
                zsl: zslTemplate,
                statusCode,
                context: "Network or fetch error",
              },
            },
          },
        );
      } finally {
        set(isExecutingTemplateAtom, false);
      }
    } else {
      const { canvases, ...restOfZslTemplate } = zslTemplate!;
      try {
        const zslRes = await fetch("/api/canvasui/zsl", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            template: {
              meta: {},
              ...restOfZslTemplate,
            },
          }),
        });

        if (!zslRes.ok) {
          const error = await zslRes.json();
          const statusCode = zslRes.status;

          set(handleZslExecutionErrorCommand, { error, statusCode, prompt });

          Sentry.captureException(
            new Error("ZSL execution error - API response failure"),
            {
              extra: { error, zsl: restOfZslTemplate, statusCode },
            },
          );

          return;
        }

        const result: ZSLFromTemplate = await zslRes.json();

        const canvas = get(canvasAtom);
        const nodes = get(nodesAtom);
        const edges = get(edgesAtom);

        const hydratedCanvas = assignAssetsToCanvas(
          { ...canvas, nodes, edges }, // Pass in current nodes & edges
          result,
        );

        /* Clearing out template now that it's been used so saving nodes and edges will persist.

        This needs to go before setting the nodes so that zapAsyncAtomFamily will make a fetch call with the real zap ids or else it will try to find the zap in the template */
        set(templateAtom, undefined);
        set(nodesAtom, hydratedCanvas.nodes);
        set(edgesAtom, hydratedCanvas.edges);

        const canvasToSave: CanvasToSave = {
          nodes: hydratedCanvas.nodes,
          edges: hydratedCanvas.edges,
        };

        const newCanvasName = canvas.name
          ? undefined
          : removeTemplateFromString(restOfZslTemplate.name);

        if (newCanvasName) {
          // Only set the new zsl name as canvas name when user hasn't named their canvas
          set(canvasAtom, (prevCanvas) => ({
            ...prevCanvas,
            name: newCanvasName,
          }));
          canvasToSave.name = newCanvasName;
        }

        const newCanvasDescription = canvas.description
          ? undefined
          : restOfZslTemplate.description;

        if (newCanvasDescription) {
          // Only set the new zsl description as canvas description when user hasn't added a description
          set(canvasAtom, (prevCanvas) => ({
            ...prevCanvas,
            description: newCanvasDescription,
          }));
          canvasToSave.description = newCanvasDescription;
        }

        set(showCanvasTemplateWidgetAtom, false);
        await set(saveCanvasAsyncSetterAtom, canvasToSave);
      } catch (error) {
        const statusCode = error instanceof Response ? error.status : 500;
        set(handleZslExecutionErrorCommand, { error, statusCode, prompt });

        Sentry.captureException(
          error instanceof Error ? error : new Error(JSON.stringify(error)),
          {
            contexts: {
              canvas: {
                message: "ZSL execution error - network or fetch failure",
                zsl: restOfZslTemplate,
                statusCode,
                context: "Network or fetch error",
              },
            },
          },
        );
      } finally {
        set(isExecutingTemplateAtom, false);
      }
    }
  },
);

executeTemplateAndSaveAsyncAtom.debugLabel = "executeTemplateAndSaveAsyncAtom";

function isValidUUID(value: string) {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(value);
}
