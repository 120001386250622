import { atom } from "jotai";
import { uuid } from "../utils/uuid";

import { getOutgoers, Node, NodeProps } from "reactflow";
import { nodeAtomFamily } from "./nodeAtomFamily";
import { takeSnapshotAtom } from "../diagramming";
import { nodesAtom } from "./nodesAtom";
import { edgesAtom } from "../edges";
import { updateDescendantPositionsAtom } from "../zaps";
import { NodesAndEdges } from "../types";
import { snapToAdjacentNode } from "../utils";
import { MARKER } from "../constants";

type Params = {
  id: NodeProps["id"];
  saveNodesAndEdges: ({ nodes, edges }: NodesAndEdges) => void;
  centerAndZoomOnNode: (node: Node) => void;
};

export const addTargetNodeAtom = atom(
  null,
  (get, set, { id, saveNodesAndEdges, centerAndZoomOnNode }: Params) => {
    const sourceNode = get(nodeAtomFamily(id));
    if (!sourceNode) {
      return;
    }

    set(takeSnapshotAtom);

    const nodes = get(nodesAtom);
    const edges = get(edgesAtom);

    let updatedEdges = edges;

    // create a unique id for the placeholder node that will be added as a target of the clicked node
    const targetId = uuid();

    // Not used - simply to make TS happy
    const tempPosition = {
      x: 0,
      y: 0,
    };

    // create a placeholder node that will be added as a target of the clicked node
    const newNode = {
      id: targetId,
      type: "default",
      selected: true,
      data: {
        label: "",
      },
      position: tempPosition,
    };

    // node will have position after running snapToAdjacentNode
    const targetNode = snapToAdjacentNode({
      newNode,
      nodes,
      edges,
      isPathNode: false,
      sourceNode,
    });

    // we need a connection from the source node to the new target node
    const sourceToTargetEdge = {
      id: `${sourceNode.id}=>${targetId}`,
      source: sourceNode.id,
      target: targetId,
      markerEnd: MARKER,
    };

    const outgoers = getOutgoers(sourceNode, nodes, edges).filter(
      (node) => node.type !== "zapStep",
    );

    set(edgesAtom, (prevEdges) => {
      let newEdges = prevEdges;

      // If outgoers exists, user is trying to insert node in between two nodes.
      // We still add the node like normal, but the outgoing edge needs to have a new source
      // If there are multiple children outgoing from this node, don't insert.
      if (outgoers.length === 1) {
        newEdges = newEdges.map((edge) => {
          /** Need to make sure edge is not a Zap step edge or else it'll change the source of the Zap step edge
           * when you try to add a new middle step with Zap node as a source */
          if (edge.source === sourceNode.id && edge.type !== "zapStepEdge") {
            return {
              ...edge,
              source: targetNode.id,
            };
          }
          return edge;
        });
      }
      newEdges = newEdges.concat([sourceToTargetEdge]);
      updatedEdges = newEdges;
      return newEdges;
    });

    set(nodesAtom, (prevNodes) => {
      const deselectedNodes = prevNodes.map((node) => {
        return {
          ...node,
          selected: false,
        };
      });

      return [...deselectedNodes, targetNode];
    });

    const updatedDescendantNodes = set(updateDescendantPositionsAtom, targetId);

    saveNodesAndEdges({
      nodes: updatedDescendantNodes,
      edges: updatedEdges,
    });

    centerAndZoomOnNode(targetNode);
  },
);

addTargetNodeAtom.debugLabel = "addTargetNodeAtom";
