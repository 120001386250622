import { atom } from "jotai";
import { canvasIdAtom } from "../canvas";

type MakePublicBulkResponse = Record<string, string>;

export const makeImagesPublicCommand = atom(
  null,
  async (get, _set, imageIds: string[]) => {
    if (!imageIds.length) {
      return {};
    }

    try {
      const canvasId = get(canvasIdAtom);

      const response = await fetch(
        `/api/canvas/v1/canvases/${canvasId}/images/make-public`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ imageIds }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to make images public");
      }

      const data: MakePublicBulkResponse = await response.json();
      return data;
    } catch (error) {
      console.error("Failed to make images public:", error);
      throw error;
    }
  },
);

makeImagesPublicCommand.debugLabel = "makeImagesPublicCommand";
