import { atom } from "jotai";
import { nodesAtom } from "../nodes";
import { atomFamily } from "jotai/utils";
import { findPathParentForVisibleSteps } from "../utils";
import {
  DEFAULT_NODE_WIDTH,
  ZAP_STEP_NODE_HEIGHT,
} from "@zapier/canvas-constants";
import {
  ZapErrorObject,
  zapMetadataAsyncAtomFamily,
} from "./zapAsyncAtomFamily";
import { ZapMetadata } from "../types";

export const zapStepsByIdAsyncAtomFamily = atomFamily((zapNodeId: string) => {
  const zapStepsAtom = atom(async (get) => {
    const nodes = get(nodesAtom);
    const zapNode = nodes.find((node) => node.id === zapNodeId)!;
    const zapId = zapNode.data.zapId;

    const fetchedZap = await get(zapMetadataAsyncAtomFamily(zapId));
    const isPublished = (fetchedZap as ZapMetadata)?.is_published ?? false;
    const zap =
      !fetchedZap || (fetchedZap as ZapErrorObject).error
        ? undefined
        : (fetchedZap as ZapMetadata);

    const zapSteps = zap?.steps ?? [];
    const visibleStepsWithPathParents = findPathParentForVisibleSteps(zapSteps);

    const zapStepNodes = visibleStepsWithPathParents.map((step, i) => {
      return {
        type: "zapStep",
        id: `zap-${zapNode.id}-${step.id}`,
        data: {
          stepNumber: i + 1,
          step,
          isZapPublished: isPublished,
        },
        position: {
          x: 0,
          y: 0,
        },
        width: DEFAULT_NODE_WIDTH,
        height: ZAP_STEP_NODE_HEIGHT,
        parentId: zapNode.id,
        selectable: true,
        deletable: false,
        draggable: false,
        connectable: isPublished,
      };
    });

    const zapStepEdges = zapStepNodes.map((node, i) => {
      const pathParentId = node.data.step.pathParentId;
      const pathParentFullId = `zap-${zapNode.id}-${pathParentId}`;

      const source =
        i === 0
          ? node.parentId
          : pathParentId
            ? pathParentFullId
            : zapStepNodes[i - 1].id;
      const target = node.id;
      const sourceHandle =
        i === 0 ? "zap-node-bottom-handle" : "zap-step-bottom-handle";
      const targetHandle = "zap-step-top-handle";

      return {
        id: `${source}=>${target}`,
        type: "zapStepEdge",
        source,
        target,
        sourceHandle,
        targetHandle,
        deletable: false,
        focusable: false,
        animated: true,
      };
    });

    const zapStepsId = zapStepNodes.map((zapStep) => zapStep.id);

    const zapStepEdgesId = zapStepEdges.map((edge) => edge.id);

    return {
      nodes: zapStepNodes,
      edges: zapStepEdges,
      nodeIDs: zapStepsId,
      edgeIDs: zapStepEdgesId,
    };
  });

  zapStepsAtom.debugLabel = `zapStepsByIdAsyncAtomFamily(${zapNodeId})`;
  return zapStepsAtom;
});
