import { atom } from "jotai";
import { zapStepNodesAtomFamily } from "./zapStepNodesAtomFamily";
import { nodesAtom } from "../nodes";
import { edgesAtom } from "../edges";
import { atomFamily } from "jotai/utils";
import { createCollapsedZapStepAndEdges } from "../utils";

export const zapStepsWithConnectionsAtomFamily = atomFamily(
  (zapNodeId: string) => {
    const zapStepsWithConnectionsAtom = atom((get) => {
      const nodes = get(nodesAtom);
      const edges = get(edgesAtom);

      const zapSteps = get(zapStepNodesAtomFamily(zapNodeId));

      const { zapStepsWithConnections, zapStepEdges } =
        createCollapsedZapStepAndEdges({
          zapNodeId,
          zapSteps,
          nodes,
          edges,
        });

      return {
        collapsedZapSteps: zapStepsWithConnections,
        collapsedZapStepEdges: zapStepEdges,
      };
    });

    zapStepsWithConnectionsAtom.debugLabel = `zapStepsWithConnectionsAtomFamily(${zapNodeId})`;
    return zapStepsWithConnectionsAtom;
  },
);
