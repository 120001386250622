import { atomWithRefresh } from "../utils";
import { AllPlansType } from "../types";

interface InterfacesApiResponse {
  currentInterfacesAccountProjectCount: number;
  currentInterfacesAccountProjectLimit: number;
  currentInterfacesPlanName: AllPlansType;
  needsToUpgrade: boolean;
}

interface InterfacesApiErrorResponse {
  currentInterfacesAccountProjectCount: 0;
  currentInterfacesAccountProjectLimit: undefined;
  currentInterfacesPlanName: undefined;
  needsToUpgrade: false;
}

export const interfacesUsageAsyncAtom = atomWithRefresh(async () => {
  try {
    const response = await fetch(`/api/canvasui/interfaces`);

    if (!response.ok) {
      const data = await response.json();
      const errorMessage = data.error || "Error fetching interfaces usage";
      throw new Error(errorMessage);
    }

    const responseData: InterfacesApiResponse = await response.json();
    return responseData;
  } catch (error) {
    return {
      currentInterfacesAccountProjectCount: 0,
      currentInterfacesAccountProjectLimit: undefined,
      currentInterfacesPlanName: undefined,
      needsToUpgrade: false,
    } as InterfacesApiErrorResponse;
  }
});

interfacesUsageAsyncAtom.debugLabel = "interfacesUsageAsyncAtom";
