import { atom } from "jotai";
import { XYPosition } from "reactflow";

export type CursorStates =
  | "select"
  | "pan"
  | "connect"
  | "comment"
  | "add-group";

export const cursorAtom = atom<CursorStates>("select");

cursorAtom.debugLabel = "cursorAtom";

export const isActivelyAddingGroupNodeAtom = atom(false);
isActivelyAddingGroupNodeAtom.debugLabel = "isActivelyAddingGroupNodeAtom";

export const groupNodeCreateStartAtom = atom<XYPosition | null>(null);
groupNodeCreateStartAtom.debugLabel = "groupNodeCreateStartAtom";
