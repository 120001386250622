import { atom } from "jotai";
import { selectedNodesAtom } from "./selectedNodesAtom";
import { Node } from "reactflow";

export const singleSelectedNodeAtom = atom<Node | null>((get) => {
  const selectedNodes = get(selectedNodesAtom);
  if (selectedNodes && selectedNodes.length === 1) {
    return selectedNodes[0];
  }

  return null;
});

singleSelectedNodeAtom.debugLabel = "singleSelectedNodeAtom";
