import { Node } from "reactflow";

/**
 * Transforms the position of a Zap step node from relative to absolute by adding its
 * position to its parent node's position. Zap step nodes' positions are relative
 * to their parentNode's position. This function ensures their positions are accurately
 * represented in the overall graph layout by converting those relative positions into
 * absolute coordinates.
 */
export function getAbsoluteNodePosition(node: Node, allNodes: Node[]) {
  if (!node?.parentId) {
    return node?.position;
  }

  const parentNode = allNodes.find((n) => n.id === node.parentId);
  if (!parentNode) {
    throw new Error(`Parent node ${node.parentId} not found`);
  }

  return {
    x: node.position.x + parentNode.position.x,
    y: node.position.y + parentNode.position.y,
  };
}
