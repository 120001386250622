import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { templateAtom } from "../templates";
import { Table } from "../types";
import { extractAssetId, isTemplateTableId } from "../utils";

export const tableAsyncAtomFamily = atomFamily(
  (tableId: string | undefined) => {
    const tableAtom = atom(async (get) => {
      if (!tableId) {
        return null;
      }

      const isTemplateTable = isTemplateTableId(tableId);
      const zslTemplate = get(templateAtom);

      if (zslTemplate && isTemplateTable) {
        const coreTableId = extractAssetId(tableId);

        const tableTemplate = zslTemplate.tables?.find(
          (table) => coreTableId === table.id,
        );

        if (tableTemplate) {
          return tableTemplate;
        }
      }

      // Because we don't want to fetch with template asset id and get a 404 Not Found error
      if (isTemplateTable) {
        return null;
      }

      try {
        const res = await fetch(`/api/canvas/v1/tables/${tableId}/`, {
          headers: {
            "trailing-slash-hack": "true",
          },
        });

        if (res.ok) {
          const json = await res.json();
          return json as Table;
        } else {
          console.error("Failed to fetch table:", res.statusText);
          return null;
        }
      } catch (error) {
        console.error("Error fetching table:", error);
        return null;
      }
    });

    tableAtom.debugLabel = `tableAsyncAtom(${tableId})`;
    return tableAtom;
  },
);
