import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { TableUsage } from "../types";
import { isTemplateTableId } from "../utils";

export const tableUsageAsyncAtomFamily = atomFamily(
  (tableId: string | undefined) => {
    const tableUsageAtom = atom(async () => {
      if (!tableId || isTemplateTableId(tableId)) {
        return { recordCount: 0 };
      }

      try {
        const res = await fetch(`/api/canvasui/tables/${tableId}/usage`);

        if (res.ok) {
          const json = await res.json();

          return json as TableUsage;
        } else {
          console.error("Failed to fetch table usage:", res.statusText);
          return { recordCount: 0 };
        }
      } catch (error) {
        console.error("Error fetching table usage:", error);
        return { recordCount: 0 };
      }
    });

    tableUsageAtom.debugLabel = `tableUsageAsyncAtomFamily(${tableId})`;
    return tableUsageAtom;
  },
);
