import { Edge } from "reactflow";
import { isTempEdge } from "./isTempEdge";

export function processEdgesBeforeSave(edges: Edge[]): Edge[] {
  return edges.reduce<Edge[]>((acc, edge) => {
    if (edge.type !== "zapStepEdge" && !isTempEdge(edge)) {
      /** Remove the 'selected' property from each edge so when you open a Canvas, nothing is selected.
       Remove className since UI presentation should not be stored as state.
       */
      const { selected, className, ...rest } = edge;
      acc.push(rest);
    }
    return acc;
  }, []);
}
