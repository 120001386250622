import { atom } from "jotai";
import { nodesAtom } from "../nodes";
import { atomFamily } from "jotai/utils";
import { getAbsoluteNodePosition } from "../utils";

/** Be aware that we are converting the position to absolute in order to get the correct bounds when using getNodesBounds */
export const zapStepNodesVisibleAtomFamily = atomFamily((zapNodeId: string) => {
  const zapStepNodesVisibleAtom = atom((get) => {
    const nodes = get(nodesAtom);

    return nodes
      .filter(
        (node) =>
          node.type === "zapStep" &&
          node.parentId === zapNodeId &&
          node.hidden !== true,
      )
      .map((node) => ({
        ...node,
        position: getAbsoluteNodePosition(node, nodes),
      }));
  });

  zapStepNodesVisibleAtom.debugLabel = `zapStepNodesVisibleAtom(${zapNodeId})`;
  return zapStepNodesVisibleAtom;
});
