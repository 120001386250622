import { atom } from "jotai";
import {
  interfacesUsageAsyncAtom,
  TablesApiErrorResponse,
  TablesApiResponse,
  tablesUsageAsyncAtom,
} from "../apps";
import { numberOfInterfaceNodesAtom, numberOfTableNodesAtom } from "../nodes";
import { getUpgradePlanType } from "../utils";
import { PlanType } from "../types";
import { isTemplateRouteAtom } from "./isTemplateRouteAtom";
import { showCanvasTemplateWidgetAtom } from "./showCanvasTemplateWidgetAtom";

interface UpgradeBannerProps {
  title: string;
  description: string;
  tablesPlanType?: PlanType;
  interfacesPlanType?: PlanType;
}

type Results = UpgradeBannerProps | null;

function isTablesUsageApiSuccessful(
  tablesUsage: TablesApiResponse | TablesApiErrorResponse,
): tablesUsage is TablesApiResponse {
  return "maxTableCount" in tablesUsage && "tableCount" in tablesUsage;
}

export const upgradeBannerPropsAsyncAtom = atom<Promise<Results>>(
  async (get) => {
    const numberOfTableNodes = get(numberOfTableNodesAtom);
    const numberOfInterfaceNodes = get(numberOfInterfaceNodesAtom);

    const [tablesUsage, interfacesUsage] = await Promise.all([
      get(tablesUsageAsyncAtom),
      get(interfacesUsageAsyncAtom),
    ]);

    const isTemplatePage = get(isTemplateRouteAtom);
    const showCanvasTemplateWidget = get(showCanvasTemplateWidgetAtom);
    const systemOrTemplate =
      isTemplatePage || showCanvasTemplateWidget ? "template" : "system";

    // Tables
    const isSuccessful = isTablesUsageApiSuccessful(tablesUsage);

    const currentPlanLimit = isSuccessful ? tablesUsage.maxTableCount : 0;
    const tableCount = isSuccessful ? tablesUsage.tableCount : 0;
    const remainingTableCapacity = currentPlanLimit - tableCount;

    const needsToUpgradeTables = isSuccessful
      ? numberOfTableNodes > remainingTableCapacity
      : false; // Default to false if error fetching tables usage (i.e., logged out)

    const tablesPlanType = getUpgradePlanType({
      type: "tables",
      currentPlanLimit,
    });

    // Interfaces
    const currentPlanName = interfacesUsage.currentInterfacesPlanName;
    const maxProjectLimit =
      interfacesUsage.currentInterfacesAccountProjectLimit ?? 0;
    const projectCount = interfacesUsage.currentInterfacesAccountProjectCount;
    const remainingInterfaceCapacity = maxProjectLimit - projectCount;

    const needsToUpgradeInterfaces =
      interfacesUsage.currentInterfacesAccountProjectLimit === undefined
        ? false
        : numberOfInterfaceNodes > remainingInterfaceCapacity;

    const interfacesPlanType = getUpgradePlanType({
      type: "interfaces",
      currentPlanName,
    });

    if (needsToUpgradeTables && needsToUpgradeInterfaces) {
      return {
        title: `Upgrade to build this ${systemOrTemplate}`,
        description: `New assets will be created that will put you over your plans limit. Upgrade to use this ${systemOrTemplate}.`,
        tablesPlanType,
        interfacesPlanType,
      };
    }

    if (needsToUpgradeTables) {
      return {
        title: "You have reached your tables limit",
        description: `${
          numberOfTableNodes === 1
            ? "A new table"
            : `${numberOfTableNodes} tables`
        } will be created that will put you over your plans limit. Upgrade to use this ${systemOrTemplate}.`,
        tablesPlanType,
      };
    }

    if (needsToUpgradeInterfaces) {
      return {
        title: "You have reached your interfaces limit",
        description: `${
          numberOfInterfaceNodes === 1
            ? "A new interface"
            : `${numberOfInterfaceNodes} interfaces`
        } will be created that will put you over your plans limit. Upgrade to use this ${systemOrTemplate}.`,
        interfacesPlanType,
      };
    }

    return null;
  },
);

upgradeBannerPropsAsyncAtom.debugLabel = "upgradeBannerPropsAsyncAtom";
