import { Interface } from "../types";
import { atomWithRefresh } from "../utils";

export const interfacesAsyncAtom = atomWithRefresh<Promise<Interface[]>>(
  async () => {
    try {
      const res = await fetch(`/api/canvas/v1/projects/`, {
        headers: {
          "trailing-slash-hack": "true",
        },
      });

      if (!res.ok) {
        const data = await res.json();
        throw new Error(data.errors);
      }

      return await res.json();
    } catch (e) {
      return [];
    }
  },
);

interfacesAsyncAtom.debugLabel = "interfacesAsyncAtom";
