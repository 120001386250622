export function extractAssetId(input: string): string {
  if (!input) return "";
  // Use regex to match uppercase words and keep periods between them
  const matches = input.match(/[A-Z0-9_]+(\.[A-Z0-9_]+)*/g);

  // Join the matches without any additional separators
  if (matches) {
    return matches.join("");
  }

  return "";
}
