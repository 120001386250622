import { ZapMetadataStep } from "../types";

const getChildSteps = (
  steps: ZapMetadataStep[],
  stepMap: Map<string, ZapMetadataStep>,
) => {
  for (const step of steps) {
    stepMap.set(step.id, step);

    if (step.steps) {
      getChildSteps(step.steps, stepMap);
    }
  }
};

export function getAllStepsFlat(steps: ZapMetadataStep[]) {
  const stepMap = new Map<string, ZapMetadataStep>();
  getChildSteps(steps, stepMap);

  return Array.from(stepMap.values());
}
