import { isPathStep } from "./isPathStep";
import { Step, Steps, ZapMetadataStep } from "../types";

const collectUniqueAppsRecursive = (
  steps: ZapMetadataStep[],
  seenApps: Map<string, ZapMetadataStep>,
) => {
  for (const step of steps) {
    const isPath = isPathStep(step);
    if (isPath) {
      // If it is a path step, set the app to "BranchingAPI" so the icon will show up
      step.app = "BranchingAPI";
    }

    // Some steps have no app, ignore those or else we get a gap in the icons
    if (step.app) {
      if (!seenApps.has(step.app)) {
        seenApps.set(step.app, step);
      }
    }

    // For path steps, recurse into nested steps if they exist
    if (step.steps) {
      collectUniqueAppsRecursive(step.steps, seenApps);
    }
  }
};

export const getUniqueAppsFromSteps = (steps: ZapMetadataStep[]) => {
  const seenApps = new Map<string, Step>();
  collectUniqueAppsRecursive(steps, seenApps);

  // Filter out 'EngineAPI' from the result since this is not a real app but only do it at the end since it can be a path step
  seenApps.delete("EngineAPI");

  // Convert Map values to an array of steps
  const result: Steps = Array.from(seenApps.values());

  return result.map((step) => step.app);
};
