import { atom } from "jotai";
import { canvasIdAtom } from "../canvas";
import { ProjectDetails, ProjectItem } from "../types";

export class ProjectFetchError extends Error {
  status: number;
  code?: string;

  constructor(message: string, status: number, code?: string) {
    super(message);
    this.status = status;
    this.code = code;
    this.name = "ProjectFetchError";
  }
}

/** When using this directly in component, be sure to wrap with ErrorBoundary since this will throw if there's an error, such as when you're logged out.
 * When using inside another atom, use with try-catch.
 * */
export const projectAsyncAtom = atom<Promise<ProjectDetails | null>>(
  async (get) => {
    const canvasId = get(canvasIdAtom);

    // This API has no permission check, so it will return any project id for the account
    const response = await fetch(
      `/api/v1/assets?ids=${canvasId}&asset_type=canvas`,
    );

    if (!response.ok) {
      const data = await response.json();

      const firstError =
        Array.isArray(data.errors) && data.errors.length > 0
          ? data.errors[0]
          : null;
      const error = firstError?.detail ?? data.error;

      throw new ProjectFetchError(
        error || "Unknown error fetching project",
        response.status,
        firstError?.code,
      );
    }

    const data: { results: ProjectItem[] } = await response.json();

    if (data.results.length === 0) {
      return null;
    }

    return data.results[0].project;
  },
);

projectAsyncAtom.debugLabel = "projectAsyncAtom";
