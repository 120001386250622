import { atom } from "jotai";

export const isTemplateRouteAtom = atom(() => {
  if (typeof window !== "undefined") {
    return window.location.pathname.includes("template");
  }

  return false;
});

isTemplateRouteAtom.debugLabel = "isTemplateRouteAtom";
