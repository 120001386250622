import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { getNodesBounds } from "reactflow";
import { nodesAtom } from "./nodesAtom";

const padding = 25;

export const groupNodeDimensionsAtomFamily = atomFamily((groupNodeId) => {
  const groupNodeDimensionsAtom = atom((get) => {
    const nodes = get(nodesAtom);
    const groupNode = nodes.find((node) => node.id === groupNodeId);

    if (!groupNode) {
      return { width: 0, height: 0 };
    }

    const children = nodes.filter((node) =>
      groupNode.data.nodes.includes(node.id),
    );

    const rect = getNodesBounds(children);

    return {
      minWidth: rect.width + padding * 2,
      minHeight: rect.height + padding * 2 + 4,
    };
  });

  groupNodeDimensionsAtom.debugLabel = `groupNodeDimensionsAtomFamily(${groupNodeId})`;
  return groupNodeDimensionsAtom;
});
