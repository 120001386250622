import { atomWithRefresh } from "../utils";

export interface TablesApiResponse {
  tableCount: number;
  maxTableCount: number;
  maxRecordsCount: number;
  needsToUpgrade: boolean;
}

export interface TablesApiErrorResponse {
  maxRecordsCount: 0;
}

export const tablesUsageAsyncAtom = atomWithRefresh(async () => {
  try {
    const res = await fetch(`/api/canvasui/tables`);

    if (res.ok) {
      const json: TablesApiResponse = await res.json();

      return json;
    } else {
      console.error("Failed to fetch tables usage:", res.statusText);
      return { maxRecordsCount: 0 } as TablesApiErrorResponse;
    }
  } catch (error) {
    console.error("Error fetching tables usage:", error);
    return { maxRecordsCount: 0 } as TablesApiErrorResponse;
  }
});

tablesUsageAsyncAtom.debugLabel = "tablesUsageAsyncAtom";
