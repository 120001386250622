import { atom } from "jotai";
import { edgesAtom } from "./edgesAtom";
import { reconnectEdge } from "reactflow";

export const updateEdgeConnectionAtom = atom(
  null,
  (get, set, { oldEdge, newConnection }) => {
    const edges = get(edgesAtom);
    const updatedEdges = reconnectEdge(oldEdge, newConnection, edges);
    set(edgesAtom, updatedEdges);
  },
);

updateEdgeConnectionAtom.debugLabel = "updateEdgeConnectionAtom";
