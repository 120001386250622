import { atom } from "jotai";

import { ZSL } from "../types";

/* Use fine-grained selector, isTemplateModeAtom, to check if you're in template mode.
   Use fine-grained selector, templateSlugAtom, to get the template slug

   When performing a coarse-grained read, the component becomes dependent on the entire piece of state and
   will re-render when any part of it changes. If the component only needs a few attributes, then we’re performing
   needless rendering when the unused attributes change.

   See: https://docs.k8s.zapier.com/editor/Architecture/StateManagement/Jotai/Patterns.html#prefer-using-fine-grained-selectors
*/
export const templateAtom = atom<ZSL | undefined>(undefined);

templateAtom.debugLabel = "templateAtom";
