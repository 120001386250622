import { Node, Edge, getIncomers } from "reactflow";
import { getCenteredYPosition } from "./getCenteredYPosition";
import { NodeType } from "../types";
import { graphlib } from "dagre";

type Params = {
  node: Node;
  nodes: Node[];
  edges: Edge[];
  dagreGraph: graphlib.Graph<object>;
  layoutedNode: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
};

export function adjustYPositionForStraightLine({
  node,
  nodes,
  edges,
  dagreGraph,
  layoutedNode,
}: Params) {
  if (node.type === "path") {
    const incomers = getIncomers(node, nodes, edges);

    if (incomers.length > 0 && incomers[0].type !== "zapStep") {
      const incomerNode = incomers[0];

      const layoutedIncomerNode = dagreGraph.node(incomerNode.id);

      const incomerNodePosition = layoutedIncomerNode
        ? {
            x: layoutedIncomerNode.x,
            y: layoutedIncomerNode.y,
          }
        : incomerNode.position;

      return getCenteredYPosition({
        nodePosition: incomerNodePosition,
        nodeType: incomerNode.type as NodeType,
        isPathNode: true,
      });
    }

    return layoutedNode.y;
  }

  return layoutedNode.y;
}
