export function isTemplateZapId(zapId: string = "") {
  return zapId.includes("_ZAP_");
}

export function isTemplateTableId(tableId: string = "") {
  return tableId.includes("_TABLE_");
}

export function isTemplateInterfaceProjectId(interfaceProjectId: string = "") {
  return interfaceProjectId.includes("_INTERFACE_");
}

export function isTemplateInterfacePageId(interfacePageId: string = "") {
  return interfacePageId.includes("_PAGE_");
}

export function isTemplateChatbotId(chatbotId: string = "") {
  return chatbotId.includes("_CHATBOT_");
}
