export const PAGE_VIEW_EVENT = "web_analytics.tracking.page_view.PageViewEvent";

export const USER_INTERACTION_EVENT =
  "platform.canvas.web_analytics.CanvasUserInteractionEvent";

export const RESOURCE_CREATED_EVENT =
  "platform.canvas.management.CanvasResourceCreatedEvent";

export const RESOURCE_CONNECT_EVENT =
  "platform.canvas.management.CanvasResourceConnectEvent";

export const AI_PREDICTION_OUTPUT_EVENT = "ai.tracking.PredictionOutputEvent";

export const AI_PREDICTION_USER_FEEDBACK_EVENT =
  "ai.tracking.PredictionUserFeedbackEvent";

export const CREATE_NEW_TABLE_BUTTON_TEXT = "Create Table";

export const CREATE_NEW_INTERFACE_BUTTON_TEXT = "Create Interface";

export const CREATE_NEW_ZAP_BUTTON_TEXT = "Create Zap";

export const CREATE_NEW_CHATBOTS_BUTTON_TEXT = "Create Chatbot";

export const DRAG_AND_DROP = "drag_and_drop";

export const HELPFUL = "EXPLICIT_GOOD";

export const NOT_HELPFUL = "EXPLICIT_BAD";

export const ZSL_EXECUTION_ERROR = "platform.zsl.ZSLExecutionErrorEvent";
