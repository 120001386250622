import { atom } from "jotai";
import { getOutgoers, NodeChange, NodeDimensionChange } from "reactflow";
import { edgesAtom } from "../edges/edgesAtom";
import { nodesAtom } from "../nodes/nodesAtom";

/** vertical for TB rankdir: distance between different ranks (levels) */
const DEFAULT_RANK_SEP = 50;

export const adjustZapStepsPositionAtom = atom(
  null,
  (get, set, nodeChanges: NodeChange[]) => {
    const nodes = get(nodesAtom);
    const edges = get(edgesAtom);

    const zapNodeIdsWithDimensionChanges = nodeChanges.reduce(
      (acc, nodeChange) => {
        if (
          nodeChange.type === "dimensions" &&
          nodes.find((n) => n.id === nodeChange.id && n.type === "zap")
        ) {
          acc.push(nodeChange.id);
        }
        return acc;
      },
      [] as string[],
    );

    if (zapNodeIdsWithDimensionChanges.length === 0) {
      return;
    }

    zapNodeIdsWithDimensionChanges.forEach((zapNodeId) => {
      const zapNode = nodes.find((n) => n.id === zapNodeId);
      if (zapNode) {
        const nodeChange = (nodeChanges as NodeDimensionChange[]).find(
          (nc) => nc.id === zapNodeId,
        );

        if (nodeChange && nodeChange.dimensions) {
          const {
            dimensions: { height: newZapNodeHeight },
          } = nodeChange;

          const outgoers = getOutgoers(zapNode, nodes, edges);
          const zapStepOutgoers = outgoers.filter((n) => n.type === "zapStep");
          const firstZapStep =
            zapStepOutgoers.length === 0 ? null : zapStepOutgoers[0];
          const expectedFirstZapStepYPosition =
            newZapNodeHeight + DEFAULT_RANK_SEP;

          if (firstZapStep) {
            const offsetY =
              expectedFirstZapStepYPosition - firstZapStep.position.y;

            if (offsetY !== 0) {
              set(nodesAtom, (prev) => {
                return prev.map((n) => {
                  if (n.type === "zapStep" && n.parentId === zapNodeId) {
                    return {
                      ...n,
                      position: {
                        x: n.position.x,
                        y: n.position.y + offsetY,
                      },
                    };
                  }
                  return n;
                });
              });
            }
          }
        }
      }
    });
  },
);

adjustZapStepsPositionAtom.debugLabel = "adjustZapStepsPositionAtom";
