import { Edge } from "reactflow";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { nodesAtom } from "../nodes";
import { edgesAtom } from "../edges";
import { zapStepsWithConnectionsAtomFamily } from "./zapStepsWithConnectionsAtomFamily";
import { zapStepNodesAtomFamily } from "./zapStepNodesAtomFamily";
import { layoutElementsVertically } from "../utils";
import { allZapStepEdgesAtomFamily } from "./allZapStepEdgesAtomFamily";

function removeZapStepEdges(edges: Edge[], layoutedAllZapStepsIds: string[]) {
  return edges.filter(
    (edge) =>
      !(
        edge.type === "zapStepEdge" &&
        (layoutedAllZapStepsIds.includes(edge.source) ||
          layoutedAllZapStepsIds.includes(edge.target))
      ),
  );
}

export const toggleZapStepNodesAndEdgesAtomFamily = atomFamily(
  (zapNodeId: string) => {
    const ToggleZapStepNodesAndEdgesAtom = atom(
      null,
      (get, set, shouldHide: boolean) => {
        const nodes = get(nodesAtom);

        const allZapStepEdgesAtom = allZapStepEdgesAtomFamily(zapNodeId);
        const allZapStepEdges = get(allZapStepEdgesAtom);

        const allZapSteps = get(zapStepNodesAtomFamily(zapNodeId));
        const allZapStepIds = allZapSteps.map((node) => node.id);
        const allZapStepsMap = new Map(
          allZapSteps.map((node) => [node.id, node]),
        );

        const { collapsedZapSteps, collapsedZapStepEdges } = get(
          zapStepsWithConnectionsAtomFamily(zapNodeId),
        );

        const zapNode = nodes.find((node) => node.id === zapNodeId)!;

        if (shouldHide) {
          if (collapsedZapSteps.length === 0) {
            set(nodesAtom, (prevNodes) => {
              return prevNodes.map((node) => {
                const isZapStep = Boolean(allZapStepsMap.get(node.id));

                if (isZapStep) {
                  return {
                    ...node,
                    hidden: true,
                  };
                }

                if (node.id === zapNodeId) {
                  return {
                    ...node,
                    data: {
                      ...node.data,
                      isExpanded: !shouldHide,
                    },
                  };
                }

                return node;
              });
            });

            set(edgesAtom, (prevEdges) => [
              ...removeZapStepEdges(prevEdges, allZapStepIds),
              ...allZapStepEdges.map((edge) => ({
                ...edge,
                hidden: true,
              })),
            ]);
          } else {
            // Create a new Dagre graph for the connected Zap steps and edges
            const {
              layoutedZapSteps: layoutedCollapsedZapSteps,
              layoutedZapStepEdges: layoutedCollapsedZapStepEdges,
            } = layoutElementsVertically({
              zapNode,
              zapSteps: collapsedZapSteps,
              zapStepEdges: collapsedZapStepEdges,
            });

            const layoutedCollapsedZapStepsMap = new Map(
              layoutedCollapsedZapSteps.map((node) => [node.id, node]),
            );

            set(nodesAtom, (prevNodes) => {
              return prevNodes.map((node) => {
                const layoutedNodeWithConnections =
                  layoutedCollapsedZapStepsMap.get(node.id);

                if (layoutedNodeWithConnections) {
                  // Zap step with connections will be visible
                  return layoutedNodeWithConnections;
                } else {
                  // Other Zap steps should be hidden
                  const isZapStep = Boolean(allZapStepsMap.get(node.id));
                  if (isZapStep) {
                    return {
                      ...node,
                      hidden: true,
                    };
                  }

                  if (node.id === zapNodeId) {
                    return {
                      ...node,
                      data: {
                        ...node.data,
                        isExpanded: !shouldHide,
                      },
                    };
                  }

                  return node;
                }
              });
            });

            set(edgesAtom, (prevEdges) => [
              ...removeZapStepEdges(prevEdges, allZapStepIds),
              ...layoutedCollapsedZapStepEdges,
            ]);
          }
        } else {
          /** Show ALL the Zap steps for this Zap node */
          // Create a new Dagre graph for the all Zap steps and edges in case Zap node height has changed
          const {
            layoutedZapSteps: layoutedAllZapSteps,
            layoutedZapStepEdges: layoutedAllZapStepEdges,
          } = layoutElementsVertically({
            zapNode,
            zapSteps: allZapSteps,
            zapStepEdges: allZapStepEdges,
          });

          const layoutedAllZapStepsIds = layoutedAllZapSteps.map(
            (node) => node.id,
          );

          const layoutedAllZapStepsMap = new Map(
            layoutedAllZapSteps.map((node) => [node.id, node]),
          );

          set(nodesAtom, (prevNodes) => {
            return prevNodes.map((node) => {
              const layoutedNode = layoutedAllZapStepsMap.get(node.id);

              if (layoutedNode) {
                return {
                  ...layoutedNode,
                  hidden: false,
                };
              }

              if (node.id === zapNodeId) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isExpanded: !shouldHide,
                  },
                };
              }

              return node;
            });
          });

          set(edgesAtom, (prevEdges) => {
            return [
              ...removeZapStepEdges(prevEdges, layoutedAllZapStepsIds),
              ...layoutedAllZapStepEdges,
            ];
          });
        }
      },
    );

    ToggleZapStepNodesAndEdgesAtom.debugLabel = `toggleZapStepNodesAndEdgesAtomFamily(${zapNodeId})`;
    return ToggleZapStepNodesAndEdgesAtom;
  },
);
