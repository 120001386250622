import { atom } from "jotai";
import { templateAtom } from "./templateAtom";
import { removeTemplateFromString } from "../utils";

export const templateNameAtom = atom<string | undefined>((get) => {
  const template = get(templateAtom);
  return template?.name ? removeTemplateFromString(template.name) : undefined;
});

templateNameAtom.debugLabel = "templateNameAtom";
