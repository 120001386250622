export const NO_AUTO_LAYOUT_NODE_TYPES = [
  "recommendationGroup",
  "text",
  "comment",
  "zapStep",
];

/** horizontal: distance between different ranks (levels) */
export const RANK_SEP = 160;

/** vertical: distance between nodes within the same rank (level) */
export const NODE_SEP = 80;

export const PATH_NODE_Y_ADJUSTMENT = 1;
