import { atom } from "jotai";
import { uuid } from "../utils";
import { Notification, NotificationOptions } from "../types";

// Atom to hold the list of notifications
export const notificationsAtom = atom<Notification[]>([]);
notificationsAtom.debugLabel = "notificationsAtom";

export const createNotificationAtom = atom(
  null,
  (get, set, options: NotificationOptions) => {
    const newNotification: Notification = {
      ...options,
      id: `notification-${uuid()}`,
    };

    set(notificationsAtom, (prev) => [...prev, newNotification]);
  },
);

createNotificationAtom.debugLabel = "createNotificationAtom";
