export const NEW_STEP_ID_PREFIX = "_GEN_";

// Seed with the current time
let nextId = Date.now();

export function getNewStepId() {
  const base = nextId;

  // Increment so that each new step ID is unique
  nextId += 1;

  return `${NEW_STEP_ID_PREFIX}${base}`;
}
