import { Node } from "reactflow";
import { atom } from "jotai";
import { loadable } from "jotai/utils";

import { DefaultNodeData, NodeData, Service } from "../types";
import { zapMetadataAsyncAtomFamily } from "../zaps";
import {
  filterByZapNodes,
  flattenOneLevel,
  getUniqueAppsFromSteps,
  isZapErrorObject,
} from "../utils";

import { nodesAtom } from "./nodesAtom";

export const uniqueZapIdsAtom = atom((get) => {
  const nodes: Node<NodeData>[] = get(nodesAtom);
  const zapNodes = filterByZapNodes(nodes);
  const uniqueZapIds = new Set(zapNodes.map((node) => node.data.zapId));
  return Array.from(uniqueZapIds);
});

uniqueZapIdsAtom.debugLabel = "uniqueZapIdsAtom";

/**
 * Returns an array of unique app IDs from all nodes. Handles both default and Zap nodes.
 * For Zap nodes, it recursively retrieves all app IDs from path steps.
 */
export const uniqueAppsForAllNodesAsyncAtom = atom<Promise<string[]>>(
  async (get) => {
    const nodes: Node<NodeData>[] = get(nodesAtom);
    const defaultNodes = nodes.filter((node) => node.type === "default");
    const uniqueZapIds = get(uniqueZapIdsAtom);

    const zapNodeApps = await Promise.all(
      uniqueZapIds.map(async (zapId) => {
        const zap = await get(zapMetadataAsyncAtomFamily(zapId));
        if (!zap || isZapErrorObject(zap)) {
          return [];
        }

        const steps = zap.steps || [];
        return getUniqueAppsFromSteps(steps);
      }),
    );

    const zapNodeAppsFlattened = flattenOneLevel(zapNodeApps);

    const defaultNodeApps = defaultNodes
      .reduce(
        (acc, curr) => [
          ...acc,
          ...((curr.data as DefaultNodeData).services || []),
        ],
        [] as Service[],
      )
      .filter(Boolean);

    const allApps: string[] = [
      ...defaultNodeApps.map((app) => app.currentImplementation.id),
      ...zapNodeAppsFlattened,
    ];

    const uniqueAppsSet = new Set([...allApps]);

    return Array.from(uniqueAppsSet);
  },
);

uniqueAppsForAllNodesAsyncAtom.debugLabel = "uniqueAppsForAllNodesAsyncAtom";

export const loadableAllNodesUniqueAppsAtom = loadable(
  uniqueAppsForAllNodesAsyncAtom,
);
loadableAllNodesUniqueAppsAtom.debugLabel = "loadableAllNodesUniqueAppsAtom";
