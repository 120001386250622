import { atom } from "jotai";
import {
  getCookie,
  newAssetIdentifierAtom,
  projectAssetsAsyncAtom,
  projectAsyncAtom,
} from "../";

type ReturnType = { error: boolean };

export const addAssetToProjectSetterAtom = atom(
  null,
  async (get, set): Promise<ReturnType> => {
    try {
      const project = await get(projectAsyncAtom);
      const newAssetIdentifier = get(newAssetIdentifierAtom);

      if (!project || !newAssetIdentifier) {
        return { error: false };
      }

      const [assetType, assetId] = newAssetIdentifier.split(":");

      const response = await fetch(`/api/v1/projects/${project.id}/assets/`, {
        method: "POST",
        headers: {
          "trailing-slash-hack": "true",
          "Content-Type": "application/json",
          "x-csrftoken": getCookie("csrftoken") || "",
        },
        body: JSON.stringify({
          asset_type: assetType,
          asset_id: assetId,
        }),
      });

      if (response.ok) {
        set(newAssetIdentifierAtom, null);
        // Refresh project assets
        set(projectAssetsAsyncAtom);
        return { error: false };
      } else {
        console.error(
          `Failed to add asset to project ${project.id}:`,
          response.status,
          response.statusText,
        );

        return { error: true };
      }
    } catch (error) {
      console.error(`Error adding asset to project:`, error);
      return { error: true };
    }
  },
);

addAssetToProjectSetterAtom.debugLabel = "addAssetToProjectSetterAtom";
