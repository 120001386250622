import { atom } from "jotai";

import { GAP_BETWEEN_NODES } from "@zapier/canvas-constants";
import { nodesAtom } from "../nodes";
import { edgesAtom } from "../edges";
import { getVisibleDescendants } from "../utils";

/**
 * When you add a step in between 2 nodes, we move all the descendant nodes of that NEW step out but NOT Zap steps since Zap steps are positioned relatively to its Zap node parent.
 * */
export const updateDescendantPositionsAtom = atom(
  null,
  (get, set, nodeId: string) => {
    const nodes = get(nodesAtom);
    const edges = get(edgesAtom);

    const allVisibleDescendantsOfNode = getVisibleDescendants(
      nodeId,
      nodes,
      edges,
      false,
    );

    if (
      !allVisibleDescendantsOfNode ||
      allVisibleDescendantsOfNode?.length === 0
    ) {
      return nodes;
    }

    const nonZapStepDescendantsIds = allVisibleDescendantsOfNode
      .filter((node) => node.type !== "zapStep")
      .map((node) => node.id);

    const updatedNodes = nodes.map((node) => {
      if (nonZapStepDescendantsIds.includes(node.id)) {
        return {
          ...node,
          position: {
            x: node.position.x + GAP_BETWEEN_NODES,
            y: node.position.y,
          },
        };
      }
      return node;
    });

    set(nodesAtom, updatedNodes);

    return updatedNodes;
  },
);

updateDescendantPositionsAtom.debugLabel = "updateDescendantPositionsAtom";
