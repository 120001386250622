import { atom } from "jotai";
import { cursorAtom } from "./cursorAtom";
import { isViewOnlyModeAtom } from "../permissions/isViewOnlyModeAtom";

export const shouldShowAddStepButtonAtom = atom((get) => {
  const cursorMode = get(cursorAtom);
  const isViewOnlyMode = get(isViewOnlyModeAtom);

  return cursorMode !== "connect" && !isViewOnlyMode;
});

shouldShowAddStepButtonAtom.debugLabel = "shouldShowAddStepButtonAtom";
