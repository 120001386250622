import { atom } from "jotai";
import { atomFamily, loadable } from "jotai/utils";
import { currentAccountIdAtom } from "../account";
import { Zap } from "../types";

type Params = {
  searchTerm: string;
  showAllWhenEmpty?: boolean;
};

// Takes a search term and returns the Zaps
export const zapsSearchAsyncAtomFamily = atomFamily(
  ({ searchTerm, showAllWhenEmpty = true }: Params) => {
    const zapsAtom = atom(async (get) => {
      const accountId = get(currentAccountIdAtom);

      if (!showAllWhenEmpty && !searchTerm) {
        return [];
      }

      try {
        const res = await fetch(
          `/api/gulliver/storage/v1/zaps?${
            searchTerm ? `title=${searchTerm}` : ""
          }&account_id=${accountId}`,
        );

        if (!res.ok) {
          console.error(
            `Error fetching Zaps${
              searchTerm ? ` with "${searchTerm}" search term` : ""
            } for account id: ${accountId}`,
          );
          return [];
        }

        const json = await res.json();
        return json.results as Zap[];
      } catch (error) {
        console.error(
          `Error fetching Zaps${
            searchTerm ? ` with "${searchTerm}" search term` : ""
          } for account id: ${accountId}`,
          error,
        );
        return [];
      }
    });

    zapsAtom.debugLabel = `zapsSearchAsyncAtomFamily(searchTerm:${searchTerm}, showAllWhenEmpty:${showAllWhenEmpty})`;
    return loadable(zapsAtom);
  },
  (a, b) =>
    a.searchTerm === b.searchTerm && a.showAllWhenEmpty === b.showAllWhenEmpty,
);
