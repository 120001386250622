import { atom } from "jotai";

type Role = {
  account_id: number;
  role: "owner" | "admin" | "super_admin" | "member";
};

export type Session = {
  is_staff: boolean;
  is_logged_in: boolean;
  first_name: string;
  last_name: string;
  email: string;
  id: number;
  photo_url: string;
  signup: string;
  user_id: number;
  timezone: string;
  roles: Role[];
  current_account_id: number;
};

export const sessionAtom = atom<Session | null>(null);

sessionAtom.debugLabel = "sessionAtom";
