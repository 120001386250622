import { atom } from "jotai";

export const shouldCreateNewInterfaceAtom = atom<boolean>(false);

export const createNewInterfaceAsyncAtom = atom(async (get) => {
  const shouldCreateNewTable = get(shouldCreateNewInterfaceAtom);

  if (!shouldCreateNewTable) {
    return null;
  }

  const response = await fetch(`/api/canvasui/interfaces`, {
    method: "POST",
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.error);
  }

  return await response.json();
});

createNewInterfaceAsyncAtom.debugLabel = "createNewInterfaceAsyncAtom";
