/** IMPORTANT! If you make changes to these vars, check the CSS files of each node type and adjust accordingly */

export const MAX_TITLE_LENGTH = 255;

export const GAP_BETWEEN_NODES = 400;

export const PATH_NODE_HEIGHT = 120;

export const PATH_NODE_WIDTH = 120;

export const DEFAULT_NODE_HEIGHT = 60;

export const DEFAULT_NODE_WIDTH = 280;

export const ZAP_STEP_NODE_HEIGHT = 45; // Must match height in ZapStepNode.module.css

export const HANDLE_DIST_FROM_TOP = 30;

export const ZAP_STEP_NODE_HANDLE_DIST_FROM_TOP = ZAP_STEP_NODE_HEIGHT / 2;

/** When you click a node from the diagramming toolbar, this is the classname of a temp edge that's created as well as the node type that's created */
export const TEMP = "temp";

export const ACTOR_TYPEAHEAD_LABEL = "People or team involved";

export const DEFAULT_NODE_COLOR = "#403f3e"; // neutral700
