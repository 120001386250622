import { atom } from "jotai";

import { atomFamily } from "jotai/utils";
import { Tuple } from "@zapier/permissions";

type Params = {
  canvasId: string;
  allowTeams: boolean;
};

export const permissionsAtom = atom<Tuple[]>([]);

export const isFetchingPermissionsAtom = atom(false);

export const fetchPermissionsAtomFamily = atomFamily(
  ({ canvasId, allowTeams }: Params) => {
    const fetchAtom = atom(null, async (get, set) => {
      if (!allowTeams) {
        return;
      }

      set(isFetchingPermissionsAtom, true);

      try {
        const res = await fetch(`/api/canvas/v1/permissions/${canvasId}/`, {
          headers: {
            "trailing-slash-hack": "true",
          },
        });

        if (!res.ok) {
          set(isFetchingPermissionsAtom, false);
          throw new Error(`Error fetching permissions: ${res.status}`);
        }

        const json: { tuples: Tuple[] } = await res.json();

        set(permissionsAtom, json.tuples);
        set(isFetchingPermissionsAtom, false);
      } catch (error) {
        set(isFetchingPermissionsAtom, false);
        throw error;
      }
    });

    fetchAtom.debugLabel = `permissionsAtomFamily(allowTeams: ${allowTeams})`;
    return fetchAtom;
  },
  isEqual,
);

function isEqual(a: Params, b: Params) {
  return a.canvasId === b.canvasId && a.allowTeams === b.allowTeams;
}
