import { MarkerType } from "reactflow";
import { Colors } from "@zapier/design-system";

export const MARKER = {
  type: MarkerType.Arrow,
  strokeWidth: 1.5,
  color: Colors.neutral700,
};

export const SELECTED_MARKER = {
  type: MarkerType.Arrow,
  strokeWidth: 1.5,
  color: Colors.bluebell,
};
