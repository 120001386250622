import { atom } from "jotai";
import { newAssetNameAtom } from "./newAssetNameAtom";

export const shouldCreateNewTableAtom = atom<boolean>(false);

shouldCreateNewTableAtom.debugLabel = "shouldCreateNewTableAtom";

export const createNewTableAsyncAtom = atom(async (get) => {
  const shouldCreateNewTable = get(shouldCreateNewTableAtom);

  if (!shouldCreateNewTable) {
    return null;
  }

  const name = get(newAssetNameAtom);

  const response = await fetch(`/api/canvasui/tables`, {
    method: "POST",
    body: JSON.stringify({ name }),
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.error);
  }

  return await response.json();
});

createNewTableAsyncAtom.debugLabel = "createNewTableAsyncAtom";
