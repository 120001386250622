import { atom } from "jotai";

import { atomFamily } from "jotai/utils";
import {
  Tuple,
  Team,
  TeamWithPermission,
  getSelectedMembersOrTeamsWithPermission,
  getUniqueSelectedTeamIds,
} from "@zapier/permissions";
import { permissionsAtom } from "./fetchPermissionsAtomFamily";

type Params = {
  currentAccountId: number | undefined;
  /* Account has multiple users */
  allowTeams: boolean;
  /* Account has multiple teams. You'll get a 403 when fetching /teams if this is false.
  See Slack thread: https://zapier.slack.com/archives/C024Z8BHJ/p1713208555005719?thread_ts=1712958519.463149&cid=C024Z8BHJ */
  hasMultipleTeams: boolean;
};

export const selectedTeamsAtom = atom<TeamWithPermission[]>([]);

export const isFetchingSelectedTeamsAtom = atom(false);

export const fetchSelectedTeamsAtomFamily = atomFamily(
  ({ currentAccountId, allowTeams, hasMultipleTeams }: Params) => {
    const fetchAtom = atom(null, async (get, set) => {
      if (!allowTeams || !hasMultipleTeams) {
        return;
      }

      set(isFetchingSelectedTeamsAtom, true);

      const permissions = get(permissionsAtom) as Tuple[];

      const uniqueTeamIds = getUniqueSelectedTeamIds(permissions, "canvas");

      if (uniqueTeamIds.length === 0 || !currentAccountId) {
        set(selectedTeamsAtom, []);
        set(isFetchingSelectedTeamsAtom, false);
        return;
      }

      const teamsRes = await fetch(
        `/api/v4/accounts/${currentAccountId}/teams?ids=${uniqueTeamIds.join(
          ",",
        )}&limit=9000`,
      );

      if (!teamsRes.ok) {
        set(isFetchingSelectedTeamsAtom, false);
        throw new Error(`Error fetching selected teams: ${teamsRes.status}`);
      }

      const json = await teamsRes.json();
      const selectedTeamsNoPermission: Team[] = json.results;

      const selectedTeams = getSelectedMembersOrTeamsWithPermission(
        selectedTeamsNoPermission,
        permissions,
        "canvas",
        "team",
      ) as TeamWithPermission[];

      set(selectedTeamsAtom, selectedTeams);
      set(isFetchingSelectedTeamsAtom, false);
    });

    fetchAtom.debugLabel = `fetchSelectedTeamsAtomFamily(allowTeams: ${allowTeams})`;
    return fetchAtom;
  },
  isEqual,
);

function isEqual(a: Params, b: Params) {
  return (
    a.allowTeams === b.allowTeams &&
    a.currentAccountId === b.currentAccountId &&
    a.hasMultipleTeams == b.hasMultipleTeams
  );
}
