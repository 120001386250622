import { atom } from "jotai";
import { canvasAtom } from "./canvasAtom";
import { Meta } from "../types";

class SaveCanvasError extends Error {
  data: any;
  constructor(message: string) {
    super(message);
    this.name = "SaveCanvasError";
    this.data = {};
  }
}

export const canvasAtomWriteOnly = atom(
  null,
  async (get, set, meta: Partial<Meta>) => {
    const canvas = get(canvasAtom);

    /** Templates will have no canvas id. We don't want to make any API calls from templates. */
    if (!canvas.id) {
      return;
    }

    // eslint-disable-next-line no-useless-catch
    try {
      const metaWithValues = Object.fromEntries(
        Object.entries(meta).filter(([_, value]) => value != null),
      );

      const response = await fetch(`/api/canvas/v1/canvases/${canvas.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "trailing-slash-hack": "true",
        },
        body: JSON.stringify(metaWithValues),
      });

      if (!response.ok) {
        const error = new SaveCanvasError("Failed to update Canvas");
        error.data = await response.json().catch(() => ({
          message: "Error parsing save Canvas meta response data",
        }));
        throw error;
      }

      set(canvasAtom, (prev) => ({
        ...prev,
        ...meta,
      }));
    } catch (error) {
      // Necessary for upstream error handling
      throw error;
    }
  },
);

canvasAtomWriteOnly.debugLabel = "canvasAtomWriteOnly";
