import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isZapErrorObject } from "../utils";

import { zapMetadataAsyncAtomFamily } from "./zapAsyncAtomFamily";

export const isZapEnabledAsyncAtomFamily = atomFamily((zapId: string) => {
  const isZapEnabledAsyncAtom = atom<Promise<boolean | null>>(async (get) => {
    const zap = await get(zapMetadataAsyncAtomFamily(zapId));

    if (!zap || isZapErrorObject(zap)) {
      return null; // Indicates the status cannot be determined
    }

    if ("is_enabled" in zap) {
      return zap.is_enabled;
    } else {
      return null; // zsl template zaps don't have is_enabled prop, so the status cannot be determined
    }
  });

  isZapEnabledAsyncAtom.debugLabel = `isZapEnabledAsyncAtomFamily(${zapId})`;
  return isZapEnabledAsyncAtom;
});
