import { atomFamily } from "jotai/utils";
import { atom } from "jotai";
import { areAllNodesDeselectedAtom, selectedEdgesAtom } from "../";

export const isOnlyEdgeSelectedAtomFamily = atomFamily(
  (edgeId: string | undefined) => {
    const isOnlyEdgeSelectedAtom = atom((get) => {
      if (!edgeId) {
        return false;
      }

      const areAllNodesDeselected = get(areAllNodesDeselectedAtom);

      const selectedEdges = get(selectedEdgesAtom);
      return (
        areAllNodesDeselected &&
        selectedEdges.length === 1 &&
        selectedEdges[0].id === edgeId
      );
    });

    isOnlyEdgeSelectedAtom.debugLabel = `isOnlyEdgeSelectedAtomFamily(${edgeId})`;
    return isOnlyEdgeSelectedAtom;
  },
);
