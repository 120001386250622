import { atom } from "jotai";

import { currentAccountIdAtom } from "../account";
import { newAssetNameAtom } from "../apps";
import { getCookie, getNewStepId } from "../utils";
import { newAssetIdentifierAtom } from "../project";
import { Zap } from "../types";

export const isCreatingZewZapAtom = atom<boolean>(false);
isCreatingZewZapAtom.debugLabel = "isCreatingZewZapAtom";

export const createNewZapAtom = atom(
  null,
  async (
    get,
    set,
    { nodeId, updateNodeAndSave, createNotification, emitEventOnZapCreation },
  ) => {
    const currentAccountId = get(currentAccountIdAtom);
    const newZapName = get(newAssetNameAtom);

    if (currentAccountId) {
      set(isCreatingZewZapAtom, true);
      try {
        const res = await fetch(
          `/api/gulliver/storage/v1/zaps?account_id=${currentAccountId}`,
          {
            method: "POST",
            referrerPolicy: "same-origin",
            headers: {
              "Content-Type": "application/json",
              "x-csrftoken": getCookie("csrftoken") || "",
            },
            body: JSON.stringify({
              folder_id: 0,
              description: "",
              kind: "workflow",
              title: newZapName,
              zdl: {
                action: "series_skip_errors",
                app: "EngineAPI",
                id: getNewStepId(),
                steps: [
                  {
                    action: "",
                    app: "",
                    id: getNewStepId(),
                    type: "read",
                  },
                  {
                    action: "",
                    app: "",
                    id: getNewStepId(),
                    type: "write",
                  },
                ],
                type: "run",
                zdl_version: "0.4",
              },
            }),
          },
        );
        const zap = await res.json();
        updateNodeAndSave({
          type: "zap",
          id: nodeId,
          data: {
            zapId: zap.id,
            isExpanded: true,
          },
        });

        set(newAssetIdentifierAtom, `zap:${zap.id}`);
        emitEventOnZapCreation(zap.id);
        return zap as Zap;
      } catch {
        createNotification({
          type: "error",
          message: "There was an issue creating a new Zap. Please try again.",
        });
      } finally {
        set(isCreatingZewZapAtom, false);
      }

      return null;
    }
  },
);

createNewZapAtom.debugLabel = "createNewZapAtom";
