import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { servicesAtomReadOnly } from "./servicesAtom";

export const serviceAtomFamily = atomFamily((appId: string | number) => {
  const serviceAtom = atom((get) => {
    const services = get(servicesAtomReadOnly);
    return services.find((service) => service.selected_api === appId);
  });

  serviceAtom.debugLabel = `serviceAtomFamily(${appId})`;
  return serviceAtom;
});
