import { atom } from "jotai";
import { newAssetIdentifierAtom } from "./newAssetIdentifierAtom";
import { ProjectDetails, ProjectItem } from "../types";

export const projectOfAssetAsyncAtom = atom<Promise<ProjectDetails | null>>(
  async (get) => {
    const newAssetIdentifier = get(newAssetIdentifierAtom);
    if (!newAssetIdentifier) return null;

    const [assetType, assetId] = newAssetIdentifier.split(":");

    // This API has no permission check, so it will return any project id for the account
    const response = await fetch(
      `/api/v1/assets?ids=${assetId}&asset_type=${assetType}`,
    );

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error);
    }

    const data: { results: ProjectItem[] } = await response.json();

    if (data.results.length === 0) {
      return null;
    }

    return data.results[0].project;
  },
);

projectOfAssetAsyncAtom.debugLabel = "projectOfAssetAsyncAtom";
