import { atomFamily, loadable } from "jotai/utils";
import { atom } from "jotai";
import { Member } from "../types";

type MembersAtomFamilyProps = {
  currentAccountId: number | undefined;
  search: string | undefined;
  allowTeams: boolean;
};

type FetchResponse = {
  results: Member[];
};

export const membersAtomFamily = atomFamily(
  ({ currentAccountId, search, allowTeams }: MembersAtomFamilyProps) => {
    const debugLabel = `membersAtomFamily({ search: ${search} })`;

    if (!allowTeams) {
      const personalAccountAtom = atom({
        state: "hasData",
        data: [],
      });

      personalAccountAtom.debugLabel = debugLabel;
      return personalAccountAtom;
    }

    const membersAtom = atom(async () => {
      const params = {
        limit: "10",
        offset: "0",
        search,
      };
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, value]) => value !== undefined),
      ) as Record<string, string>;
      const query = new URLSearchParams(filteredParams);

      const res = await fetch(
        `/api/v4/accounts/${currentAccountId}/members?${query}`,
      );

      if (!res.ok) {
        throw new Error("Failed to fetch members");
      }

      const response: FetchResponse = await res.json();

      return response.results || [];
    });

    membersAtom.debugLabel = debugLabel;
    return loadable(membersAtom);
  },
  isEqual,
);

function isEqual(a: MembersAtomFamilyProps, b: MembersAtomFamilyProps) {
  return a.search === b.search;
}
