import { ZapMetadataStep } from "../types";
import { getAllStepsFlat } from "./getAllStepsFlat";

function isPathBranchStep(step: ZapMetadataStep) {
  return (
    step.app === "EngineAPI" &&
    step.action === "series_skip_errors" &&
    step.type === "run"
  );
}

export function getAllVisibleStepsFlat(steps: ZapMetadataStep[]) {
  const allSteps = getAllStepsFlat(steps);
  return allSteps.filter((step) => !isPathBranchStep(step));
}
