import { atom } from "jotai";
import { Node, getConnectedEdges, getNodesBounds } from "reactflow";
import { graphlib, layout } from "dagre";
import { nodesAtom, selectedNodesAtom } from "../nodes";
import { edgesAtom } from "../edges";
import {
  DEFAULT_NODE_HEIGHT,
  DEFAULT_NODE_WIDTH,
  NO_AUTO_LAYOUT_NODE_TYPES,
  NODE_SEP,
  PATH_NODE_HEIGHT,
  PATH_NODE_WIDTH,
  RANK_SEP,
} from "@zapier/canvas-constants";
import { zapStepNodesVisibleAtomFamily } from "../zaps";
import { takeSnapshotAtom } from "./history";
import { adjustYPositionForStraightLine } from "../utils";

/* IMPORTANT! Any changes made here, please make them for autoLayoutAtom as well */
export const autoLayoutSelectedNodesAtom = atom(null, (get, set) => {
  // Don't auto layout default nodes in a group
  const selectedNodes = get(selectedNodesAtom).filter(
    (node) => !NO_AUTO_LAYOUT_NODE_TYPES.includes(node.type!) && !node.parentId,
  );

  if (selectedNodes.length === 0) {
    return { isSuccess: false, nodes: [] };
  }

  set(takeSnapshotAtom);

  const firstNodeInitialPos = selectedNodes[0].position;

  const nodes = get(nodesAtom);
  /** IMPORTANT! Need to filter out zapStepEdge or else dagre graph will be off */
  const edges = get(edgesAtom).filter((edge) => edge.type !== "zapStepEdge");

  const dagreGraph = new graphlib.Graph()
    .setGraph({
      rankdir: "LR",
      ranksep: RANK_SEP,
      nodesep: NODE_SEP,
    })
    .setDefaultEdgeLabel(() => ({}));

  function calcNodeHeight(node: Node) {
    if (node.type === "zap") {
      const visibleZapSteps = get(zapStepNodesVisibleAtomFamily(node.id));
      const bounds = getNodesBounds([node, ...visibleZapSteps]);
      return bounds.height;
    }

    return (
      node.height ??
      (node.type === "path" ? PATH_NODE_HEIGHT : DEFAULT_NODE_HEIGHT)
    );
  }

  // Add only selected nodes and their edges to the graph
  selectedNodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width:
        node.width ??
        (node.type === "path" ? PATH_NODE_WIDTH : DEFAULT_NODE_WIDTH),
      height: calcNodeHeight(node),
    });
  });

  getConnectedEdges(selectedNodes, edges).forEach((edge) => {
    if (dagreGraph.hasNode(edge.source) && dagreGraph.hasNode(edge.target)) {
      dagreGraph.setEdge(edge.source, edge.target);
    }
  });

  layout(dagreGraph);

  // Update node positions based on the layout
  const updatedNodes = nodes.map((node) => {
    const layoutedNode = dagreGraph.node(node.id);

    if (layoutedNode) {
      return {
        ...node,
        position: {
          x: layoutedNode.x,
          y: adjustYPositionForStraightLine({
            node,
            nodes,
            edges,
            dagreGraph,
            layoutedNode,
          }),
        },
      };
    }
    return node;
  });

  // Find the new position of the first node after layout
  const firstNodeNewPos = updatedNodes.find(
    (node) => node.id === selectedNodes[0].id,
  )!.position;

  // Calculate the offset needed to keep the first node in its original position
  const offsetX = firstNodeInitialPos.x - firstNodeNewPos.x;
  const offsetY = firstNodeInitialPos.y - firstNodeNewPos.y;

  // Apply the offset only to selected nodes
  const adjustedNodes = updatedNodes.map((node) => {
    // Check if the current node is one of the selected nodes
    const isSelectedNode = selectedNodes.find(
      (selectedNode) => selectedNode.id === node.id,
    );

    if (isSelectedNode) {
      return {
        ...node,
        position: {
          x: node.position.x + offsetX,
          y: node.position.y + offsetY,
        },
      };
    } else {
      return node;
    }
  });

  set(nodesAtom, adjustedNodes);

  return { nodes: adjustedNodes, isSuccess: true };
});

autoLayoutSelectedNodesAtom.debugLabel = "autoLayoutSelectedNodesAtom";
