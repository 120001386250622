import { atomFamily, loadable } from "jotai/utils";
import { atom } from "jotai";
import { Team } from "@zapier/permissions";

type TeamsAtomFamilyProps = {
  currentAccountId: number | undefined;
  search: string | undefined;
  allowTeams: boolean;
  /* Account has multiple teams. You'll get a 403 when fetching /teams if this is false.
  See Slack thread: https://zapier.slack.com/archives/C024Z8BHJ/p1713208555005719?thread_ts=1712958519.463149&cid=C024Z8BHJ */
  hasMultipleTeams: boolean;
};

type FetchResponse = {
  results: Team[];
};

export const teamsAtomFamily = atomFamily(
  ({
    currentAccountId,
    search,
    allowTeams,
    hasMultipleTeams,
  }: TeamsAtomFamilyProps) => {
    const debugLabel = `teamsAtomFamily({ search: ${search} })`;

    if (!allowTeams || !hasMultipleTeams) {
      const personalAccountAtom = atom({
        state: "hasData",
        data: [],
      });

      personalAccountAtom.debugLabel = debugLabel;
      return personalAccountAtom;
    }

    const teamsAtom = atom(async () => {
      const params = {
        limit: "10",
        offset: "0",
        search,
      };
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, value]) => value !== undefined),
      ) as Record<string, string>;
      const query = new URLSearchParams(filteredParams);

      const res = await fetch(
        `/api/v4/accounts/${currentAccountId}/teams?${query}`,
      );

      if (!res.ok) {
        throw new Error("Failed to fetch teams");
      }

      const response: FetchResponse = await res.json();

      return response.results || [];
    });

    teamsAtom.debugLabel = debugLabel;
    return loadable(teamsAtom);
  },
  isEqual,
);

function isEqual(a: TeamsAtomFamilyProps, b: TeamsAtomFamilyProps) {
  return a.search === b.search;
}
