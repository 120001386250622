import { atom } from "jotai";

export const generateTemplateErrorAtom = atom(null);
generateTemplateErrorAtom.debugLabel = "generateTemplateErrorAtom";

export const generateTemplateAtom = atom(
  null,
  async (get, set, assetIds: object) => {
    try {
      const response = await fetch("/api/canvasui/interfaces/zsl-generate/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(assetIds),
      });
      const data = await response.json();

      if (!response.ok) {
        let errorMessage = "An error occurred";
        if (data.detail) {
          errorMessage = data.detail;
        } else if (data.errors) {
          errorMessage = data.errors[0]?.detail || data.errors;
        }
        throw new Error(errorMessage);
      } else if (data.errors && data.errors.length > 0) {
        const errorMessages: string[] = [];
        data.errors.forEach((error: any) => {
          errorMessages.push(error.message);
        });
        throw new Error(errorMessages.join("\n"));
      } else {
        return data;
      }
    } catch (error: any) {
      set(generateTemplateErrorAtom, error.message);
    }
  },
);
generateTemplateAtom.debugLabel = "generateTemplateAtom";
