import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { Page, ZSLInterface } from "../types";
import { templateAtom } from "../templates";
import { isTemplateInterfaceProjectId } from "../utils";
import { interfaceProjectAsyncAtomFamily } from "./interfaceProjectAsyncAtomFamily";

export const interfaceProjectPagesAsyncAtomFamily = atomFamily(
  (interfaceProjectId: string | undefined) => {
    const interfaceProjectPagesAtom = atom(async (get) => {
      if (!interfaceProjectId) {
        return null;
      }

      const isTemplateInterface =
        isTemplateInterfaceProjectId(interfaceProjectId);
      const zslTemplate = get(templateAtom);

      if (zslTemplate && isTemplateInterface) {
        const templateInterface = (await get(
          interfaceProjectAsyncAtomFamily(interfaceProjectId),
        )) as ZSLInterface;

        if (templateInterface) {
          return templateInterface.pages;
        }
      }

      // Because we don't want to fetch with template asset id and get a 404 Not Found error
      if (isTemplateInterface) {
        return null;
      }

      try {
        const res = await fetch(
          `/api/canvasui/interfaces/${interfaceProjectId}/pages`,
        );

        if (res.ok) {
          const {
            result: { data },
          } = await res.json();

          return data as Page[];
        } else {
          console.error("Failed to fetch interface pages:", res.statusText);
          return null;
        }
      } catch (error) {
        console.error("Error fetching interface pages:", error);
        return null;
      }
    });

    interfaceProjectPagesAtom.debugLabel = `interfaceProjectPagesAsyncAtomFamily(${interfaceProjectId})`;
    return interfaceProjectPagesAtom;
  },
);
