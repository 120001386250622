import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { currentAccountIdAtom } from "../account";
import { isTemplateModeAtom } from "../templates";
import { isTemplateZapId } from "../utils";

export type UsageCounts = {
  error: number;
  filtered: number;
  halted: number;
  held: number;
  pending: number;
  scheduled: number;
  skipped: number;
  success: number;
  throttled: number;
  waiting: number;
};

const defaultUsage: UsageCounts = {
  error: 0,
  filtered: 0,
  halted: 0,
  held: 0,
  pending: 0,
  scheduled: 0,
  skipped: 0,
  success: 0,
  throttled: 0,
  waiting: 0,
};

type UsageEntry = {
  counts: UsageCounts;
  date: string;
};

export const zapUsageAsyncAtomFamily = atomFamily((zapId: string) => {
  const zapUsageAtom = atom(async (get) => {
    const isTemplate = get(isTemplateModeAtom);
    if (isTemplate || isTemplateZapId(zapId)) {
      return defaultUsage;
    }

    const currentAccountId = get(currentAccountIdAtom);
    const res = await fetch(
      `/api/zap-history/v2/runs/usage?account_id=${currentAccountId}&zap_ids=${zapId}&status=success`,
    );
    if (!res.ok) {
      return defaultUsage;
    }
    const json = (await res.json()) as { usage: UsageEntry[] };

    const aggregatedCounts = json.usage.reduce(
      (acc, usageEntry) => {
        const { counts } = usageEntry;
        for (const status in counts) {
          if (Object.prototype.hasOwnProperty.call(counts, status)) {
            if (!Object.prototype.hasOwnProperty.call(acc, status)) {
              acc[status as keyof UsageCounts] = 0;
            }
            acc[status as keyof UsageCounts] +=
              counts[status as keyof UsageCounts];
          }
        }
        return acc;
      },
      { ...defaultUsage } as UsageCounts,
    );

    return { ...aggregatedCounts, zapId } as UsageCounts;
  });

  zapUsageAtom.debugLabel = `zapUsageAsyncAtomFamily(${zapId})`;

  return zapUsageAtom;
});
