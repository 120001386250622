import { atom } from "jotai";
import { currentAccountIdAtom } from "../account";
import { Member } from "../types";

const initiatedFetchMembersAtom = atom<Record<string, boolean>>({});
initiatedFetchMembersAtom.debugLabel = "initiatedFetchMembersAtom";

/** Encapsulated atom that we only want fetchAndSetMembersAtom to modify */
const membersAtom = atom<Record<string, Member>>({});
membersAtom.debugLabel = "membersAtom";

export const membersAtomReadOnly = atom((get) => get(membersAtom));
membersAtomReadOnly.debugLabel = "membersAtomReadOnly";

export const fetchAndSetMembersAtom = atom(
  null,
  async (get, set, uniqueUserIds: string[]) => {
    const currentMembers = get(membersAtomReadOnly);
    const initiatedFetchMembers = get(initiatedFetchMembersAtom);
    const membersToFetch = uniqueUserIds.filter(
      (id) => !currentMembers[id] && !initiatedFetchMembers[id],
    );

    if (membersToFetch.length === 0) {
      return;
    }

    const newInitiatedFetchMembers = { ...initiatedFetchMembers };
    membersToFetch.forEach((id) => {
      newInitiatedFetchMembers[id] = true;
    });
    set(initiatedFetchMembersAtom, newInitiatedFetchMembers);

    const currentAccountId = get(currentAccountIdAtom);

    const fetchMembersAsync = async () => {
      const res = await fetch(
        `/api/v4/accounts/${currentAccountId}/members?ids=${membersToFetch.join(
          ",",
        )}`,
      );
      const json = await res.json();
      const fetchedMembers = (json.results as Member[]).reduce(
        (acc, obj) => {
          acc[obj.id] = obj;
          return acc;
        },
        {} as Record<string, Member>,
      );

      set(membersAtom, (existingMembers) => ({
        ...existingMembers,
        ...fetchedMembers,
      }));
    };

    await fetchMembersAsync();
  },
);

fetchAndSetMembersAtom.debugLabel = "fetchAndSetMembersAtom";
