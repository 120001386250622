import { XYPosition } from "reactflow";
import {
  DEFAULT_NODE_HEIGHT,
  HANDLE_DIST_FROM_TOP,
  PATH_NODE_HEIGHT,
  PATH_NODE_Y_ADJUSTMENT,
  ZAP_STEP_NODE_HANDLE_DIST_FROM_TOP,
} from "@zapier/canvas-constants";
import { NodeType } from "../types";

type Params = {
  nodePosition: XYPosition;
  isPathNode: boolean;
  nodeType: NodeType;
};

/** When we connect steps to Zap steps, we want the Y position to be centered so the
 * edge is straight instead of a zigzag */
export function getCenteredYPosition({
  nodePosition,
  nodeType,
  isPathNode,
}: Params) {
  const newNodeHeight = isPathNode ? PATH_NODE_HEIGHT : DEFAULT_NODE_HEIGHT;

  const isSourceOrTargetZapStep = nodeType === "zapStep";

  function getOffsetForStraightLine() {
    if (isPathNode) {
      if (isSourceOrTargetZapStep) {
        return 0;
      }

      return PATH_NODE_Y_ADJUSTMENT;
    }

    if (isSourceOrTargetZapStep) {
      return -1;
    }

    return 0;
  }

  const handleDistFromTop =
    nodeType === "zapStep"
      ? ZAP_STEP_NODE_HANDLE_DIST_FROM_TOP
      : HANDLE_DIST_FROM_TOP;

  // Calculate handle position of the node to snap to
  const sourceNodeHandleY =
    nodePosition.y + handleDistFromTop + getOffsetForStraightLine();
  return sourceNodeHandleY - newNodeHeight / 2;
}
