import { Edge, Node, Position } from "reactflow";
import { atom } from "jotai";
import { uuid } from "../utils";
import { nodesAtom } from "../nodes";
import { edgesAtom } from "../edges";
import { templateAtom } from "../templates";
import { saveCanvasAsyncSetterAtom } from "../persistence";

const defaultNode = {
  id: uuid(),
  type: "default",
  data: {
    label: "",
    services: [],
  },
  position: { x: 0, y: 0 },
  sourcePosition: Position.Right,
};

function getDefaultState() {
  const nodes: Node[] = [defaultNode];

  const edges: Edge[] = [];

  return { nodes, edges };
}

type Params = (node: Node) => void;

export const clearCanvasAtom = atom(
  null,
  async (_get, set, centerAndZoomOnNode: Params) => {
    const { nodes, edges } = getDefaultState();
    set(nodesAtom, nodes);
    set(edgesAtom, edges);
    set(templateAtom, undefined); // Remove template banner

    centerAndZoomOnNode(nodes[0]);

    await set(saveCanvasAsyncSetterAtom, { nodes, edges });
  },
);

clearCanvasAtom.debugLabel = "clearCanvasAtom";
