import { atom } from "jotai";

import { templateAtom } from "./templateAtom";

export const isTemplateModeAtom = atom<boolean>((get) => {
  const template = get(templateAtom);
  return Boolean(template);
});

isTemplateModeAtom.debugLabel = "isTemplateModeAtom";
