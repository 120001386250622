import { atom } from "jotai";
import { Node } from "reactflow";
import { closeContextMenusAtom } from "../diagramming";
import { updateNodeAtom } from "./updateNodeAtom";
import { addNodeOnClickAtom } from "./addNodeOnClickAtom";
import { GetNodeDataByTypeFnParams, NodesAndEdges } from "../types";

type Params = {
  event: React.MouseEvent;
  node: Node;
  ref: React.MutableRefObject<HTMLDivElement | null>;
  saveNodesAndEdges: ({ nodes, edges }: NodesAndEdges) => void;
  onSaveNodes: (nodes: Node[]) => void;
  emitEventOnAddStep: (interactionType: string, rawText: string) => void;
  emitEventOnAddPath: (interactionType: string, rawText: string) => void;
  screenToFlowPosition: (point: { x: number; y: number }) => {
    x: number;
    y: number;
  };
  getIntersectingNodes: (rect: {
    x: number;
    y: number;
    width: number;
    height: number;
  }) => Node[];
  getNodeDataByType: (params: GetNodeDataByTypeFnParams) => Node | null;
};

export const onNodeClickAtom = atom(
  null,
  (
    _get,
    set,
    {
      event,
      node,
      ref,
      saveNodesAndEdges,
      onSaveNodes,
      emitEventOnAddStep,
      emitEventOnAddPath,
      screenToFlowPosition,
      getIntersectingNodes,
      getNodeDataByType,
    }: Params,
  ) => {
    // Closes the context menus when user don't need to use them or else they're forced to delete the node
    set(closeContextMenusAtom);

    // When the node's handle is clicked, it propagates an event up to the parent
    // node element. By the time this function is called, `node.selected === true` already
    // so it's too late to stop the propagation. So, let's just set it back to false
    // when the handle is clicked to prevent the node's selected glow and menu from showing.
    if (
      (event.target as HTMLElement).classList.contains("react-flow__handle")
    ) {
      set(updateNodeAtom, {
        id: node.id,
        selected: false,
      });
    }

    // We do this since onPaneClick event is blocked when you click on a group node
    if (node.type === "group") {
      set(addNodeOnClickAtom, {
        event,
        parentId: node.id,
        ref,
        saveNodesAndEdges,
        onSaveNodes,
        emitEventOnAddStep,
        emitEventOnAddPath,
        screenToFlowPosition,
        getIntersectingNodes,
        getNodeDataByType,
      });
    }
  },
);

onNodeClickAtom.debugLabel = "onNodeClickAtom";
