import { atom } from "jotai";
import { chatbotsAppAtom, interfacesAppAtom, tablesAppAtom } from "./";

export type AppImages = {
  url_128x128: string;
  url_64x64: string;
  url_32x32: string;
  url_16x16: string;
};

export type Implementation = {
  images: AppImages;
  name: string;
  selected_api: string;
  app_id: number | string;
};

async function getImplementations(
  selectedApps: string[],
): Promise<Implementation[]> {
  const uniqueNonEmptySelectedApps = [...new Set(selectedApps)].filter(Boolean);

  if (uniqueNonEmptySelectedApps.length === 0) {
    return [];
  }

  const params = new URLSearchParams({
    global: "true",
    limit: uniqueNonEmptySelectedApps.length.toString(),
    selected_apis: uniqueNonEmptySelectedApps.join(","),
  });

  const res = await fetch(`/api/v4/implementations/?${params.toString()}`);

  if (!res.ok) {
    throw new Error("Failed to fetch implementations data");
  }

  const response = await res.json();
  return response.results as Implementation[];
}

/** recommendationsApps and selectedAppsAtom are 2 different states since we don't want the rec apps to show up on the Summary Panel.
 * Summary Panel uses selectedAppsAtom to render all the apps used for the whole canvas.
 * */
const selectedAppsAtom = atom<string[]>([]);

selectedAppsAtom.debugLabel = "selectedAppsAtom";

const recommendationAppsAtom = atom<string[]>([]);

recommendationAppsAtom.debugLabel = "recommendationAppsAtom";

/** Encapsulated atom that we only want servicesAtomWriteOnly to modify */
const servicesAtom = atom<Implementation[]>([]);

servicesAtom.debugLabel = "servicesAtom";

const servicesAtomReadOnly = atom((get) => get(servicesAtom));

servicesAtomReadOnly.debugLabel = "servicesAtomReadOnly";

const servicesAtomWriteOnly = atom(null, async (get, set) => {
  const selectedApps = get(selectedAppsAtom);
  const recommendationApps = get(recommendationAppsAtom);
  const existingServices = get(servicesAtom);

  const tablesApp = get(tablesAppAtom);
  const interfacesApp = get(interfacesAppAtom);
  const chatbotsApp = get(chatbotsAppAtom);

  // Adding built-in apps upfront so when users select one of these apps, ServiceTypeahead will load the app icon faster
  const builtInApps = [
    tablesApp?.current_implementation_id,
    interfacesApp?.current_implementation_id,
    chatbotsApp?.current_implementation_id,
  ].filter(Boolean) as string[];

  // Filter out selectedApps that already have a corresponding service
  const appsToFetch = [
    ...builtInApps,
    ...selectedApps,
    ...recommendationApps,
  ].filter(
    (app) => !existingServices.some((service) => service.selected_api === app),
  );

  if (appsToFetch.length > 0) {
    const newServices = await getImplementations(appsToFetch);

    set(servicesAtom, (prevServices) => {
      // Create a map to keep track of existing services by their selected_api
      const servicesMap = new Map(
        prevServices.map((service) => [service.selected_api, service]),
      );

      // Only add the new services if they don't already exist in the map
      for (const newService of newServices) {
        if (!servicesMap.has(newService.selected_api)) {
          servicesMap.set(newService.selected_api, newService);
        }
      }

      return Array.from(servicesMap.values());
    });
  }
});

servicesAtomWriteOnly.debugLabel = "servicesAtomWriteOnly";

export {
  selectedAppsAtom,
  servicesAtomReadOnly,
  servicesAtomWriteOnly,
  recommendationAppsAtom,
};
