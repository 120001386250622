import { atom } from "jotai";
import { edgesAtom } from "../edges";
import { nodeAtomFamily } from "./nodeAtomFamily";
import { snapToAdjacentNode, uuid } from "../utils";
import { getOutgoers, Node } from "reactflow";
import { nodesAtom } from "./nodesAtom";
import { NodesAndEdges } from "../types";
import { takeSnapshotAtom } from "../diagramming";
import { MARKER } from "../constants";

type Args = {
  // The ID of the node you want to add a parent to.
  id: string;
  saveNodesAndEdges: ({ nodes, edges }: NodesAndEdges) => void;
  centerAndZoomOnNode: (node: Node) => void;
};

export const addSourceNodeAtom = atom(
  null,
  (get, set, { id, saveNodesAndEdges, centerAndZoomOnNode }: Args) => {
    // we need the target node object for getting its position
    const targetNode = get(nodeAtomFamily(id));
    if (!targetNode) {
      return;
    }

    set(takeSnapshotAtom);

    const nodes = get(nodesAtom);
    const edges = get(edgesAtom);

    let updatedNodes = nodes;
    let updatedEdges = edges;

    // create a unique id for the placeholder node that will be added as a target of the clicked node
    const sourceId = uuid();

    // Not used - simply to make TS happy
    const tempPosition = {
      x: 0,
      y: 0,
    };

    // create a placeholder node that will be added as a target of the clicked node
    const newNode = {
      id: sourceId,
      type: "default",
      selected: true,
      data: {
        label: "",
      },
      position: tempPosition,
    };

    // node will have position after running snapToAdjacentNode
    const sourceNode = snapToAdjacentNode({
      newNode,
      nodes,
      edges,
      isPathNode: false,
      targetNode,
    });

    // we need a connection from the clicked node to the new placeholder
    const sourceToTargetEdge = {
      id: `${sourceId}=>${targetNode.id}`,
      source: sourceId,
      target: targetNode.id,
      markerEnd: MARKER,
    };

    const outgoers = getOutgoers(sourceNode, nodes, edges);

    set(edgesAtom, (edges) => {
      let newEdges = edges;

      // If outgoers exists, user is trying to insert node in between two nodes.
      // We still add the node like normal, but the outgoing edge needs to have a new source
      // If there are multiple children outgoing from this node, don't insert.
      if (outgoers.length === 1) {
        newEdges = newEdges.map((edge) => {
          if (edge.source === sourceNode.id) {
            return {
              ...edge,
              source: targetNode.id,
            };
          }
          return edge;
        });
      }
      newEdges = newEdges.concat([sourceToTargetEdge]);
      updatedEdges = newEdges;
      return newEdges;
    });

    set(nodesAtom, (nodes) => {
      const deselectedNodes = nodes.map((node) => {
        return {
          ...node,
          selected: false,
        };
      });

      /** Source node needs to be first or else autoLayout will fail */
      updatedNodes = [sourceNode, ...deselectedNodes];
      return updatedNodes;
    });

    saveNodesAndEdges({
      nodes: updatedNodes,
      edges: updatedEdges,
    });

    centerAndZoomOnNode(sourceNode);
  },
);

addSourceNodeAtom.debugLabel = "addSourceNodeAtom";
