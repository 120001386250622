import { Node, Edge } from "reactflow";

function transformNodes(nodes: Node[]) {
  return nodes.map((node) => {
    const updatedData = { ...node.data };

    if (updatedData.zapId) {
      updatedData.zapId = `\${{ zaps.${updatedData.zapId} }}`;
    }

    if (updatedData.tableId) {
      updatedData.tableId = `\${{ tables.${updatedData.tableId} }}`;
    }

    if (updatedData.projectId && updatedData.pageId) {
      updatedData.pageId = `\${{ interfaces.${updatedData.projectId}.${updatedData.pageId} }}`;
      updatedData.projectId = `\${{ interfaces.${updatedData.projectId} }}`;
    } else if (updatedData.projectId) {
      updatedData.projectId = `\${{ interfaces.${updatedData.projectId} }}`;
    }

    return {
      ...node,
      data: updatedData,
    };
  });
}

type Params = {
  nodes: Node[];
  edges: Edge[];
};

export function convertAssetIdsToPlaceholderIds({ nodes, edges }: Params) {
  const nodesMinusZapSteps = nodes.filter((node) => node.type !== "zapStep");
  const edgesMinusZapStepsEdges = edges.filter(
    (edge) => edge.type !== "zapStepEdge",
  );

  return {
    nodes: transformNodes(nodesMinusZapSteps),
    edges: edgesMinusZapStepsEdges,
  };
}
