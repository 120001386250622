import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { selectedNodesAtom } from "./selectedNodesAtom";
import { isDraggingNodeAtom } from "../diagramming";

export const isOnlyNodeSelectedAtomFamily = atomFamily((nodeId: string) => {
  const isOnlyNodeSelectedAtom = atom((get) => {
    const isDraggingNode = get(isDraggingNodeAtom);

    if (isDraggingNode) {
      return false;
    }

    const selectedNodes = get(selectedNodesAtom);
    return selectedNodes.length === 1 && selectedNodes[0].id === nodeId;
  });

  isOnlyNodeSelectedAtom.debugLabel = `isOnlyNodeSelectedAtomFamily(${nodeId})`;
  return isOnlyNodeSelectedAtom;
});
