import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

export type AssetMetadata = {
  asset_type: string;
  asset_id: string;
  metadata_name: string;
  metadata_value: {
    unit: "seconds" | "minutes" | "hours" | "days";
    value: string;
  };
};

type Params = {
  assetType: "zap";
  metadataName: "time_saved";
  zapId: string;
};

export const asyncZapAssetMetadataAtomFamily = atomFamily(
  (params: Params) => {
    const { metadataName, assetType, zapId } = params;

    // Base atom to fetch data initially
    const fetchAtom = atom(async () => {
      // This is possible for nodes with type: "zap" but no zapId selected yet.
      if (!zapId) {
        return {
          metadata_name: "time_saved",
          metadata_value: {
            value: "0",
            unit: "minutes",
          },
        } as AssetMetadata;
      }
      const res = await fetch(
        `/api/canvas/v1/asset-metadata/?asset_type=${assetType}&asset_id=${zapId}&metdata_name=${metadataName}`,
        {
          method: "GET",
          headers: {
            "trailing-slash-hack": "true",
            "Content-Type": "application/json",
          },
        },
      );

      if (!res.ok) {
        return {
          metadata_name: "time_saved",
          metadata_value: {
            value: "0",
            unit: "minutes",
          },
        } as AssetMetadata;
      }

      const json = await res.json();
      return json.results[0] as AssetMetadata;
    });

    fetchAtom.debugLabel = `asyncZapAssetMetadataAtomFamily/fetchAtom/${zapId}`;

    // Writable atom to store and manage the data
    const dataAtom = atom(null);

    dataAtom.debugLabel = `asyncZapAssetMetadataAtomFamily/dataAtom/${zapId}`;

    // Derived atom to manage initial fetching and subsequent updates
    const derivedAtom = atom(
      async (get) => {
        const currentData = get(dataAtom);
        if (currentData === null) {
          const fetchedData = await get(fetchAtom);
          return fetchedData;
        }
        return currentData;
      },
      (get, set, update) => {
        // If update is a function, call it with the previous value
        const newValue =
          typeof update === "function" ? update(get(dataAtom)) : update;
        // Set the new value to dataAtom
        set(dataAtom, newValue);
      },
    );

    derivedAtom.debugLabel = `asyncZapAssetMetadataAtomFamily/derivedAtom/${zapId}`;

    return derivedAtom;
  },
  // This is to prevent infinite loops because we pass in an object as the key
  // instead of a string, which can be more easily compared.
  (a, b) =>
    a.zapId === b.zapId &&
    a.metadataName === b.metadataName &&
    a.assetType === b.assetType,
);
