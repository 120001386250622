import { atom } from "jotai";
import { Edge } from "reactflow";

import { edgesAtom } from "./edgesAtom";

export const updateEdgeAtom = atom(
  null,
  (get, set, partialEdge: Partial<Edge>) => {
    const nodeId = partialEdge.id;

    const edges = get(edgesAtom);

    const updatedEdges = edges.map((edge) => {
      if (edge.id === nodeId) {
        // it's important that we create a new object here
        // in order to notify react flow about the change
        edge = {
          ...edge,
          ...partialEdge,
          data: {
            ...edge.data,
            ...partialEdge.data,
          },
        };
      }

      return edge;
    });

    set(edgesAtom, updatedEdges);
    return updatedEdges;
  },
);

updateEdgeAtom.debugLabel = "updateEdgeAtom";
