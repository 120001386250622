import { Edge, getIncomers, getOutgoers, Node } from "reactflow";

function isNotZapNodeOrZapStep(node: Node, parentNodeId: string) {
  return node.type !== "zapStep" && node.id !== parentNodeId;
}

function hasConnections(zapStep: Node, nodes: Node[], edges: Edge[]) {
  const incomers = getIncomers(zapStep, nodes, edges);
  const outgoers = getOutgoers(zapStep, nodes, edges);

  const filteredIncomers = incomers.filter((incomer) =>
    isNotZapNodeOrZapStep(incomer, zapStep.parentId!),
  );

  const filteredOutgoers = outgoers.filter((outgoer) =>
    isNotZapNodeOrZapStep(outgoer, zapStep.parentId!),
  );

  return [...filteredIncomers, ...filteredOutgoers].length > 0;
}

function createNewEdgesForConnectedZapSteps(
  zapStepsWithConnections: Node[],
  parentNodeId: string,
) {
  return zapStepsWithConnections.map((zapStep, i) => {
    const source = i === 0 ? parentNodeId : zapStepsWithConnections[i - 1].id;
    const target = zapStep.id;
    const sourceHandle =
      i === 0 ? "zap-node-bottom-handle" : "zap-step-bottom-handle";
    const targetHandle = "zap-step-top-handle";
    const { stepNumber } = zapStep.data;
    const numOfHiddenSteps =
      i === 0
        ? stepNumber - 1
        : stepNumber - zapStepsWithConnections[i - 1].data.stepNumber - 1;

    const label = numOfHiddenSteps === 0 ? undefined : `+${numOfHiddenSteps}`;

    return {
      id: `${source}=>${target}`,
      type: "zapStepEdge",
      source,
      target,
      sourceHandle,
      targetHandle,
      deletable: false,
      focusable: false,
      animated: true,
      data: {
        label,
      },
    };
  });
}

type Params = {
  zapNodeId: string;
  zapSteps: Node[];
  nodes: Node[];
  edges: Edge[];
};

export function createCollapsedZapStepAndEdges({
  zapNodeId,
  zapSteps,
  nodes,
  edges,
}: Params) {
  const zapStepsWithConnections = zapSteps.filter((zapStep) =>
    hasConnections(zapStep, nodes, edges),
  );

  const zapStepsWithConnectionsId = zapStepsWithConnections.map(
    (zapStep) => zapStep.id,
  );

  const zapStepEdges = createNewEdgesForConnectedZapSteps(
    zapStepsWithConnections,
    zapNodeId,
  );

  const zapStepEdgesId = zapStepEdges.map((edge) => edge.id);

  return {
    zapStepsWithConnections,
    zapStepEdges,
    zapStepsWithConnectionsId,
    zapStepEdgesId,
  };
}
