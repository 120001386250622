import { atom } from "jotai";
import { createNotificationAtom } from "../notifications";

export const handleImageUploadStartCommand = atom(
  null,
  (_get, set, message: string = "Uploading image... hang tight.") => {
    set(createNotificationAtom, {
      type: "info",
      message,
      timeout: 8000,
    });
  },
);

handleImageUploadStartCommand.debugLabel = "handleImageUploadStartCommand";
