import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isZapErrorObject } from "../utils";

import { zapMetadataAsyncAtomFamily } from "./zapAsyncAtomFamily";

export const isZapPublishedAsyncAtomFamily = atomFamily((zapId: string) => {
  const isZapPublishedAsyncAtom = atom<Promise<boolean | null>>(async (get) => {
    const zap = await get(zapMetadataAsyncAtomFamily(zapId));

    if (!zap || isZapErrorObject(zap)) {
      return null; // Indicates the status cannot be determined
    }

    if ("is_published" in zap) {
      return zap.is_published;
    } else {
      return null; // zsl template zaps don't have is_published prop, so published value cannot be determined
    }
  });

  isZapPublishedAsyncAtom.debugLabel = `isZapPublishedAsyncAtomFamily(${zapId})`;
  return isZapPublishedAsyncAtom;
});
