import { ZapMetadataStep } from "../types";
import { getAllStepsFlat } from "./getAllStepsFlat";
import { getAllVisibleStepsFlat } from "./getAllVisibleStepsFlat";

function isPathRulesStep(step: ZapMetadataStep) {
  return step.app === "BranchingAPI" && step.action === "filter";
}

// Function to create a map of child step ID to parent step ID
const createParentMap = (
  steps: ZapMetadataStep[],
  parentMap: Map<string, string>,
  parentId: string = "",
) => {
  steps.forEach((step) => {
    if (parentId) {
      parentMap.set(step.id, parentId);
    }

    if (step.steps) {
      createParentMap(step.steps, parentMap, step.id);
    }
  });
};

// Function to recursively find the ultimate parent of a given step,
// stopping when a step with action "parallel_paths" and app "BranchingAPI" is found
const findPathParent = (
  stepId: string,
  steps: ZapMetadataStep[],
  parentMap: Map<string, string>,
): ZapMetadataStep | null => {
  const parentId = parentMap.get(stepId);
  if (!parentId) {
    return null;
  }

  const parentStep = steps.find((step) => step.id === parentId);

  if (!parentStep) {
    return null;
  }

  // Check if the parent is a "parallel_paths" step in "BranchingAPI"
  if (
    parentStep.action === "parallel_paths" &&
    parentStep.app === "BranchingAPI"
  ) {
    return parentStep;
  }

  // Continue searching for the ultimate parent
  return findPathParent(parentId, steps, parentMap) || parentStep;
};

export const findPathParentForVisibleSteps = (zapSteps: ZapMetadataStep[]) => {
  const allSteps = getAllStepsFlat(zapSteps);
  const visibleSteps = getAllVisibleStepsFlat(zapSteps);

  // Create the parent map from allSteps
  const parentMap = new Map<string, string>();
  createParentMap(allSteps, parentMap);

  // Find ultimate parents for visibleSteps, stopping at specific parent criteria
  return visibleSteps.map((step) => {
    const isPathRules = isPathRulesStep(step);

    return {
      ...step,
      pathParentId: isPathRules
        ? findPathParent(step.id, allSteps, parentMap)?.id
        : null,
    };
  });
};
