import { atom } from "jotai";
import { atomFamily, loadable } from "jotai/utils";

import { templateAtom } from "../templates";
import { Zap, ZapMetadata } from "../types";
import { extractAssetId, isTemplateZapId, isZapErrorObject } from "../utils";
import { currentAccountIdAtom } from "../account";

export type ZapErrorObject = {
  error: string;
};

type ZapAtomReturnType = Zap | ZapErrorObject | null;
type ZapMetadataAtomReturnType = ZapMetadata | ZapErrorObject | null;

async function getZapMetadata(zapId: string) {
  try {
    const res = await fetch(`/api/gulliver/metadata/v1/zaps/${zapId}`);
    if (!res.ok) {
      throw new Error("Zap Metadata not found");
    }
    return await res.json();
  } catch (error: unknown) {
    if (error instanceof Error) {
      return { error: error.message };
    }
    return { error: `Error fetching Zap id ${zapId}` };
  }
}

async function getZap(zapId: string, account_id: number) {
  try {
    const res = await fetch(
      `/api/gulliver/storage/v1/zaps/${zapId}?account_id=${account_id}`,
    );
    if (!res.ok) {
      throw new Error("Zap not found");
    }
    return await res.json();
  } catch (error: unknown) {
    if (error instanceof Error) {
      return { error: error.message };
    }
    return { error: `Error fetching Zap id ${zapId}` };
  }
}

export const zapAsyncAtomFamily = atomFamily((zapId: string | null) => {
  const zapAsyncAtom = atom<Promise<ZapAtomReturnType>>(async (get) => {
    const accountId = get(currentAccountIdAtom);
    if (!zapId || !accountId) {
      return null;
    }

    const isTemplateZap = isTemplateZapId(zapId);
    const zslTemplate = get(templateAtom);

    if (zslTemplate && isTemplateZap) {
      const coreZapId = extractAssetId(zapId); // For templates, zapId might be ${{zaps._ZAP_1}} so we want to extract ZAP_1 from it

      const zapTemplate = zslTemplate?.zaps?.find(
        (zap) => coreZapId === zap.id,
      );

      if (zapTemplate) {
        return {
          ...zapTemplate,
          current_version: {
            zdl: zapTemplate.zdl,
          },
        };
      }
    }

    // Because we don't want to fetch with template asset id and get a 404 Not Found error
    if (isTemplateZap) {
      return null;
    }

    return await getZap(zapId, accountId);
  });

  zapAsyncAtom.debugLabel = `zapAsyncAtomFamily(${zapId})`;
  return zapAsyncAtom;
});

export const zapMetadataAsyncAtomFamily = atomFamily((zapId: string | null) => {
  const zapAsyncAtom = atom<Promise<ZapMetadataAtomReturnType>>(async (get) => {
    if (!zapId) {
      return null;
    }

    const isTemplateZap = isTemplateZapId(zapId);
    const zslTemplate = get(templateAtom);

    if (zslTemplate && isTemplateZap) {
      const coreZapId = extractAssetId(zapId); // For templates, zapId might be ${{zaps._ZAP_1}} so we want to extract ZAP_1 from it

      const zapTemplate = zslTemplate?.zaps?.find(
        (zap) => coreZapId === zap.id,
      );

      if (zapTemplate) {
        return {
          ...zapTemplate,
          zap_id: zapTemplate.id,
          steps: zapTemplate.zdl.steps,
        };
      }
    }

    // Because we don't want to fetch with template asset id and get a 404 Not Found error
    if (isTemplateZap) {
      return null;
    }

    return await getZapMetadata(zapId);
  });

  zapAsyncAtom.debugLabel = `zapMetadataAsyncAtomFamily(${zapId})`;
  return zapAsyncAtom;
});

export const loadableZapMetadataAsyncAtomFamily = atomFamily(
  (zapId: string | null) => {
    const loadableZapAsyncAtom = loadable(zapMetadataAsyncAtomFamily(zapId));
    loadableZapAsyncAtom.debugLabel = `loadableZapMetadataAsyncAtomFamily(${zapId})`;
    return loadableZapAsyncAtom;
  },
);

export const zapNoteAsyncAtomFamily = atomFamily((zapId: string | null) => {
  const zapNoteAtom = atom(async (get) => {
    const zap = await get(zapAsyncAtomFamily(zapId));
    if (isZapErrorObject(zap)) {
      return null;
    }
    return zap?.current_version?.zap_note || zap?.draft?.zap_note || null;
  });

  zapNoteAtom.debugLabel = `zapNoteAsyncAtomFamily(${zapId})`;
  return zapNoteAtom;
});
