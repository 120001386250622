import { atom } from "jotai";
import { Node, Edge } from "reactflow";
import { isEmbedAtom } from "../permissions";
import { isTemplateModeAtom } from "../templates";
import { canvasAtom, isViewerAtom } from "../canvas";
import { processEdgesBeforeSave, processNodesBeforeSave } from "../utils";
import { createNotificationAtom } from "../notifications";

type Params = {
  nodes?: Node[];
  edges?: Edge[];
  name?: string;
  is_public?: boolean;
  description?: string;
};

const GENERIC_ERROR_MSG = "There was an issue saving your canvas.";

export const saveCanvasAsyncSetterAtom = atom(
  null,
  async (get, set, updatedCanvas: Params) => {
    const canvas = get(canvasAtom);
    const isViewer = get(isViewerAtom);
    const isEmbed = get(isEmbedAtom);
    const isTemplate = get(isTemplateModeAtom);

    /** We don't want to make any API calls from templates.
     *
     * Do not use isViewOnlyModeAtom 'cuz we need to save changes to BE when haveGroupNodes = true while getting recommendations.
     *
     * isViewer will be true when is_public is true (so true for public links)
     * */
    if (!canvas.id || isTemplate || isEmbed || isViewer) {
      return;
    }

    const { nodes, edges, ...rest } = updatedCanvas;
    const canvasToSave: Partial<Params> = { ...rest };

    /* Ensures that nodes and edges are only included in the PATCH request when they are defined. If either nodes or edges are undefined, they will be omitted from the request body, so we don't accidentally overwrite the current nodes and edges.*/
    if (nodes) {
      canvasToSave.nodes = processNodesBeforeSave(nodes);
    }

    if (edges) {
      canvasToSave.edges = processEdgesBeforeSave(edges);
    }

    try {
      const res = await fetch(`/api/canvas/v1/canvases/${canvas.id}/`, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(canvasToSave),
      });

      if (!res.ok) {
        const errorMessage =
          res.status === 403 // User lost permission while they were actively on a canvas
            ? "You do not have permission to edit this canvas."
            : GENERIC_ERROR_MSG;

        set(createNotificationAtom, {
          type: "error",
          message: errorMessage,
        });

        if (res.status === 403) {
          setTimeout(
            () =>
              (window.location.href = `/app/login?next=/app/canvas/${canvas.id}`),
            2500,
          );
        }
      }
    } catch (err: unknown) {
      console.error("Error saving canvas", err);

      set(createNotificationAtom, {
        type: "error",
        message: GENERIC_ERROR_MSG,
      });
    }
  },
);

saveCanvasAsyncSetterAtom.debugLabel = "saveCanvasAsyncSetterAtom";
