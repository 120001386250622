import { atom } from "jotai";
import { nodesAtom } from "../nodes/nodesAtom";

export const hasExpandedZapNodesAtom = atom((get) => {
  const nodes = get(nodesAtom);
  return (
    nodes.filter((node) => node.type === "zap" && node.data.isExpanded).length >
    0
  );
});

hasExpandedZapNodesAtom.debugLabel = "hasExpandedZapNodesAtom";
