import { SelectableNodeTypes } from "../diagramming";

const rawTextMapping: { [key: string]: string } = {
  default: "toolbar-default",
  zap: "toolbar-zap",
  path: "toolbar-path",
  text: "toolbar-text",
  comment: "toolbar-comment",
  table: "toolbar-table",
  interface: "toolbar-interface",
  chatbots: "toolbar-chatbots",
  group: "toolbar-group",
};

export function getEventRawText(type: SelectableNodeTypes | "comment") {
  if (type === null) {
    return "";
  }

  return rawTextMapping[type];
}
