import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { Page } from "../types";

interface ErrorJson {
  errors: string[];
}

class FetchPagesError extends Error {
  status?: number;
  json?: ErrorJson;

  constructor(message: string, status?: number, json?: ErrorJson) {
    super(message);
    this.name = "FetchPagesError";
    this.status = status;
    this.json = json;
  }
}

export const interfacesPagesAsyncAtomFamily = atomFamily(
  (projectId: string) => {
    const pagesAtom = atom<Promise<Page[]>>(async () => {
      try {
        const res = await fetch(
          `/api/canvas/v1/pages/?project_id=${projectId}`,
        );

        if (!res.ok) {
          const errorResponse = await res.json().catch(() => ({
            errors: ["Error parsing interfaces pages response data"],
          }));

          throw new FetchPagesError(res.statusText, res.status, errorResponse);
        }

        return await res.json();
      } catch (error) {
        console.error(
          `Failed to fetch pages for interface id: ${projectId}`,
          error,
        );
        return [];
      }
    });

    pagesAtom.debugLabel = `interfacesPagesAsyncAtomFamily(${projectId})`;
    return pagesAtom;
  },
);
