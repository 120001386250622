import { atom } from "jotai";

export const createNewCanvasAtom = atom(null, async (_get, _set, canvas) => {
  try {
    // Send POST request to create new canvas
    const res = await fetch("/api/canvas/v1/canvases/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "trailing-slash-hack": "true",
      },
      body: JSON.stringify(canvas),
    });

    if (!res.ok) {
      throw new Error("Failed to create new canvas");
    }

    const json = await res.json();
    const canvasId = json.id;

    // Redirect to new canvas ID
    window.location.href = `/app/canvas/${canvasId}`;
  } catch (error) {
    console.error("Error creating new canvas:", error);
  }
});

createNewCanvasAtom.debugLabel = "createNewCanvasAtom";
