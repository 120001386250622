import { addEdge, Connection } from "reactflow";
import { atom } from "jotai";

import { edgesAtom } from "./edgesAtom";
import { MARKER } from "../constants";

export const addEdgeAtom = atom(null, (get, set, connection: Connection) => {
  const edges = get(edgesAtom);
  const id = `${connection.source}=>${connection.target}`;

  const edgeExists = edges.some((edge) => edge.id === id);

  if (!edgeExists) {
    const updatedEdges = addEdge(
      {
        ...connection,
        id,
        markerEnd: MARKER,
      },
      edges,
    );
    set(edgesAtom, updatedEdges);
    return updatedEdges;
  }
  return edges;
});

addEdgeAtom.debugLabel = "addEdgeAtom";
