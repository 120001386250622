import { atom } from "jotai";

import { sessionAtom } from "./sessionAtom";
import { Account } from "../types";

// We need a way to read currentAccountId from within jotai.
export const currentAccountIdAtom = atom<number | undefined>(undefined);

export const accountAtom = atom<Account | null>(null);
accountAtom.debugLabel = `accountAtom`;

export const isCollaborationModeAtom = atom((get) => {
  const account = get(accountAtom);
  const session = get(sessionAtom);
  const hasTeams = account?.plan_info?.allow_teams === true;
  const isTest = session?.email?.includes("gen_api.canvas");
  return hasTeams && !isTest;
});
isCollaborationModeAtom.debugLabel = `isCollaborationModeAtom`;
