import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { TableField } from "../types";
import { extractAssetId, isTemplateTableId } from "../utils";
import { templateAtom } from "../templates";

export const tableFieldsAsyncAtomFamily = atomFamily(
  (tableId: string | undefined) => {
    const tableFieldsAtom = atom(async (get) => {
      if (!tableId) {
        return null;
      }

      const isTemplateTable = isTemplateTableId(tableId);
      const zslTemplate = get(templateAtom);

      if (zslTemplate && isTemplateTable) {
        const coreTableId = extractAssetId(tableId);

        const tableTemplate = zslTemplate.tables?.find(
          (table) => coreTableId === table.id,
        );

        if (tableTemplate) {
          return tableTemplate.fields;
        }
      }

      // Because we don't want to fetch with template asset id and get a 404 Not Found error
      if (isTemplateTable) {
        return null;
      }

      try {
        const res = await fetch(`/api/canvasui/tables/${tableId}/fields`);

        if (res.ok) {
          const { data } = await res.json();
          return data as TableField[];
        } else {
          console.error("Failed to fetch table fields:", res.statusText);
          return null;
        }
      } catch (error) {
        console.error("Error fetching table fields:", error);
        return null;
      }
    });

    tableFieldsAtom.debugLabel = `tableFieldsAsyncAtomFamily(${tableId})`;
    return tableFieldsAtom;
  },
);
