import { atom } from "jotai";
import { emitEventOnExecuteTemplateAtom } from "../events";
import { templateSlugAtom } from "./templateSlugAtom";
import { executeTemplateAndSaveAsyncAtom } from "./executeTemplateAndSaveAsyncAtom";

export const handleUseTemplateCommand = atom(null, async (get, set) => {
  const templateSlug = get(templateSlugAtom);

  if (templateSlug) {
    set(emitEventOnExecuteTemplateAtom, templateSlug);
  }

  await set(executeTemplateAndSaveAsyncAtom);
});

handleUseTemplateCommand.debugLabel = "handleUseTemplateCommand";
