import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { interfacePageAsyncAtomFamily } from "./interfacePageAsyncAtomFamily";

type Params = {
  interfaceProjectId: string;
  interfacePageId: string;
  periodInDays?: number; // Defaults to 30 if undefined
};

type FormSubmissionsSuccess = {
  formId: string;
  total: number;
  period: number;
};

type FormSubmissionsError = {
  formId: string;
  error: string;
  status: number;
};

type IndividualFormResponse = FormSubmissionsSuccess | FormSubmissionsError;

export type FormSubmissionsApiResponse = IndividualFormResponse[];

function isFormSubmissionError(
  entry: IndividualFormResponse,
): entry is FormSubmissionsError {
  return (entry as FormSubmissionsError).error !== undefined;
}

function getDateDaysAgoISO(days: number): string {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date.toISOString();
}

export const formsSubmissionsAsyncAtomFamily = atomFamily(
  ({ interfaceProjectId, interfacePageId, periodInDays = 30 }) => {
    const formsSubmissionsAtom = atom(async (get) => {
      try {
        const interfacePage = await get(
          interfacePageAsyncAtomFamily({
            interfaceProjectId,
            interfacePageId,
          }),
        );

        if (!interfacePage) {
          return null;
        }

        const formIds = interfacePage.blocks
          .map((block) => block.config.id)
          .join(",");

        if (!formIds) {
          return null;
        }

        const fromDate = getDateDaysAgoISO(periodInDays);

        const response = await fetch(
          `/api/canvasui/interfaces/${interfaceProjectId}/forms?formIds=${formIds}&from=${fromDate}`,
        );

        if (!response.ok) {
          return null;
        }

        const dataArray = (await response.json()) as FormSubmissionsApiResponse;

        // Filter out entries with errors
        const validDataArray = dataArray.filter(
          (entry) => !isFormSubmissionError(entry),
        ) as FormSubmissionsSuccess[];

        if (validDataArray.length === 0) {
          return null;
        }

        return validDataArray.reduce(
          (acc, curr) => ({
            total: acc.total + curr.total,
            period: acc.period + curr.period,
          }),
          { total: 0, period: 0 },
        );
      } catch (error) {
        console.error("Error fetching form submissions:", error);
        return null;
      }
    });

    formsSubmissionsAtom.debugLabel = `formsSubmissionsAsyncAtomFamily(${interfaceProjectId}-${interfacePageId})`;
    return formsSubmissionsAtom;
  },
  areParamsEqual,
);

function areParamsEqual(a: Params, b: Params) {
  return (
    a.interfaceProjectId === b.interfaceProjectId &&
    a.interfacePageId === b.interfacePageId &&
    a.periodInDays === b.periodInDays
  );
}
