import { atom } from "jotai";

import { areAllNodesDeselectedAtom } from "./areAllNodesDeselectedAtom";
import { nodesAtom } from "./nodesAtom";

export const deselectAllNodesAtom = atom(null, (get, set) => {
  const areAllNodesDeselected = get(areAllNodesDeselectedAtom);
  if (areAllNodesDeselected) return;

  const nodes = get(nodesAtom);
  const updatedNodes = nodes.map((node) =>
    node.selected ? { ...node, selected: false } : node,
  );

  set(nodesAtom, updatedNodes);
});

deselectAllNodesAtom.debugLabel = "deselectAllNodesAtom";
