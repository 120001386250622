import { atom } from "jotai";

import { areAllEdgesDeselectedAtom } from "./areAllEdgesDeselectedAtom";
import { edgesAtom } from "./edgesAtom";

export const deselectAllEdgesAtom = atom(null, (get, set) => {
  const areAllEdgesDeselected = get(areAllEdgesDeselectedAtom);
  if (areAllEdgesDeselected) return;

  const edges = get(edgesAtom);
  const updatedEdges = edges.map((edge) =>
    edge.selected ? { ...edge, selected: false } : edge,
  );

  set(edgesAtom, updatedEdges);
});

deselectAllEdgesAtom.debugLabel = "deselectAllEdgesAtom";
