import { atom } from "jotai";
import { canvasAtom } from "../";

// This will be true when is_public is true (so true for public links)
export const isViewerAtom = atom((get) => {
  const canvas = get(canvasAtom);
  return canvas.access_level === "can_view";
});

isViewerAtom.debugLabel = "isViewerAtom";
