import { Node, Edge } from "reactflow";
import { GAP_BETWEEN_NODES, TEMP } from "@zapier/canvas-constants";
import {
  isTempEdge,
  getAbsoluteNodePosition,
  getCenteredYPosition,
} from "./index";
import { NodeType } from "../types";

type Params = {
  newNode: Node;
  nodes: Node[];
  edges: Edge[];
  isPathNode: boolean;
  sourceNode?: Node;
  targetNode?: Node;
};

/** Snap to incoming / outgoing node */
export function snapToAdjacentNode({
  newNode,
  nodes,
  edges,
  isPathNode,
  sourceNode,
  targetNode,
}: Params) {
  const tempEdge = edges.find((e) => isTempEdge(e));
  if (!tempEdge && !sourceNode && !targetNode) return newNode;

  const incomerNode = sourceNode
    ? sourceNode
    : nodes.find((node) => node.id === tempEdge?.source && node.type !== TEMP);
  const outgoerNode = targetNode
    ? targetNode
    : nodes.find((node) => node.id === tempEdge?.target && node.type !== TEMP);

  if (incomerNode) {
    newNode.position.x =
      getAbsoluteNodePosition(incomerNode, nodes).x + GAP_BETWEEN_NODES;
    newNode.position.y = getCenteredYPosition({
      nodePosition: getAbsoluteNodePosition(incomerNode, nodes),
      nodeType: incomerNode.type as NodeType,
      isPathNode: isPathNode,
    });
  } else if (outgoerNode) {
    const gap = isPathNode ? GAP_BETWEEN_NODES * 2 : GAP_BETWEEN_NODES;
    newNode.position.x = getAbsoluteNodePosition(outgoerNode, nodes).x - gap;
    newNode.position.y = getCenteredYPosition({
      nodePosition: getAbsoluteNodePosition(outgoerNode, nodes),
      nodeType: outgoerNode.type as NodeType,
      isPathNode: isPathNode,
    });
  }

  return newNode;
}
