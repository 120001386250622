import { atom } from "jotai";
import { Node } from "reactflow";

import { nodesAtom } from "./nodesAtom";

export const addNodeAtom = atom(null, (get, set, node: Node) => {
  const nodes = get(nodesAtom);
  const deselectedNodes = nodes.map((node) => ({
    ...node,
    selected: false,
  }));
  const updatedNodes = [...deselectedNodes, node];
  set(nodesAtom, updatedNodes);
  return updatedNodes;
});

addNodeAtom.debugLabel = "addNodeAtom";
