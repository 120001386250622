import { atom } from "jotai";
import { accountAtom } from "./";

type Entitlements = {
  limits: {
    is_teams_enabled: boolean;
  };
};

export const entitlementsAsyncAtom = atom<Promise<Entitlements> | null>(
  async (get) => {
    const account = get(accountAtom);
    if (!account) {
      return null;
    }

    try {
      const response = await fetch(
        `/api/v4/accounts/${account.id}/entitlements/`,
      );

      if (!response.ok) {
        throw new Error("Failed to fetch entitlements");
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching entitlements:", error);
      return null;
    }
  },
);

entitlementsAsyncAtom.debugLabel = "entitlementsAsyncAtom";
