import { atom } from "jotai";

import { asyncZapAssetMetadataAtomFamily } from "./zapAssetMetadataAtomFamily";

export const updateAssetMetadataAtom = atom(
  null,
  async (get, set, { zapId, metadataName, assetType, metadataValue }) => {
    await fetch(`/api/canvas/v1/asset-metadata/`, {
      method: "POST",
      body: JSON.stringify({
        asset_type: assetType,
        asset_id: zapId,
        metadata_name: metadataName,
        metadata_value: metadataValue,
      }),
      headers: {
        "trailing-slash-hack": "true",
        "Content-Type": "application/json",
      },
    });

    set(
      asyncZapAssetMetadataAtomFamily({
        zapId,
        assetType: "zap",
        metadataName: "time_saved",
      }),
      {
        metadata_name: metadataName,
        metadata_value: metadataValue,
      },
    );
  },
);
