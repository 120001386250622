import { Edge } from "reactflow";
import { atom } from "jotai";

export const edgesAtom = atom<Edge[]>([]);

edgesAtom.debugLabel = "edgesAtom";

export const editingEdgeIdAtom = atom<null | string>(null);

editingEdgeIdAtom.debugLabel = "editingEdgeIdAtom";
