import { atom } from "jotai";
import { Edge, Node } from "reactflow";
import { nodesAtom } from "../nodes/nodesAtom";
import { edgesAtom } from "../edges/edgesAtom";
import { canvasAtom } from "../canvas/canvasAtom";
import { TEMP } from "@zapier/canvas-constants";

type HistoryItem = {
  nodes: Node[];
  edges: Edge[];
};

const maxHistorySize = 100;

const pastAtom = atom<HistoryItem[]>([]);
pastAtom.debugLabel = "pastAtom";

const futureAtom = atom<HistoryItem[]>([]);
futureAtom.debugLabel = "futureAtom";

export const takeSnapshotAtom = atom(null, (get, set) => {
  const nodes = get(nodesAtom).filter((node) => node.type !== TEMP);
  const edges = get(edgesAtom).filter((edge) => edge.className !== TEMP);
  set(pastAtom, (past) => [
    ...past.slice(past.length - maxHistorySize + 1, past.length),
    { nodes, edges },
  ]);

  set(futureAtom, []);
});

takeSnapshotAtom.debugLabel = "takeSnapshotAtom";

export const undoAtom = atom(null, (get, set) => {
  // get the last state that we want to go back to
  const past = get(pastAtom);
  const pastState = past[past.length - 1];
  const nodes = get(nodesAtom);
  const edges = get(edgesAtom);
  const canvas = get(canvasAtom);
  if (pastState) {
    // first we remove the state from the history
    set(pastAtom, (past) => past.slice(0, past.length - 1));
    // we store the current graph for the redo operation
    set(futureAtom, (future) => [...future, { nodes, edges }]);
    // now we can set the graph to the past state
    set(nodesAtom, pastState.nodes);
    set(edgesAtom, pastState.edges);

    return { nodes: pastState.nodes, edges: pastState.edges, id: canvas.id };
  }

  return null;
});

undoAtom.debugLabel = "undoAtom";

export const redoAtom = atom(null, (get, set) => {
  const future = get(futureAtom);
  const futureState = future[future.length - 1];
  const nodes = get(nodesAtom);
  const edges = get(edgesAtom);
  const canvas = get(canvasAtom);

  if (futureState) {
    set(futureAtom, (future) => future.slice(0, future.length - 1));
    set(pastAtom, (past) => [...past, { nodes, edges }]);
    set(nodesAtom, futureState.nodes);
    set(edgesAtom, futureState.edges);
    return {
      nodes: futureState.nodes,
      edges: futureState.edges,
      id: canvas.id,
    };
  }

  return null;
});

redoAtom.debugLabel = "redoAtom";
