import { atom } from "jotai";
import { Node, getNodesBounds } from "reactflow";

import { edgesAtom } from "../edges";
import { graphlib, layout } from "dagre";
import { nodesAtom } from "../nodes";
import {
  DEFAULT_NODE_HEIGHT,
  DEFAULT_NODE_WIDTH,
  NO_AUTO_LAYOUT_NODE_TYPES,
  NODE_SEP,
  PATH_NODE_HEIGHT,
  PATH_NODE_WIDTH,
  RANK_SEP,
} from "@zapier/canvas-constants";
import { takeSnapshotAtom } from "./history";
import { zapStepNodesVisibleAtomFamily } from "../zaps";
import { adjustYPositionForStraightLine } from "../utils";

/* IMPORTANT! Any changes made here, please make them for autoLayoutSelectedNodesAtom as well */
export const autoLayoutAtom = atom(null, (get, set) => {
  set(takeSnapshotAtom);

  const dagreGraph = new graphlib.Graph();

  dagreGraph.setGraph({
    rankdir: "LR",
    ranksep: RANK_SEP,
    nodesep: NODE_SEP,
  });

  dagreGraph.setDefaultEdgeLabel(() => ({}));

  /** IMPORTANT! Need to filter out zapStepEdge or else dagre graph will be off */
  const edges = get(edgesAtom).filter((edge) => edge.type !== "zapStepEdge");
  const nodes = get(nodesAtom);

  // Don't auto layout default nodes in a group
  const nodesToAutoLayout = nodes.filter(
    (node) => !NO_AUTO_LAYOUT_NODE_TYPES.includes(node.type!) && !node.parentId,
  );

  function calcNodeHeight(node: Node) {
    if (node.type === "zap") {
      const visibleZapSteps = get(zapStepNodesVisibleAtomFamily(node.id));
      const bounds = getNodesBounds([node, ...visibleZapSteps]);
      return bounds.height;
    }

    return (
      node.height ??
      (node.type === "path" ? PATH_NODE_HEIGHT : DEFAULT_NODE_HEIGHT)
    );
  }

  nodesToAutoLayout.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width:
        node.width ??
        (node.type === "path" ? PATH_NODE_WIDTH : DEFAULT_NODE_WIDTH),
      height: calcNodeHeight(node),
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  layout(dagreGraph);

  const updatedNodes = nodes.map((node) => {
    const layoutedNode = dagreGraph.node(node.id);

    if (layoutedNode) {
      return {
        ...node,
        position: {
          x: layoutedNode.x,
          y: adjustYPositionForStraightLine({
            node,
            nodes,
            edges,
            dagreGraph,
            layoutedNode,
          }),
        },
      };
    }
    return node;
  });

  set(nodesAtom, updatedNodes);

  return { nodes: updatedNodes, isSuccess: true };
});

autoLayoutAtom.debugLabel = "autoLayoutAtom";
