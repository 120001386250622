import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { templateAtom } from "../templates";
import { Chatbot } from "../types";
import { extractAssetId, isTemplateChatbotId } from "../utils";

export const chatbotAsyncAtomFamily = atomFamily(
  (chatbotId: string | undefined) => {
    const chatbotAtom = atom(async (get) => {
      if (!chatbotId) {
        return null;
      }

      const isTemplateChatbot = isTemplateChatbotId(chatbotId);
      const zslTemplate = get(templateAtom);

      if (zslTemplate && isTemplateChatbot) {
        const coreChatbotId = extractAssetId(chatbotId);

        const chatbotTemplate = zslTemplate.chatbots?.find(
          (chatbot) => coreChatbotId === chatbot.id,
        );

        if (chatbotTemplate) {
          return {
            id: chatbotTemplate.id,
            name: chatbotTemplate.config.chatbotName,
          };
        }
      }

      // Because we don't want to fetch with template asset id and get a 400 Bad Request error
      if (isTemplateChatbot) {
        return null;
      }

      try {
        const res = await fetch(`/api/chatbots/v0/chatbots/${chatbotId}/`, {
          headers: {
            "trailing-slash-hack": "true",
          },
        });

        if (res.ok) {
          const json = await res.json();
          return json.data as Chatbot;
        } else {
          console.error("Failed to fetch chatbot:", res.statusText);
          return null;
        }
      } catch (error) {
        console.error("Error fetching chatbot:", error);
        return null;
      }
    });

    chatbotAtom.debugLabel = `chatbotAsyncAtomFamily(${chatbotId})`;
    return chatbotAtom;
  },
);
