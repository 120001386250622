import { atom } from "jotai";

type MenuProps = {
  id: string;
  top: number | undefined;
  left: number | undefined;
};

export const nodeContextMenuAtom = atom<MenuProps | null>(null);
nodeContextMenuAtom.debugLabel = "nodeContextMenuAtom";

export const closeContextMenusAtom = atom(null, (_get, set) => {
  set(nodeContextMenuAtom, null);
});
closeContextMenusAtom.debugLabel = "closeContextMenusAtom";
