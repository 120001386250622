import { atom } from "jotai";

import { AssetType, ProjectDetails, ZapMetadata } from "../types";
import {
  GroupedAssets,
  projectAssetsAsyncAtom,
  ProjectAssetsError,
} from "./projectAssetsAsyncAtom";
import { ZapErrorObject, zapMetadataAsyncAtomFamily } from "../zaps";

export type HydratedGroupedAssets = Record<AssetType, ZapMetadata[]>;

interface ProjectAssetsSuccess {
  assets: HydratedGroupedAssets | null;
  project?: ProjectDetails;
}

type Response = ProjectAssetsSuccess | ProjectAssetsError;

export const projectAssetsHydratedAsyncAtom = atom<Promise<Response>>(
  async (get) => {
    const response = await get(projectAssetsAsyncAtom);

    if ("error" in response || response.assets === null) {
      return response as Response;
    }

    const groupedAssets: GroupedAssets = response.assets;

    // @ts-ignore canvas is filtered out in projectAssetsAsyncAtom
    const hydratedGroupedAssets: HydratedGroupedAssets = {
      zap: [],
      interface: [],
      table: [],
      chatbot: [],
    };

    // Only hydrate zaps, the rest are copied as-is for now
    if (groupedAssets.zap && groupedAssets.zap.length > 0) {
      const fetchPromises = groupedAssets.zap.map(async (zap) =>
        get(
          zapMetadataAsyncAtomFamily(zap.node_id ? String(zap.node_id) : null),
        ),
      );

      const zaps = await Promise.all(fetchPromises);

      hydratedGroupedAssets.zap = zaps.filter(
        (zap): zap is ZapMetadata =>
          zap !== null && !(zap as ZapErrorObject).error,
      );
    }

    return {
      assets: hydratedGroupedAssets,
      project: response.project,
    };
  },
);

projectAssetsHydratedAsyncAtom.debugLabel = "projectAssetsHydratedAsyncAtom";
