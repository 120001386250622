import { atom } from "jotai";

export const SUMMARY = "summary" as const;
export const PROJECT = "project" as const;

export type SideBarSelected =
  | typeof SUMMARY
  | typeof PROJECT
  | "recommendations"
  | "devTools"
  | "graphData"
  | null;

export const sideBarSelectedAtom = atom<SideBarSelected>(null);

sideBarSelectedAtom.debugLabel = "sideBarSelectedAtom";
