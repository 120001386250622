import { atom } from "jotai";

export type SelectableNodeTypes =
  | null
  | "default"
  | "path"
  | "group"
  | "zap"
  | "table"
  | "interface"
  | "chatbots"
  | "text";

export const selectedNodeTypeAtom = atom<SelectableNodeTypes>(null);

selectedNodeTypeAtom.debugLabel = "selectedNodeTypeAtom";
