import { atom } from "jotai";
import { Edge } from "reactflow";
import { edgesAtom } from "./edgesAtom";
import { TEMP } from "@zapier/canvas-constants";

export const setTempEdgeAtom = atom(
  null,
  (get, set, closeEdge: Edge | null) => {
    const edges = get(edgesAtom);

    const nextEdges = edges.filter((e) => e.className !== TEMP);
    if (
      closeEdge &&
      !nextEdges.find(
        (ne) =>
          ne.source === closeEdge.source && ne.target === closeEdge.target,
      )
    ) {
      nextEdges.push({
        ...closeEdge,
        className: TEMP /** used in Canvas.css .temp .react-flow__edge-path */,
      });
    }

    set(edgesAtom, nextEdges);
  },
);

setTempEdgeAtom.debugLabel = "setTempEdgeAtom";
