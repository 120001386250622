import { Node } from "reactflow";
import { atom } from "jotai";
import { nodesAtom } from "./nodesAtom";

export const visibleNodesAtom = atom<Node[]>((get) => {
  const nodes = get(nodesAtom);
  return nodes.filter((node) => node.hidden !== true);
});

visibleNodesAtom.debugLabel = "visibleNodesAtom";
