import { atom } from "jotai";

type Params = {
  naturalLanguage: string;
  shouldEmitEvent?: boolean;
  isExperimental?: boolean;
};

export const naturalLanguageToZslErrorAtom = atom(null);
naturalLanguageToZslErrorAtom.debugLabel = "naturalLanguageToZslErrorAtom";

export const naturalLanguageToZslAtom = atom(
  null,
  async (get, set, { naturalLanguage, isExperimental = false }: Params) => {
    try {
      const response = await fetch("/api/zsl/outline_combined", {
        method: "POST",
        headers: {
          "trailing-slash-hack": "true",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt: naturalLanguage, isExperimental }),
      });

      if (!response.ok) {
        const data = await response.json();
        const errorMessage =
          data.detail ||
          (Array.isArray(data.errors) ? data.errors.join(", ") : data.errors) ||
          response.statusText ||
          "An error occurred converting natural language to zsl";
        throw new Error(errorMessage);
      }

      const zsl = await response.json();
      set(naturalLanguageToZslErrorAtom, null);

      return zsl;
    } catch (error: any) {
      set(naturalLanguageToZslErrorAtom, error.message);
    }
  },
);

naturalLanguageToZslAtom.debugLabel = "naturalLanguageToZslAtom";
