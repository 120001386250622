import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { permissionsAtom } from "./fetchPermissionsAtomFamily";
import { getCanvasOrgPermission, Tuple } from "@zapier/permissions";

type Params = {
  currentAccountId: number | undefined;
  allowTeams: boolean;
};

export const orgPermissionAtomFamily = atomFamily(
  ({ currentAccountId, allowTeams }: Params) => {
    const getterAtom = atom((get) => {
      if (!currentAccountId || !allowTeams) {
        return "no access";
      }

      const permissions = get(permissionsAtom) as Tuple[];
      return getCanvasOrgPermission(permissions, currentAccountId, "canvas");
    });

    getterAtom.debugLabel = `orgPermissionAtomFamily(allowTeams: ${allowTeams})`;
    return getterAtom;
  },
  isEqual,
);

function isEqual(a: Params, b: Params) {
  return (
    a.allowTeams === b.allowTeams && a.currentAccountId === b.currentAccountId
  );
}
