import { Node, Edge } from "reactflow";

/**
 * Checks if there is a loop in the Canvas.
 *
 * This function looks at the connections between the nodes and edges in the Canvas
 * to see if there is any circular path that leads back to the starting point.
 */
export const hasCycle = (nodes: Node[], edges: Edge[]): boolean => {
  type AdjacencyList = { [key: string]: string[] };

  // Initialize the adjacency list for all nodes
  const adjacencyList: AdjacencyList = nodes.reduce((acc, node) => {
    acc[node.id] = [];
    return acc;
  }, {} as AdjacencyList);

  // Build the adjacency list from the edges
  edges.forEach((edge) => {
    if (adjacencyList[edge.source] !== undefined) {
      adjacencyList[edge.source].push(edge.target);
    }
  });

  const visited = new Set<string>();
  const recStack = new Set<string>();

  const dfs = (node: string): boolean => {
    if (!visited.has(node)) {
      visited.add(node);
      recStack.add(node);

      for (const neighbor of adjacencyList[node]) {
        if (!visited.has(neighbor) && dfs(neighbor)) {
          return true;
        } else if (recStack.has(neighbor)) {
          return true;
        }
      }
    }
    recStack.delete(node);
    return false;
  };

  // Check each node for cycles
  return nodes.some((node) => dfs(node.id));
};
