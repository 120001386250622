import { atom } from "jotai";
import { Node } from "reactflow";

import { nodesAtom } from "./nodesAtom";

export const updateNodeAtom = atom(
  null,
  (get, set, partialNode: Partial<Node>) => {
    const nodeId = partialNode.id;

    const nodes = get(nodesAtom);

    const updatedNodes = nodes.map((node) => {
      if (node.id === nodeId) {
        // it's important that we create a new object here
        // in order to notify react flow about the change
        node = {
          ...node,
          ...partialNode,
          style: {
            ...node.style,
            ...partialNode.style,
          },
          data: {
            ...node.data,
            ...partialNode.data,
          },
        };
      }

      return node;
    });

    set(nodesAtom, updatedNodes);
    return updatedNodes;
  },
);

updateNodeAtom.debugLabel = "updateNodeAtom";
