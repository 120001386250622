import { atom } from "jotai";

import { nodeAtomFamily } from "../nodes/nodeAtomFamily";

import { asyncZapAssetMetadataAtomFamily } from "./zapAssetMetadataAtomFamily";
import { updateAssetMetadataAtom } from "./updateAssetMetadataAtom";

export const createZapTimeSpentFromTimeSavedAtom = atom(
  null,
  async (get, set, nodeId: string, zapId: string) => {
    const node = get(nodeAtomFamily(nodeId));
    const existingMetadata = await get(
      asyncZapAssetMetadataAtomFamily({
        zapId,
        assetType: "zap",
        metadataName: "time_saved",
      }),
    );

    // If the default node has timeSpent and the zap doesn't have timeSaved, create timeSaved from timeSpent
    // Then update the zap with the new value to convey timeSpent converting to timeSaved
    // If existing metadata is 0, we should treat that as not set
    if (
      (!existingMetadata ||
        Number(existingMetadata.metadata_value.value) <= 0) &&
      Number(node?.data.timeSpent?.value) > 0
    ) {
      await set(updateAssetMetadataAtom, {
        zapId,
        assetType: "zap",
        metadataName: "time_saved",
        metadataValue: {
          unit: node?.data.timeSpent?.unit,
          value: node?.data.timeSpent?.value,
        },
      });
    }
  },
);

createZapTimeSpentFromTimeSavedAtom.debugLabel =
  "createZapTimeSpentFromTimeSavedAtom";
