import { projectAsyncAtom, ProjectFetchError } from "./projectAsyncAtom";
import { atomWithRefresh } from "../utils";
import { AssetType, ProjectDetails } from "../types";

export interface ProjectAsset {
  asset_type: AssetType;
  asset_id: string;
  asset_identifier: string;
  node_id?: number; // Only for zaps - this is the zap id
}

export type GroupedAssets = Record<AssetType, ProjectAsset[]>;

interface NoProjectResponse {
  assets: null;
}

export interface ProjectAssetsSuccess {
  assets: GroupedAssets;
  project: ProjectDetails;
}

export interface ProjectAssetsError {
  project: ProjectDetails | null;
  error: string;
  status: number;
  permissionDenied: boolean;
  assets: null;
  code?: string;
}

type ProjectAssetsResponse =
  | NoProjectResponse
  | ProjectAssetsSuccess
  | ProjectAssetsError;

export const projectAssetsAsyncAtom = atomWithRefresh<
  Promise<ProjectAssetsResponse>
>(async (get) => {
  let project: ProjectDetails | null = null;

  try {
    project = await get(projectAsyncAtom);

    if (!project) {
      return { assets: null } as NoProjectResponse;
    }
  } catch (error) {
    console.error("Error fetching project:", error);
    /* When not logged in, we'll get
     {
     code: "not_authenticated",
     message: "Authentication credentials were not provided."
     status: 403
     }
    */
    const code = (error as ProjectFetchError).code;

    return {
      error: error instanceof Error ? error.message : String(error),
      status: (error as ProjectFetchError).status || 500,
      code,
      permissionDenied: (error as ProjectFetchError).status === 403,
      assets: null,
    } as ProjectAssetsError;
  }

  try {
    // At this point, project is guaranteed to be of type ProjectDetails
    const response = await fetch(`/api/v1/projects/${project.id}/assets`);

    if (!response.ok) {
      const data = await response.json();

      const error =
        Array.isArray(data.errors) && data.errors.length > 0
          ? data.errors[0]?.detail
          : data.error;

      return {
        project,
        error: error || "Unknown error fetching project assets",
        status: response.status,
        permissionDenied: response.status === 403,
        assets: null,
      } as ProjectAssetsError;
    }

    const data: { results: ProjectAsset[] } = await response.json();

    const groupedAssets = data.results.reduce<GroupedAssets>((acc, asset) => {
      if (asset.asset_type === "canvas") {
        return acc;
      }

      if (!acc[asset.asset_type]) {
        acc[asset.asset_type] = [];
      }

      acc[asset.asset_type].push(asset);
      return acc;
    }, {} as GroupedAssets);

    return {
      assets: groupedAssets,
      project: project as ProjectDetails,
    } as ProjectAssetsSuccess;
  } catch (error) {
    console.error("Error fetching project assets:", error);

    return {
      project: project as ProjectDetails,
      error: error instanceof Error ? error.message : String(error),
      status: 500,
      permissionDenied: false,
      assets: null,
    } as ProjectAssetsError;
  }
});

projectAssetsAsyncAtom.debugLabel = "projectAssetsAsyncAtom";
