import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { nodesAtom } from "./nodesAtom";

export const nodeAtomFamily = atomFamily((nodeId: string) => {
  const nodeAtom = atom((get) => {
    const nodes = get(nodesAtom);
    return nodes.find(({ id }) => id === nodeId);
  });

  nodeAtom.debugLabel = `nodeAtomFamily(${nodeId})`;
  return nodeAtom;
});
