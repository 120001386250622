import { graphlib, layout } from "dagre";
import { Edge, Node, Position } from "reactflow";
import {
  DEFAULT_NODE_HEIGHT,
  ZAP_STEP_NODE_HEIGHT,
  DEFAULT_NODE_WIDTH,
} from "@zapier/canvas-constants";

type Params = {
  zapNode: Node;
  zapSteps: Node[];
  zapStepEdges: Edge[];
};

function getLayoutedZapStepsAndEdges(
  nodes: Node[],
  edges: Edge[],
  dagreGraph: graphlib.Graph,
) {
  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: node.width,
      height: node.height,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  layout(dagreGraph);

  const firstNodeWithPosition = dagreGraph.node(nodes[0].id);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);

    // This adjustment horizontally centers the zap steps relative to the zap node above
    const xAdjustment = firstNodeWithPosition.x;

    return {
      ...node,
      targetPosition: Position.Top,
      sourcePosition: Position.Bottom,
      position: {
        x: nodeWithPosition.x - xAdjustment,
        y: nodeWithPosition.y - ZAP_STEP_NODE_HEIGHT / 2,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
}

export function layoutElementsVertically({
  zapNode,
  zapSteps,
  zapStepEdges,
}: Params) {
  const dagreGraph = new graphlib.Graph();
  dagreGraph.setGraph({ rankdir: "TB" });
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setNode(zapNode.id, {
    width: DEFAULT_NODE_WIDTH,
    height: zapNode.height ?? DEFAULT_NODE_HEIGHT,
  });

  const { nodes: layoutedZapSteps, edges: layoutedZapStepEdges } =
    getLayoutedZapStepsAndEdges(zapSteps, zapStepEdges, dagreGraph);

  return { layoutedZapSteps, layoutedZapStepEdges };
}
