import { atom } from "jotai";
import { emitZslExecutionErrorEventAtom } from "../events";
import { hasTemplateExecutionFailedAtom } from "./hasTemplateExecutionFailedAtom";
import { showAIStreamCreateModalAtom } from "./showAIStreamCreateModalAtom";
import { templateAtom } from "./templateAtom";

type Params = { error: unknown; statusCode: number; prompt: string };

export const handleZslExecutionErrorCommand = atom(
  null,
  (get, set, { error, statusCode, prompt }: Params) => {
    set(hasTemplateExecutionFailedAtom, true);

    if (prompt) {
      set(showAIStreamCreateModalAtom, true);
    } else {
      // TODO: implement error handling for template creation with ZSL not generated from AI
    }

    const zslTemplate = get(templateAtom);

    if (zslTemplate) {
      const { canvases, ...zsl } = zslTemplate;

      const errorMessage =
        error instanceof Error ? error.message : JSON.stringify(error);

      set(emitZslExecutionErrorEventAtom, {
        prompt,
        zsl: JSON.stringify(zsl),
        status_code: statusCode,
        error_message: errorMessage,
      });
    }
  },
);

handleZslExecutionErrorCommand.debugLabel = "handleZslExecutionErrorCommand";
