import { Node } from "reactflow";
import { atom } from "jotai";

import { DefaultNodeData, NodeData, Service } from "../types";
import { zapMetadataAsyncAtomFamily } from "../zaps";
import {
  flattenOneLevel,
  getUniqueAppsFromSteps,
  isZapErrorObject,
} from "../utils";

import { selectedNodesAtom } from "./selectedNodesAtom";
import { uniqueZapIdsAtom } from "./uniqueAppsForAllNodesAsyncAtom";

/**
 * Returns an array of unique app IDs from selected nodes. Handles both default and Zap nodes.
 * For Zap nodes, it recursively retrieves all app IDs from path steps.
 */
export const uniqueAppsForSelectedNodesAsyncAtom = atom<Promise<string[]>>(
  async (get) => {
    const selectedNodes: Node<NodeData>[] = get(selectedNodesAtom);
    const defaultNodes = selectedNodes.filter(
      (node) => node.type === "default",
    );
    const uniqueZapIds = get(uniqueZapIdsAtom);

    const zapNodeApps = await Promise.all(
      uniqueZapIds.map(async (zapId) => {
        const zap = await get(zapMetadataAsyncAtomFamily(zapId));
        if (!zap || isZapErrorObject(zap)) {
          return [];
        }

        const steps = zap.steps || [];
        return getUniqueAppsFromSteps(steps);
      }),
    );

    const zapNodeAppsFlattened = flattenOneLevel(zapNodeApps);

    const defaultNodeApps = defaultNodes
      .reduce(
        (acc, curr) => [
          ...acc,
          ...((curr.data as DefaultNodeData).services || []),
        ],
        [] as Service[],
      )
      .filter(Boolean);

    const allApps: string[] = [
      ...defaultNodeApps.map((app) => app.currentImplementation.id),
      ...zapNodeAppsFlattened,
    ];

    const uniqueAppsSet = new Set([...allApps]);

    return Array.from(uniqueAppsSet);
  },
);

uniqueAppsForSelectedNodesAsyncAtom.debugLabel =
  "uniqueAppsForSelectedNodesAsyncAtom";
