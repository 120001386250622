import { atom } from "jotai";
import { nodesAtom } from "../nodes/nodesAtom";
import { edgesAtom } from "../edges/edgesAtom";
import { Node } from "reactflow";

export function getNodeIdsToBeDeleted(nodes: Node[]) {
  return nodes
    .filter((node) => {
      if (node.type === "zapStep" && node.parentId) {
        const parentNode = nodes.find((parent) => parent.id === node.parentId);

        return parentNode && !parentNode.data.zapId;
      }

      // Exclude nodes without a parentNode
      return false;
    })
    .map((node) => node.id);
}

export const deleteZapStepNodesAndEdgesAtom = atom(null, (get, set) => {
  const nodes = get(nodesAtom);
  const edges = get(edgesAtom);

  /** When user removes the Zap or converts a Zap node to a default node, we also need to remove the zap step nodes and edges that no longer apply */
  const shouldBeDeletedNodeIds = getNodeIdsToBeDeleted(nodes);

  const filteredNodes = nodes.filter(
    (node) => !shouldBeDeletedNodeIds.includes(node.id),
  );

  const filteredEdges = edges.filter(
    (edge) =>
      !shouldBeDeletedNodeIds.includes(edge.source) &&
      !shouldBeDeletedNodeIds.includes(edge.target),
  );

  set(nodesAtom, filteredNodes);
  set(edgesAtom, filteredEdges);
});

deleteZapStepNodesAndEdgesAtom.debugLabel = "deleteZapStepNodesAndEdgesAtom";
