import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { nodeAtomFamily } from "./nodeAtomFamily";

export const nodeClassNameAtomFamily = atomFamily((nodeId: string) => {
  const nodeClassNameAtom = atom((get) => {
    const node = get(nodeAtomFamily(nodeId));
    return node?.className;
  });

  nodeClassNameAtom.debugLabel = `nodeClassNameAtomFamily(${nodeId})`;
  return nodeClassNameAtom;
});
