import { atom } from "jotai";
import { newAssetIdentifierAtom } from "./newAssetIdentifierAtom";
import { ProjectAsset, projectAssetsAsyncAtom } from "./projectAssetsAsyncAtom";
import { AssetType } from "../types";

type ReturnValue = boolean | undefined;

export const isAssetInProjectAsyncAtom = atom<Promise<ReturnValue>>(
  async (get) => {
    const newAssetIdentifier = get(newAssetIdentifierAtom);

    if (!newAssetIdentifier) {
      return;
    }

    const [assetType, nodeIdString] = newAssetIdentifier.split(":");
    const nodeId = Number(nodeIdString);

    const response = await get(projectAssetsAsyncAtom);

    if (response?.assets) {
      const assets = response.assets;
      if (Object.prototype.hasOwnProperty.call(assets, assetType)) {
        return assets[assetType as AssetType].some(
          (asset: ProjectAsset) => asset.node_id === nodeId,
        );
      }
    }
  },
);

isAssetInProjectAsyncAtom.debugLabel = "isAssetInProjectAsyncAtom";
