import { atom } from "jotai";

import { BuiltInApp, Chatbot } from "../types";
import { atomWithRefresh } from "../utils";

export const chatbotsAppAtom = atom<BuiltInApp | null>(null);
chatbotsAppAtom.debugLabel = "chatbotsAppAtom";

export const chatbotsAsyncAtom = atomWithRefresh<Promise<Chatbot[]>>(
  async () => {
    try {
      const res = await fetch(`/api/chatbots/v0/chatbots`, {
        headers: {
          "trailing-slash-hack": "true",
        },
      });

      if (!res.ok) {
        const data = await res.json();
        throw new Error(data.errors);
      }

      const json = await res.json();
      return json.data;
    } catch (e) {
      return [];
    }
  },
);

chatbotsAsyncAtom.debugLabel = "chatbotsAsyncAtom";
