import { atom } from "jotai";
import { haveGroupNodesAtom } from "../nodes";
import { isTemplateModeAtom } from "../templates";
import { isViewerAtom } from "../canvas";

export const isPublicAtom = atom(false);
isPublicAtom.debugLabel = "isPublicAtom";

export const isEmbedAtom = atom(false);
isEmbedAtom.debugLabel = "isEmbedAtom";

export const isViewOnlyModeAtom = atom((get) => {
  const haveGroupNodes = get(haveGroupNodesAtom);
  const isPublic = get(isPublicAtom);
  const isTemplate = get(isTemplateModeAtom);
  const isEmbed = get(isEmbedAtom);
  const isViewer = get(isViewerAtom); // This will be true when is_public is true (so true for public links)

  // isViewer & isPublic: all nodes except path is selectable
  // isTemplate || isEmbed: all nodes not selectable
  return isViewer || isPublic || isTemplate || isEmbed || haveGroupNodes;
});

isViewOnlyModeAtom.debugLabel = "isViewOnlyModeAtom";
