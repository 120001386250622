import { atom } from "jotai";
import { Actor, DefaultNodeData } from "../types";
import { nodesAtom, selectedNodesAtom } from "../nodes";
import { atomFamily } from "jotai/utils";

// Get all actors for all nodes or selected nodes
export const actorsAtomFamily = atomFamily((onlyForSelectedNodes: boolean) => {
  const actorsAtom = atom((get) => {
    const nodes = onlyForSelectedNodes
      ? get(selectedNodesAtom)
      : get(nodesAtom);
    const actors = nodes.flatMap(
      (node) => (node.data as DefaultNodeData)?.actors ?? [],
    );

    // Return unique actors
    return actors.reduce((acc, curr) => {
      if (!acc.some((obj) => obj.label === curr.label)) {
        acc.push(curr);
      }
      return acc;
    }, [] as Actor[]);
  });

  actorsAtom.debugLabel = onlyForSelectedNodes
    ? "actorsAtomFamily(onlyForSelectedNodes:true)"
    : "actorsAtomFamily(onlyForSelectedNodes:false)";

  return actorsAtom;
});
