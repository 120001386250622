import { Node, Edge } from "reactflow";
import buildHierarchy from "../../../canvas/src/utils/buildHierarchy";
import { hierarchy } from "d3-hierarchy";
import { hasCycle } from "./hasCycle";

function getHierarchyFromNode(nodeId: string, idToNodeMap: Map<string, Node>) {
  const rootNode = idToNodeMap.get(nodeId);
  /* This will crash the browser if there's a loop in a Canvas */
  return hierarchy(rootNode);
}

/* This will give you all the VISIBLE descendants of a node. Hidden nodes are NOT included.
 * If includeSelf = true, it will return the node itself.
 * */
export function getVisibleDescendants(
  id: string,
  nodes: Node[],
  edges: Edge[],
  includeSelf: boolean,
): Node[] | undefined {
  const willCreateCycle = hasCycle(nodes, edges);

  // Check for cycles or else hierarchy function from d3-hierarchy will never stop running and the browser will crash (CNVS-747)
  if (willCreateCycle) {
    return undefined;
  }

  const idToNodeMap = buildHierarchy(nodes, edges);
  const subtree = getHierarchyFromNode(id, idToNodeMap);

  if (includeSelf) {
    // Filter out undefined values and return only the Node objects
    return subtree
      .descendants()
      .filter((desc) => desc.data !== undefined)
      .map((desc) => desc.data as Node);
  } else {
    // Filter out undefined values and source node and return only the Node objects
    return subtree
      .descendants()
      .filter((desc) => desc.data !== undefined && desc.data.id !== id)
      .map((desc) => desc.data as Node);
  }
}
