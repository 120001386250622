import { AllPlansType, PlanType } from "../types";

type Params = {
  type: "interfaces" | "tables";
  currentPlanLimit?: number;
  currentPlanName?: AllPlansType;
};

export function getUpgradePlanType({
  type,
  currentPlanLimit = 0,
  currentPlanName,
}: Params): PlanType {
  if (type === "interfaces") {
    return currentPlanName === "free" ? "premium" : "advanced";
  }

  if (type === "tables") {
    return currentPlanLimit <= 5 ? "premium" : "advanced";
  }

  throw new Error("Invalid type. Must be 'interfaces' or 'tables'.");
}
