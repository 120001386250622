import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { selectedNodesAtom } from "./selectedNodesAtom";

export const isMultiSelectedAtomFamily = atomFamily((nodeId: string) => {
  const isMultiSelectedAtom = atom((get) => {
    const selectedNodes = get(selectedNodesAtom);

    if (selectedNodes.length === 1) {
      return false;
    }

    return selectedNodes.some(({ id }) => id === nodeId);
  });

  isMultiSelectedAtom.debugLabel = `isMultiSelectedAtomFamily(${nodeId})`;

  return isMultiSelectedAtom;
});
