import { canvasAtom } from "../";
import { atom } from "jotai";

export const newAssetNameAtom = atom((get) => {
  const { name: canvasName } = get(canvasAtom);

  if (!canvasName) {
    return "Created by Canvas";
  }

  return `${canvasName} (created by Canvas)`;
});

newAssetNameAtom.debugLabel = "newAssetNameAtom";
