import { atom } from "jotai";
import { NodeData, NodeType } from "../types";
import { Edge, Node } from "reactflow";
import { takeSnapshotAtom } from "../diagramming";
import { nodesAtom } from "../nodes";
import { edgesAtom } from "../edges";
import {
  calculatePathChildrenYPositions,
  getAbsoluteNodePosition,
  getCenteredYPosition,
  uuid,
} from "../utils";
import { GAP_BETWEEN_NODES } from "@zapier/canvas-constants";
import { MARKER } from "../constants";

type NodesAndEdges = { nodes: Node[]; edges: Edge[] };

type Params = {
  sourceNodeId: string | null;
  nodePartial: Partial<Node<NodeData>>;
  numOfPaths: number;
  saveNodesAndEdges: ({ nodes, edges }: NodesAndEdges) => void;
  shouldAddIncomerToPathNodeEdge?: boolean;
};

export const addAndSavePathNodeAtom = atom(
  null,
  (
    get,
    set,
    {
      sourceNodeId,
      nodePartial,
      numOfPaths,
      saveNodesAndEdges,
      shouldAddIncomerToPathNodeEdge = true,
    }: Params,
  ) => {
    set(takeSnapshotAtom);

    const nodes = get(nodesAtom);
    const edges = get(edgesAtom);

    /** If sourceNodeId = null, sourceNode is the path node itself when you drop a path node without connecting it to another node */
    const sourceNode = sourceNodeId
      ? nodes.find((node) => node.id === sourceNodeId)!
      : nodePartial;
    const newNodes: Node<Partial<NodeData>>[] = [];
    const newEdges: Edge[] = [];
    const pathNodeId = nodePartial.id ?? uuid();

    const currentMousePosition = {
      x: sourceNode.position!.x,
      y: sourceNode.position!.y,
    };

    const sourceNodePosition = sourceNodeId
      ? getAbsoluteNodePosition(sourceNode as Node, nodes)
      : currentMousePosition;

    const position = sourceNodeId
      ? {
          x: sourceNodePosition.x + GAP_BETWEEN_NODES,
          y: getCenteredYPosition({
            nodePosition: sourceNodePosition,
            nodeType: sourceNode.type as NodeType,
            isPathNode: true,
          }),
        }
      : currentMousePosition;

    newNodes.push({
      ...nodePartial,
      id: pathNodeId,
      position,
      data: { label: "" },
      selected: false,
    });

    if (sourceNodeId && shouldAddIncomerToPathNodeEdge) {
      const incomerToPathNodeEdge: Edge = {
        id: `${sourceNodeId}=>${pathNodeId}`,
        source: sourceNodeId,
        target: pathNodeId,
        markerEnd: MARKER,
      };

      newEdges.push(incomerToPathNodeEdge);
    }

    /* When you add a path node from diagramming toolbar without a connecting node,
       we add offset so the children steps look even
     */
    const yOffset = sourceNodeId ? 0 : 34;

    const childYPositions = calculatePathChildrenYPositions(
      sourceNodePosition.y + yOffset,
      numOfPaths,
    );

    for (let i = 0; i < numOfPaths; i++) {
      const pathNodeChildId = uuid();
      const pathNode: Node = {
        id: pathNodeChildId,
        position: {
          x:
            sourceNodePosition.x +
            (sourceNodeId ? GAP_BETWEEN_NODES * 2 : GAP_BETWEEN_NODES),
          y: childYPositions[i],
        },
        type: "default",
        data: { label: "", ...nodePartial.data },
      };

      newNodes.push(pathNode);

      const pathNodeToOutgoerEdge: Edge = {
        id: `${pathNodeId}=>${pathNodeChildId}`,
        source: pathNodeId,
        target: pathNodeChildId,
        markerEnd: MARKER,
        selected: false,
      };

      newEdges.push(pathNodeToOutgoerEdge);
    }

    let updatedNewNodes = nodes;
    let updatedNewEdges = edges;

    set(edgesAtom, (edges) => {
      updatedNewEdges = edges.concat(newEdges);
      return updatedNewEdges;
    });

    set(nodesAtom, (nodes) => {
      const deselectedNodes: Node[] = nodes.map((node) => ({
        ...node,
        selected: false,
      }));
      updatedNewNodes = deselectedNodes.concat(newNodes);
      return updatedNewNodes;
    });

    saveNodesAndEdges({
      nodes: updatedNewNodes,
      edges: updatedNewEdges,
    });
  },
);

addAndSavePathNodeAtom.debugLabel = "addAndSavePathNodeAtom";
