import { atom } from "jotai";
import { nodesAtom } from "./nodesAtom";

export const selectSingleNodeAtom = atom(null, (get, set, nodeId) => {
  const nodes = get(nodesAtom);
  const updatedNodes = nodes.map((node) => ({
    ...node,
    selected: node.id === nodeId,
  }));

  set(nodesAtom, updatedNodes);
});

selectSingleNodeAtom.debugLabel = "selectSingleNodeAtom";
