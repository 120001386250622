import { atom } from "jotai";
import { zapIdsAtom } from "./zapIdsAtom";
import {
  ZapErrorObject,
  zapMetadataAsyncAtomFamily,
} from "./zapAsyncAtomFamily";
import { ZapMetadata } from "../types";
import { projectAssetsAsyncAtom } from "../project";

export const zapsAsyncAtom = atom(async (get) => {
  const zapIdsFromNodes: string[] = get(zapIdsAtom);

  const response = await get(projectAssetsAsyncAtom);

  const zapIdsFromProject =
    "error" in response || !(response.assets && response.assets.zap)
      ? []
      : response.assets.zap.map((z) => String(z.node_id));

  const uniqueZapIds = new Set([...zapIdsFromNodes, ...zapIdsFromProject]);

  const zapsPromises = Array.from(uniqueZapIds).map((zapId) =>
    get(zapMetadataAsyncAtomFamily(zapId)).then((zap) => ({ zapId, zap })),
  );
  const zaps = await Promise.all(zapsPromises);
  return zaps.reduce<Record<string, ZapMetadata>>((acc, { zapId, zap }) => {
    // Check if zap is not null and does not contain an error
    if (zap && !(zap as ZapErrorObject).error) {
      acc[zapId] = zap as ZapMetadata;
    }
    return acc;
  }, {});
});

zapsAsyncAtom.debugLabel = "zapsAsyncAtom";
