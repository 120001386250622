import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { currentAccountIdAtom } from "../account";
import { Member } from "../types";

type Args = {
  customuser_id: number | null;
};

export const ownerAsyncAtomFamily = atomFamily(
  ({ customuser_id }: Args) => {
    const ownerAtom = atom<Promise<Member>>(async (get) => {
      const currentAccountId = get(currentAccountIdAtom);
      // When an unauthenticated user is viewing a canvas, we don't have a currentAccountId
      if (!currentAccountId || !customuser_id) {
        return null;
      }
      const res = await fetch(
        `/api/v4/accounts/${currentAccountId}/members/${customuser_id}`,
      );
      return await res.json();
    });

    ownerAtom.debugLabel = `ownerAsyncAtomFamily`;
    return ownerAtom;
  },
  (a, b) => a.customuser_id === b.customuser_id,
);
