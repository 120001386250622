import { Node, Edge } from "reactflow";

export default function buildHierarchy(nodes: Node[], edges: Edge[]) {
  type NodeWithChild = Node & { children?: NodeWithChild[] };

  const idToNode = new Map(nodes.map((d) => [d.id, { ...d, children: [] }]));

  edges.forEach((edge) => {
    // Massage data so hierarchy can interpret it
    // each node needs an array of children on it

    const parent: NodeWithChild | undefined = idToNode.get(edge.source);
    const child: NodeWithChild | undefined = idToNode.get(edge.target);
    if (parent && child) {
      parent.children?.push(child);
    }
  });

  return idToNode; // Return entire map
}
