import { Edge, Node } from "reactflow";

/**
 * Removes invalid edges related to a specific Zap node based on the following criteria:
 * - The edge is considered invalid if its source or target is not included in the list of valid Zap step node IDs for the given Zap node.
 * - This function is intended to be used for each Zap node individually to clean up edges
 *   connected to Zap steps that have been deleted or are otherwise invalid within the context of that specific Zap node.
 */
export function dropInvalidEdges(
  zapNodeId: string,
  zapStepNodes: Node[],
  edges: Edge[],
) {
  const zapStepNodesIds = zapStepNodes.map((node) => node.id);
  const partialZapStepId = `zap-${zapNodeId}`;

  const invalidEdges = edges.filter(
    (e) =>
      (e.source.includes(partialZapStepId) &&
        !zapStepNodesIds.includes(e.source)) ||
      (e.target.includes(partialZapStepId) &&
        !zapStepNodesIds.includes(e.target)),
  );

  return edges.filter((edge) => !invalidEdges.includes(edge));
}
