import { atom } from "jotai";
import { selectedNodesAtom } from "../nodes";

export const hasExpandedSelectedZapNodesAtom = atom((get) => {
  const selectedNodes = get(selectedNodesAtom);
  return (
    selectedNodes.filter((node) => node.type === "zap" && node.data.isExpanded)
      .length > 0
  );
});

hasExpandedSelectedZapNodesAtom.debugLabel = "hasExpandedSelectedZapNodesAtom";
