import { atom } from "jotai";

import { Canvas } from "../types";

const defaultCanvasState = {
  id: "",
  account_id: 0,
  name: "",
  full_name: "",
  photo_url: "",
  nodes: [],
  edges: [],
  lastchanged: null,
  created: null,
  description: null,
  customuser_id: null,
  access_level: "can_view" as const,
};

export const canvasAtom = atom<Canvas>(defaultCanvasState);
canvasAtom.debugLabel = "canvasAtom";

export const canvasNameAtom = atom((get) => get(canvasAtom).name);
canvasNameAtom.debugLabel = "canvasNameAtom";

export const canvasIdAtom = atom((get) => get(canvasAtom).id);
canvasIdAtom.debugLabel = "canvasIdAtom";
