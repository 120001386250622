import {
  AI_PREDICTION_OUTPUT_EVENT,
  AI_PREDICTION_USER_FEEDBACK_EVENT,
  HELPFUL,
  NOT_HELPFUL,
  USER_INTERACTION_EVENT,
  ZSL_EXECUTION_ERROR,
} from "@zapier/canvas-constants";
import { atom } from "jotai";

import { uuid } from "../utils";
import { canvasIdAtom } from "../canvas";
import { currentAccountIdAtom, sessionAtom } from "../account";

declare global {
  interface Window {
    EventToolkit: {
      emit(options: Record<string, any>, event: string): any;
    };
  }
}

const commonPropsAtom = atom((get) => {
  const canvasId = get(canvasIdAtom);
  const session = get(sessionAtom);
  const currentAccountId = get(currentAccountIdAtom);
  return {
    system_name: "canvas",
    canvas_id: canvasId,
    customuser_id: session?.id,
    account_id: currentAccountId,
  };
});
commonPropsAtom.debugLabel = "commonPropsAtom";

type PredictionOutputEventPayload = {
  prediction_id: string;
  prediction_source: string;
  prediction_json_input: string;
  prediction_json_output: string;
  correlation_id?: string;
  model_name?: string;
};

export const emitAiPredictionOutputEventAtom = atom(
  null,
  (get, _set, payload: PredictionOutputEventPayload) => {
    if (window.EventToolkit?.emit) {
      const commonProps = get(commonPropsAtom);
      window.EventToolkit.emit(
        {
          model_provider_name: "openai",
          model_name: "gpt-4o",
          ...payload,
          ...commonProps,
        },
        AI_PREDICTION_OUTPUT_EVENT,
      );
    }
  },
);
emitAiPredictionOutputEventAtom.debugLabel = "emitAiPredictionOutputEventAtom";

type PredictionUserFeedbackEventPayload = {
  prediction_id: string;
  user_rating: typeof HELPFUL | typeof NOT_HELPFUL | null;
  user_feedback: string;
  correlation_id?: string;
};

export const emitAiPredictionUserFeedbackEventAtom = atom(
  null,
  (get, _set, payload: PredictionUserFeedbackEventPayload) => {
    if (window.EventToolkit?.emit) {
      const commonProps = get(commonPropsAtom);
      window.EventToolkit.emit(
        {
          ...payload,
          ...commonProps,
        },
        AI_PREDICTION_USER_FEEDBACK_EVENT,
      );
    }
  },
);
emitAiPredictionUserFeedbackEventAtom.debugLabel =
  "emitAiPredictionUserFeedbackEventAtom";

export const emitEventOnAICopilotStartAtom = atom(null, (get) => {
  if (window.EventToolkit?.emit) {
    const commonProps = get(commonPropsAtom);
    window.EventToolkit.emit(
      {
        event_id: uuid(),
        event_action: "click",
        interaction_goal: "AI Copilot start",
        interaction_raw_text: "Start with AI Copilot",
        option_selected: "Role Specific Prompt",
        ...commonProps,
      },
      USER_INTERACTION_EVENT,
    );
  }
});
emitEventOnAICopilotStartAtom.debugLabel = "emitEventOnAICopilotStartAtom";

export const emitEventOnAIExecutionAtom = atom(null, (get) => {
  if (window.EventToolkit?.emit) {
    const commonProps = get(commonPropsAtom);
    window.EventToolkit.emit(
      {
        event_id: uuid(),
        event_action: "click",
        interaction_goal: "AI Execution",
        interaction_raw_text: "Use this system",
        ...commonProps,
      },
      USER_INTERACTION_EVENT,
    );
  }
});
emitEventOnAIExecutionAtom.debugLabel = "emitEventOnAIExecutionAtom";

export const emitEventOnExecuteTemplateAtom = atom(
  null,
  (get, _set, slug: string) => {
    if (window.EventToolkit?.emit) {
      const commonProps = get(commonPropsAtom);
      window.EventToolkit.emit(
        {
          event_id: uuid(),
          event_action: "click",
          interaction_goal: "Execute template",
          interaction_raw_text: slug,
          ...commonProps,
        },
        USER_INTERACTION_EVENT,
      );
    }
  },
);
emitEventOnExecuteTemplateAtom.debugLabel = "emitEventOnExecuteTemplateAtom";

export const emitEventOnInterfacesPlanUpgradeAtom = atom(
  null,
  (get, _set, rawText: string) => {
    if (window.EventToolkit?.emit) {
      const commonProps = get(commonPropsAtom);
      window.EventToolkit.emit(
        {
          event_id: uuid(),
          event_action: "click",
          interaction_goal: "upgrade Interfaces",
          interaction_raw_text: rawText,
          ...commonProps,
        },
        USER_INTERACTION_EVENT,
      );
    }
  },
);
emitEventOnInterfacesPlanUpgradeAtom.debugLabel =
  "emitEventOnInterfacesPlanUpgradeAtom";

export const emitEventOnModifyPromptAtom = atom(null, (get) => {
  if (window.EventToolkit?.emit) {
    const commonProps = get(commonPropsAtom);
    window.EventToolkit.emit(
      {
        event_id: uuid(),
        event_action: "click",
        interaction_goal: "AI Copilot modify prompt",
        interaction_raw_text: "Modify prompt",
        ...commonProps,
      },
      USER_INTERACTION_EVENT,
    );
  }
});
emitEventOnModifyPromptAtom.debugLabel = "emitEventOnModifyPromptAtom";

export const emitEventOnNewCanvasCreationAtom = atom(
  null,
  async (get, _set, rawText?: string) => {
    if (window.EventToolkit?.emit) {
      const commonProps = get(commonPropsAtom);
      await window.EventToolkit.emit(
        {
          event_id: uuid(),
          event_action: "click",
          interaction_goal: "Create Canvas",
          interaction_raw_text: rawText || "Create",
          ...commonProps,
        },
        USER_INTERACTION_EVENT,
      );
    }
  },
);
emitEventOnNewCanvasCreationAtom.debugLabel =
  "emitEventOnNewCanvasCreationAtom";

export const emitEventOnPromptButtonClickAtom = atom(
  null,
  (get, _set, rawText, optionSelected?: string) => {
    if (window.EventToolkit?.emit) {
      const commonProps = get(commonPropsAtom);
      window.EventToolkit.emit(
        {
          event_id: uuid(),
          event_action: "click",
          interaction_goal: "AI Copilot prompt button click",
          interaction_raw_text: rawText,
          option_selected: optionSelected,
          ...commonProps,
        },
        USER_INTERACTION_EVENT,
      );
    }
  },
);
emitEventOnPromptButtonClickAtom.debugLabel =
  "emitEventOnPromptButtonClickAtom";

export const emitEventOnStartFromScratchAtom = atom(null, (get) => {
  if (window.EventToolkit?.emit) {
    const commonProps = get(commonPropsAtom);
    window.EventToolkit.emit(
      {
        event_id: uuid(),
        event_action: "click",
        interaction_goal: "Start from scratch or template",
        interaction_raw_text: "Start from scratch or template",
        ...commonProps,
      },
      USER_INTERACTION_EVENT,
    );
  }
});
emitEventOnStartFromScratchAtom.debugLabel = "emitEventOnStartFromScratchAtom";

export const emitEventOnStartingOverAtom = atom(null, (get, _set) => {
  if (window.EventToolkit?.emit) {
    const commonProps = get(commonPropsAtom);
    window.EventToolkit.emit(
      {
        event_id: uuid(),
        event_action: "click",
        interaction_goal: "Start over from AI",
        interaction_raw_text: "Start over",
        ...commonProps,
      },
      USER_INTERACTION_EVENT,
    );
  }
});
emitEventOnStartingOverAtom.debugLabel = "emitEventOnStartingOverAtom";

export const emitEventOnTablesPlanUpgradeAtom = atom(
  null,
  (get, _set, rawText: string) => {
    if (window.EventToolkit?.emit) {
      const commonProps = get(commonPropsAtom);
      window.EventToolkit.emit(
        {
          event_id: uuid(),
          event_action: "click",
          interaction_goal: "upgrade Tables",
          interaction_raw_text: rawText,
          ...commonProps,
        },
        USER_INTERACTION_EVENT,
      );
    }
  },
);
emitEventOnTablesPlanUpgradeAtom.debugLabel =
  "emitEventOnTablesPlanUpgradeAtom";

export const emitEventOnTemplateToolbarCloseAtom = atom(null, (get) => {
  if (window.EventToolkit?.emit) {
    const commonProps = get(commonPropsAtom);
    window.EventToolkit.emit(
      {
        event_id: uuid(),
        event_action: "click",
        interaction_goal: "Template toolbar closed",
        interaction_raw_text: "Close template toolbar",
        ...commonProps,
      },
      USER_INTERACTION_EVENT,
    );
  }
});
emitEventOnTemplateToolbarCloseAtom.debugLabel =
  "emitEventOnTemplateToolbarCloseAtom";

export const emitEventOnWelcomeModalCloseAtom = atom(null, (get) => {
  if (window.EventToolkit?.emit) {
    const commonProps = get(commonPropsAtom);
    window.EventToolkit.emit(
      {
        event_id: uuid(),
        event_action: "click",
        interaction_goal: "Welcome modal closed",
        interaction_raw_text: "Cancel",
        ...commonProps,
      },
      USER_INTERACTION_EVENT,
    );
  }
});
emitEventOnWelcomeModalCloseAtom.debugLabel =
  "emitEventOnWelcomeModalCloseAtom";

export const emitTimeSavedAtom = atom(null, (get, _set, timeSaved: number) => {
  if (window.EventToolkit?.emit) {
    const commonProps = get(commonPropsAtom);
    window.EventToolkit.emit(
      {
        event_id: uuid(),
        event_action: "click",
        interaction_goal: "time saved",
        interaction_raw_text: Number(timeSaved),
        ...commonProps,
      },
      USER_INTERACTION_EVENT,
    );
  }
});
emitTimeSavedAtom.debugLabel = "emitTimeSavedAtom";

export const emitTimeSpentAtom = atom(null, (get, _set, timeSpent: number) => {
  if (window.EventToolkit?.emit) {
    const commonProps = get(commonPropsAtom);
    window.EventToolkit.emit(
      {
        event_id: uuid(),
        event_action: "click",
        interaction_goal: "time spent",
        interaction_raw_text: Number(timeSpent),
        ...commonProps,
      },
      USER_INTERACTION_EVENT,
    );
  }
});
emitTimeSpentAtom.debugLabel = "emitTimeSpentAtom";

type ZslExecutionErrorEventParams = {
  prompt: string;
  zsl: string;
  status_code: number;
  error_message: string;
};

export const emitZslExecutionErrorEventAtom = atom(
  null,
  (
    get,
    _set,
    { error_message, prompt, zsl, status_code }: ZslExecutionErrorEventParams,
  ) => {
    if (window.EventToolkit?.emit) {
      const commonProps = get(commonPropsAtom);

      window.EventToolkit.emit(
        {
          event_id: uuid(),
          origin_system_name: "canvas",
          prompt,
          zsl,
          status_code,
          error_message,
          ...commonProps,
        },
        ZSL_EXECUTION_ERROR,
      );
    }
  },
);
emitZslExecutionErrorEventAtom.debugLabel = "emitZslExecutionErrorEventAtom";
