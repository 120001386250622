import { atom } from "jotai";
import { nodesAtom } from "./nodesAtom";

const isTablesNode = (appId: string = "") => appId.includes("TableCLIAPI@");

export const numberOfTableNodesAtom = atom((get) => {
  const nodes = get(nodesAtom);

  const tableNodes = nodes.filter(
    (node) =>
      isTablesNode(node?.data?.services?.[0]?.currentImplementation.id) &&
      node.data.tableId,
  );

  return tableNodes.length;
});

numberOfTableNodesAtom.debugLabel = "numberOfTableNodesAtom";
