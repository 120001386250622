import { Node } from "reactflow";
import { TEMP } from "@zapier/canvas-constants";

/* convertCanvasSchemaForReactFlow function will hydrate props that we need on page load */
export function processNodesBeforeSave(nodes: Node[]): Node[] {
  return nodes.reduce((acc: Node[], node: Node) => {
    /** recommendationGroup nodes, zap steps, and temp nodes are ephemeral and should not be stored */
    if (
      node.type === "recommendationGroup" ||
      node.type === TEMP ||
      node.type === "zapStep"
    )
      return acc;

    /** Remove the 'selected' property from each node so when you open a Canvas, nothing is selected.
     * And we also don't need to persist the 'hidden' or 'className' props since UI presentation should not be stored as state.
     * */

    const {
      className,
      selected,
      hidden,
      dragging,
      selectable,
      deletable,
      connectable,
      extent, // For group nodes
      expandParent, // For group nodes
      style, // For group and text nodes
      ...rest
    } = node;

    acc.push(rest);
    return acc;
  }, []);
}
