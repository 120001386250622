import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { Page } from "../types";
import {
  extractAssetId,
  extractInterfacePageId,
  isTemplateInterfacePageId,
} from "../utils";
import { templateAtom } from "../templates";

type Params = {
  interfaceProjectId: string | undefined;
  interfacePageId: string | undefined;
};

export const interfacePageAsyncAtomFamily = atomFamily(
  ({ interfaceProjectId, interfacePageId }: Params) => {
    const interfacePageAtom = atom(async (get) => {
      if (!interfacePageId) {
        return null;
      }

      const zslTemplate = get(templateAtom);
      const isTemplateInterfacePage =
        isTemplateInterfacePageId(interfacePageId);

      if (zslTemplate && zslTemplate.interfaces && isTemplateInterfacePage) {
        const coreInterfaceProjectId = extractAssetId(interfaceProjectId ?? "");

        const interfaceTemplate = zslTemplate.interfaces.find(
          (project) => coreInterfaceProjectId === project.id,
        );

        if (interfaceTemplate && interfaceTemplate.pages) {
          const corePageId = extractInterfacePageId(interfacePageId);

          const pageTemplate = interfaceTemplate.pages.find(
            (page) => corePageId === page.id,
          );

          if (pageTemplate) {
            return pageTemplate;
          }
        }
      }

      // Because we don't want to fetch with template asset id and get a 404 Not Found error
      if (isTemplateInterfacePage) {
        return null;
      }

      try {
        const res = await fetch(
          `/api/canvasui/interfaces/pages/${interfacePageId}`,
        );

        if (res.ok) {
          const {
            result: { data },
          } = await res.json();

          return {
            blocks: data.content.blocks,
            name: data.name,
            id: data.id,
            slug: data.slug,
          } as Page;
        } else {
          console.error("Failed to fetch interface page:", res.statusText);
          return null;
        }
      } catch (error) {
        console.error("Error fetching interface page:", error);
        return null;
      }
    });

    interfacePageAtom.debugLabel = `interfacePageAsyncAtomFamily(${interfaceProjectId} - ${interfacePageId})`;
    return interfacePageAtom;
  },
  areParamsEqual,
);

function areParamsEqual(a: Params, b: Params) {
  return (
    a.interfacePageId === b.interfacePageId &&
    a.interfaceProjectId === b.interfaceProjectId
  );
}
